import React, { useState, useEffect, useContext } from "react";
import { CURRENT_USER, USER_DATA } from "../querys";
import { useQuery } from "@apollo/client";
import Loader from "../components/Loader";
import Layout from "../components/layout/Layout";

import client from "../apollo/client";
import { Router } from "@reach/router";
import InfoArticle from "../components/templates/info-article";

import RegisterNewMember from "../pages/register-new-member";
import Login from "../pages/login";
import TermsConditions from "../pages/terms-conditions";

import ForgotInformation from "../pages/forgot-information";
import RecoverPassword from "../pages/recover-password";

import NewHome from "../pages/new-home";
import RegisterNew from "../pages/register-new";
import ResaleCertificate from "../pages/resale-certificate";

import InfoBlog from "../pages/info-blog";
// Private
import PrivateRoute from "./PrivateRoute";

import Cart from "../pages/cart";
import Shopping from "../pages/shopping";
import MakePaymentPage from "../pages/make-payment";
import DetailProduct from "../components/templates/DetailProduct";
import ContactUs from "../pages/contact-us";

import EditAddresesses from "../pages/edit-addresesses";
import EditMemberInfo from "../pages/edit-member-info";
import OrderHistoryInfo from "../pages/order-history-info";
import SearchRoute from "../pages/SearchRouter";
import OrderPage from "../pages/admin/orders";
import UserOrderPage from "../pages/admin/user-order";
import OrderForm from "../pages/admin/order-form";
import ThanksForPurchase from "../pages/thanks-for-purchase";
// page 404
import NotFoundPage from "../pages/404";
import FavoritesInfo from "../pages/favorites-info";
import AfterPayClearPay from "../pages/afterpay";
import ConfirmPayment from "../pages/confirm-payment";
import AfterRegister from "../pages/after-register";
import PrivacyPolicy from "../pages/privacy-policy";
import HomePage from "../pages/home-page";
import { AuthUserContext } from "../context/AuthUser";
import ShareLink from "../pages/share-link";
import RegisterShowRoom from "../pages/register-la-showroom";
import SuccessRegister from "../pages/success-register";
import TokyoMaps from "../pages/tokyo-maps";
import RegisterTokyoShowroom from "../pages/register-tokyo-showroom";
import { getCurrentUser } from "../services/api";

const MainRouter = () => {
  const { setAuthUser } = useContext(AuthUserContext);

  const { data, loading } = useQuery(CURRENT_USER, {
    onCompleted: async () => {
      // setAuthUser({ logged: true, user: data.me });

      await getCurrentUser(localStorage.getItem("token")).then((response) => {
        if (response?.role) {
          setAuthUser({
            logged: true,
            typeCustomer: response.typecustomer,
            role: response.role.id,
            user: data.me,
          });
        } else {
          // toast.error("The user does not have access");
        }
      });
    },
    onError: () => {
      client.clearStore();
      localStorage.removeItem("token");
      setAuthUser({ logged: false, user: {} });
    },
  });  

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <Layout>
            <Router>
              <NewHome path="/" />
              <InfoBlog path="/info" />
              <InfoArticle path="/info/:id" />
              <RegisterNew path="/register-new" />
              <RegisterShowRoom path="/register-la-showroom" />
              <RegisterTokyoShowroom path="/tokyo-showroom" />
              <AfterRegister path="/after-register" />
              <SuccessRegister path="/successful-registration" />
              <TermsConditions path="/terms-and-conditions" />
              <PrivacyPolicy path="/privacy-policy" />
              <RegisterNewMember path="/register-new-member" />
              <ForgotInformation path="/forgot-information" />
              <RecoverPassword path="/recover-password" />
              <HomePage path="/home" />
              <Login path="/login" />
              <NotFoundPage default />
              {/* Private */}
              <PrivateRoute component={TokyoMaps} path="/tokyo-maps" />
              <PrivateRoute component={SearchRoute} path="/products" />
              {/* <PrivateRoute component={HomePage} path="/home" /> */}
              <PrivateRoute component={Cart} path="/cart" />
              {/* <PrivateRoute component={ScanProduct} path="/scan-product" /> */}
              <PrivateRoute component={Shopping} path="/shopping" />
              <PrivateRoute component={ShareLink} path="/share" />
              <PrivateRoute component={MakePaymentPage} path="/make-payment" />
              <PrivateRoute component={DetailProduct} path="/products/:id" />
              <PrivateRoute component={ContactUs} path="/contact-us" />
              <PrivateRoute component={FavoritesInfo} path="/favorites" />
              <PrivateRoute component={EditAddresesses} path="/edit-address" />
              <PrivateRoute component={EditMemberInfo} path="/edit-info" />
              <PrivateRoute
                component={OrderHistoryInfo}
                path="/order-history"
              />
              <PrivateRoute
                component={ResaleCertificate}
                path="/resale-certificate"
              />
              <PrivateRoute component={AfterPayClearPay} path="/afterpay" />
              <PrivateRoute
                component={ConfirmPayment}
                path="/confirm-payment"
              />
              <PrivateRoute component={OrderPage} path="/orders" />
              <PrivateRoute component={OrderForm} path="/orders/:id" />
              <PrivateRoute component={UserOrderPage} path="/orders-page/:id" />
              <PrivateRoute
                component={ThanksForPurchase}
                path="/thanks-for-purchase"
              />
            </Router>
          </Layout>
        </div>
      )}
    </>
  );
};

export default MainRouter;
