export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "60%",
    right: "auto",
    border: "0px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 5px 15px -5px rgba(79, 79, 79, 0.6)",
  },
};

export const modalStyles = {
  content: {
    top: "50% !important",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgrund: "black!important",
  },
};

export const customStylesMagnifier = {
  content: {
    top: "50%",
    left: "50%",
    width: "60%",
    right: "auto",
    border: "0px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 5px 15px -5px rgba(79, 79, 79, 0.6)",
    overflow: "hidden",
  },
};

export const customStylesTable = {
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "700",
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "center",
    },
  },
};
