import React, { useState, useEffect } from "react"
import { IoIosArrowDown } from "react-icons/io"

import { FilterStyle, TitleStyle } from "./styled-widget"
import Collapsible from 'react-collapsible';

const FinishWidget = ({ finish, handleFilter, selected }) => {
  const [collapse, setCollapse] = useState<boolean>(false)
  useEffect(() => {

  }, [selected])
  return (
    <FilterStyle>
      <Collapsible trigger={<TitleStyle onClick={() => setCollapse(!collapse)}>
        Finish
        <IoIosArrowDown
          style={{
            transform: `${collapse ? "rotate(180deg)" : "rotate(0deg)"} `,
          }}
        />
      </TitleStyle>} transitionTime={200}>

        <ul >
          {finish.slice(0, collapse ? finish.length : 0).map(item => {
            const isSelected = selected.includes(item)            
            return (
              <li onClick={() => handleFilter(item)} style={{
                fontWeight: `${isSelected ? "bold" : ""}`,
              }}>
                {item}
              </li>
            )
          }

          )}
        </ul>
      </Collapsible>
    </FilterStyle>
  )
}

export default FinishWidget
