import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, navigate } from "gatsby";

import CartList from "../cards/CardList";

import { CartContentContext } from "../../context/CartContentContext";
import { toast } from "react-toastify";
import Modal from "react-modal";

import { TfiMenu } from "react-icons/tfi";
import { VscSearch } from "react-icons/vsc";
import { AiOutlineHeart } from "react-icons/ai";
import { BsCart3, BsLock } from "react-icons/bs";
import { BiQrScan } from "react-icons/bi";
import Collapsible from "react-collapsible";
import {
  HeaderComponent,
  Container,
  Menu,
  MenuByFabric,
  Cart,
  LoginUser,
} from "./Style/HeaderStyle";

import client from "../../apollo/client";

import { AuthUserContext } from "../../context/AuthUser";

import closeIcon from "../../images/icons/close.icon2.svg";

import textileLibraryLogo from "../../images/textile_library_logo.png";

import MenuFilter from "../menu/Menu";

import MenuSearch from "../MenuSearch";
import useComponentVisible from "../../hooks/useComponentVisible";
import { CURRENT_USER, USER_DATA } from "../../querys/index";
import { useQuery } from "@apollo/client";
import { GET_SHIPPING_CART } from "../../mutations";
import ScanProduct from "../scan-product";
import { customStyles, modalStyles } from "../../services/modal";

const Header = () => {
  const { data } = useQuery(CURRENT_USER);

  const { authUser, setAuthUser } = useContext(AuthUserContext);

  const { data: userData } = useQuery(USER_DATA, {
    variables: { id: authUser ? authUser?.user?.id || data?.me?.id : "" },
  });

  const { data: cartList } = useQuery(GET_SHIPPING_CART, {
    variables: {
      id: authUser ? authUser?.user?.id || data?.me?.id : "",
    },
  });

  const [udata, setUData] = useState();

  useEffect(() => {
    setUData(localStorage.getItem("user_data"));
  }, [data, authUser]);
  const {
    cartContent,
    setCartContent,
    setSwatchContent,
    setTotalPrice,
    setShippingAddress,
    setShippingTotalCost,
    setTotalAmout,
    setDataCetcurrentUser,
  } = useContext(CartContentContext);
  useEffect(() => {}, [cartContent]);

  const handleShowMenu = () => {
    const ELEMENT = document.querySelector(".item-menu");
    if (ELEMENT.classList.contains("hiddeMenu")) {
      ELEMENT.classList.remove("hiddeMenu");
      ELEMENT.classList.add("showMenu");
    } else if (ELEMENT.classList.contains("showMenu")) {
      ELEMENT.classList.add("hiddeMenu");
      ELEMENT.classList.remove("showMenu");
    } else {
      ELEMENT.classList.add("showMenu");
    }
  };

  const handleLogout = () => {
    client.clearStore();
    localStorage.removeItem("token");
    localStorage.removeItem("user_data");
    setAuthUser({ logged: false, user: {} });
    handleShowMenu();
    // client.resetStore()
    window.location.href = "/login";
    // window.location.href = "/login";
    // navigate("/login");

    setCartContent([]);
    setSwatchContent([]);
    setTotalPrice(0);
    setShippingAddress({});
    setShippingTotalCost(0);
    setTotalAmout(0);
    setDataCetcurrentUser([]);

    toast.warning("Log out successfully!");
  };

  const handleAccessLogin = () => {
    toast.error("Login to access the cart");
  };

  const [searchVal, setSearchVal] = useState(false);
  const handleOpenSearch = () => {
    const Element = document.getElementById("search-container");
    if (searchVal) {
      setSearchVal(false);
      Element.classList.remove("showSearch");
    } else {
      setSearchVal(true);
      Element.classList.add("showSearch");
    }
  };

  useEffect(() => {
    setCartContent({
      id: cartList?.cartItems.data[0]?.id,
    });
  }, [cartList, authUser]);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const [showCamera, setShowCamera] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* <MenuFilter /> */}
      <HeaderComponent className="container-fluid">
        <Container className="container-fluid">
          <div className="u-display-flex u-align-center u-height-100">
            <Menu onClick={handleShowMenu}>
              <TfiMenu />
            </Menu>
            <Link to={"/"} className="u-text-center title-header">
              <img src={textileLibraryLogo} alt="" />
            </Link>
            <Link to={"/"} className="u-text-center title-header-only-text">
              Textile Library
            </Link>
            <div className="top-menu-desktop">
              <MenuFilter
                handleShowMenu={() => {}}
              />
            </div>
          </div>
          {!authUser.logged && !localStorage.getItem("token") ? (
            <LoginUser className="container-login">
              <Link to="/login" className="u-icon-size-23">
                {/* <img src={lockIcon} alt="Login" /> */}
                <BsLock />
              </Link>

              <Cart>
                <div
                  className="btn-cart u-icon-size-23"
                  onClick={handleAccessLogin}
                >
                  <BsCart3 />
                </div>
              </Cart>
            </LoginUser>
          ) : (
            <>
              <LoginUser>
                <div
                  onClick={() => setShowCamera((showCamera) => !showCamera)}
                  className="u-icon-size-23 hidde-qrscan-button"
                >
                  <BiQrScan />
                </div>
                <div
                  onClick={() => handleOpenSearch()}
                  className="u-icon-size-23"
                >
                  <VscSearch />
                </div>
                <Link to="/favorites" className="u-icon-size-23">
                  <AiOutlineHeart />
                </Link>

                <Cart ref={ref}>
                  <div
                    className="btn-cart u-icon-size-23"
                    onClick={() => setIsComponentVisible(true)}
                  >
                    {cartList?.cartItems?.data[0] && (
                      <>
                        {cartList?.cartItems?.data[0]?.attributes?.cartitem
                          .length > 0 && (
                          <div className="quantity-items">
                            {
                              cartList?.cartItems?.data[0]?.attributes?.cartitem
                                ?.length
                            }
                          </div>
                        )}
                      </>
                    )}

                    <BsCart3 />
                  </div>

                  {isComponentVisible && (
                    <CartList
                      data={cartList?.cartItems?.data[0]}
                      setIsComponentVisible={setIsComponentVisible}
                    />
                  )}
                </Cart>
              </LoginUser>
            </>
          )}
        </Container>
        <MenuSearch user={authUser?.user?.username} />
      </HeaderComponent>
      <div className="container-menu item-menu">
        <div className="backdrop" onClick={handleShowMenu}></div>
        <div className="container-menu__box newMenu">
          <button
            className="container-menu__btn-close"
            onClick={handleShowMenu}
          >
            <img src={closeIcon} alt="Close" />
          </button>

          {!authUser.logged && !localStorage.getItem("token") ? (
            ""
          ) : (
            <>
              <div className="mobileMainMenu">
                <div className="top-menu-responsive">
                  <MenuFilter handleShowMenu={handleShowMenu} />
                </div>
              </div>
            </>
          )}

          <div className="newMenu1">
            {!authUser.logged && !localStorage.getItem("token") ? (
              ""
            ) : (
              <div>
                <p className="menuSubtitle">My Account</p>
                <ul className="margin-bottom30">
                  <li>
                    <Link onClick={handleShowMenu} to="/favorites">
                      Favorite
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleShowMenu} to="/order-history">
                      My Orders
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleShowMenu} to="/edit-info">
                      User Information
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleShowMenu} to="/edit-address">
                      My Address
                    </Link>
                  </li>

                  {authUser.logged ? (
                    <li>
                      <a
                        className="container-menu__btn-logout"
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </a>
                    </li>
                  ) : (
                    ""
                  )}

                  {udata && (
                    <li>
                      <Link onClick={handleShowMenu} to="/orders">
                        Orders
                      </Link>
                    </li>
                  )}
                </ul>
                <hr></hr>
              </div>
            )}

            <p className="menuSubtitle">Help</p>
            <ul className="margin-bottom30">
              <li>
                <Link onClick={handleShowMenu} to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
            <hr />
            <ul className="margin-bottom30">
              {authUser.logged ? (
                <li>
                  <Link to="/tokyo-maps" onClick={() => handleShowMenu()}>
                    Tokyo Map
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>

            <ul className="margin-bottom30 u-display-flex u-margin-top-2">
              <li>
                <Link
                  style={{ fontSize: "12px" }}
                  to="/terms-and-conditions"
                  onClick={() => handleShowMenu()}
                >
                  Terms and Conditions
                </Link>
              </li>
              <li style={{ marginLeft: "20px" }}>
                <Link
                  style={{ fontSize: "12px" }}
                  to="/privacy-policy"
                  onClick={() => handleShowMenu()}
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Modal isOpen={showCamera} className="modal-styles-qr">
        <ScanProduct setShowCamera={setShowCamera} />
      </Modal>
    </>
  );
};

export default Header;
