import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import {
  Container,
  Row,
  InputField,
  Label,
  Buttons,
  Terms,
} from "../components/styled/RegisterMemberStyled"
import { useMutation } from "@apollo/client"
import { ContactUsRegister } from "../mutations"
import { toast } from "react-toastify"
import { sendContactUsMail } from "../services/api"

const ContactForm = () => {
  const [contact, setContact] = useState({
    FullName: "",
    Email: "",
    ConfirmEmail: "",
    Description: "",
    messageError: {}, 
  })
  const { messageError } = contact
  const emailRegex = RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  )

  const handleChange = e => {
    const { name, value } = e.target
    setContact(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = contact.messageError
    switch (name) {
      case "FullName":
        errors.FullName =
          value === "" ? "Field is required" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "Field is required"
            : "" || emailRegex.test(value)
            ? ""
            : "Invalid email"
        break

      case "ConfirmEmail":
        errors.ConfirmEmail =
          contact.Email !== contact.ConfirmEmail
          ? "Emails do not match"
          : "" ||
          value !== contact.Email
            ? "Field is required"
            : ""
        break
      case "Description":
        errors.Description =
          value === "" ? "Field is required" : ""
        break
    }
  }
  const [addContact,{ loading: mutationLoading, error: mutationError }] = useMutation(ContactUsRegister, {
    onCompleted: () => {
      let dataSend = {
        FullName: contact.FullName,
        Email: contact.Email,
        Description: contact.Description
      }
      const token = localStorage.getItem("token")
      sendContactUsMail(token, dataSend)
      toast.success("Successfully sent")
      navigate("/")
    },
    onError: () => {
      toast.error(mutationError, "Server Error")
    },
  })

  const handleRegisterContact = () => {
    addContact({
      variables: {
        input: {
          data: {
            FullName: contact.FullName,
            Email: contact.Email,
            Description: contact.Description,
          },
        },
      },
    })
  }
  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  const handleSubmit = event => {
    event.preventDefault()
      if (
        contact.FullName !== "" &&
        contact.Email !== "" &&
        contact.ConfirmEmail !== "" &&
        contact.Description !== ""
      ) {
        if (formValidate(contact.messageError, contact)) {
          handleRegisterContact()
          toast.success("Thanks for contacting us!")
        } else {
          toast.error("Form is not corrected filled!")
        }
      } else {
        toast.error("Please check and send again.")
      }
  }
  return(
      <Container className="container" onSubmit={handleSubmit} noValidate>
        <h2>Contact Us</h2>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="FullName">Full Name</Label>
          </div>
          <div className="col-lg-8 u-margin-bottom-2">
            <InputField>
              <input
              type="text"
              placeholder="Full Name (required)"
              id="FullName"
              name="FullName"
              onChange={handleChange}
              onBlur={handleChange}
            />
            {messageError.FullName && (
              <small className="field-error">{messageError.FullName}</small>
            )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="Email">Email</Label>
          </div>
          <div className="col-lg-4">
            <InputField className="u-margin-rp-2">
              <input
                type="email"
                id="Email"
                name="Email"
                placeholder="E-Mail Address (required)"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.Email && (
                <small className="field-error">{messageError.Email}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="email"
                name="ConfirmEmail"
                placeholder="Confirm E-mail Address (required)"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.ConfirmEmail && (
                <small className="field-error">
                  {messageError.ConfirmEmail}
                </small>
              )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="Description">Message</Label>
          </div>
          <div className="col-lg-8">
            <InputField>
              <textarea
                type="textarea"
                placeholder="Your comments... (required)"
                id="Description"
                name="Description"
                onChange={handleChange}
                onBlur={handleChange}
              />
            {messageError.Description && (
              <small className="field-error">{messageError.Description}</small>
            )}
            </InputField>
          </div>
        </Row>
        <Buttons className="row u-text-center">
          <Terms>
              <label htmlFor="terms" className="u-margin-tb-2">
                *Please read to the{" "}
                <Link to="/terms-and-conditions">terms</Link> of service and
                proceed.
              </label>
          </Terms>
          <button
            type="submit"
            className="btn btn-primary btn-width-200 u-margin-bottom-1"
          >
            {mutationLoading ? (
              <div className="loading"></div>
            ) : (
              "Submit"
            )}
          </button>
        </Buttons>
      </Container>
  )
}

export default ContactForm