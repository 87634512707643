import React, { useState, useEffect } from "react"
import { IoIosArrowDown } from "react-icons/io"
import { FilterStyle, TitleStyle } from "./styled-widget"
import _ from "underscore"
import Collapsible from "react-collapsible"
const PatternDesignWidget = ({ patternDesign, handleFilter, selected }) => {
  const [collapse, setCollapse] = useState<boolean>(false)

  const [patternDesigns, seetPatternDesigns] = useState([])

  useEffect(() => {
    _.each(
      patternDesign,
      _.iteratee(item =>
        seetPatternDesigns(prevState => [
          ...prevState,
          {
            subCategory: item.split("-")[0],
            values: item.split("-")[1].split(","),
          },
        ])
      )
    )
  }, [patternDesign])

  return (
    <FilterStyle>
      <Collapsible
        trigger={
          <TitleStyle onClick={() => setCollapse(!collapse)}>
            Pattern & Design
            <IoIosArrowDown
              style={{
                transform: `${collapse ? "rotate(180deg)" : "rotate(0deg)"} `,
              }}
            />
          </TitleStyle>
        }
        transitionTime={200}
      >
        <ul>
          {patternDesigns
            .slice(0, collapse ? patternDesigns.length : 0)
            .map((sub: any) => (
              <li>
                <Collapsible
                  trigger={
                    <p>
                      {sub.subCategory} <IoIosArrowDown />
                    </p>
                  }
                  transitionTime={200}
                >
                  <ul style={{ marginLeft: "20px" }}>
                    {sub.values.map(item => {
                      const isSelected = selected.includes(item)
                      return (
                        <li
                          onClick={() => handleFilter(sub.subCategory, item)}
                          style={{
                            fontWeight: `${isSelected ? "bold" : ""}`,
                          }}
                        >
                          {item}
                        </li>
                      )
                    })}
                  </ul>
                </Collapsible>
              </li>
            ))}
        </ul>
      </Collapsible>
    </FilterStyle>
  )
}

export default PatternDesignWidget
