import React, { useEffect, useContext, useState } from "react";
import { Link } from "gatsby";
import { Buttons } from "./Style/CardStyle";
import { DropDown } from "./Style/CartStyle";

import _ from "underscore";
import { CartContentContext } from "../../context/CartContentContext";
import noImage from "../../images/icons/no-image.svg";
import { getImageFromDrive } from "../../services/api";
import closeImg from "../../images/icons/close.icon.svg";

const CardList = ({ data, setIsComponentVisible }) => {
  const [cartValues, setCartValues] = useState();
  useEffect(() => {
    setCartValues(_.groupBy(data?.attributes?.cartitem, "typeofpurchase"));
  }, []);  
  return (
    <DropDown className="element-dropdown">
      <div className="content-cart__all-items">
        {cartValues?.length_order?.length !== 0 ||
          (cartValues?.swatch?.length !== 0 && (
            <button
              className="btn-close u-text-center"
              onClick={() => setIsComponentVisible(false)}
            >
              <img src={closeImg} alt="closeImg" />
            </button>
          ))}
        {cartValues?.length_order && (
          <>
            <span className="text-length-order">Length Order</span>

            {cartValues?.length_order?.map((item, i) => (
              <div className="content-cart__item" key={i}>
                {item.image === "#N/A" || !item.image ? (
                  // <img className="image-default-small" src={"noImage"} alt="" />
                  ""
                ) : (
                  <img src={getImageFromDrive(item.image)} alt="" />
                )}
                <div className="content-cart__item__text">
                  <p>{item.productId}</p>
                  <span>Color type： {item.colortype}</span>
                  <b>$ {parseFloat(item.price).toFixed(2)}</b>
                  <span>Quantity: {item.quantity}</span>
                </div>
              </div>
            ))}
          </>
        )}

        {cartValues?.swatch?.length !== 0 && (
          <>
            {cartValues?.swatch?.length === 0 && (
              <span className="text-swatch-order">Swatch Order</span>
            )}

            {cartValues?.swatch?.map((item, i) => (
              <div className="content-cart__item" key={i}>
                {item.image === "#N/A" || !item.image ? (
                  // <img className="image-default-small" src={noImage} alt="" />
                  ""
                ) : (
                  <img src={getImageFromDrive(item.image)} alt="" />
                )}

                <div className="content-cart__item__text">
                  <p>{item.productId}</p>
                  <span>Color type： {item.colortype}</span>
                  <b>$ {item.quantity}</b>
                  <span>Quantity: {item.quantity}</span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {!cartValues?.length_order && !cartValues?.swatch ? (
        "Oh! You have not added any items to the cart yet."
      ) : (
        <Buttons className="content-buttons-cart">
          <Link
            to="/shopping"
            onClick={() => setIsComponentVisible(false)}
            className="btn btn-primary u-text-center btn-link"
          >
            Check Out
          </Link>
          <Link
            to="/cart"
            onClick={() => setIsComponentVisible(false)}
            className="btn u-text-center btn-link-cart"
          >
            View Cart
          </Link>
        </Buttons>
      )}
    </DropDown>
  );
};

export default CardList;
