import styled from "@emotion/styled";

export const MenuStyled = styled.div({
  ".button-close-menu": {
    zIndex: 3,
    top: "30px",
    right: "50px",
    border: "none",
    cursor: "pointer",
    background: "none",
    position: "absolute",
  },
  height: "100%",
  ul: {
    height: "100%",
    display: "inline-flex",
    position: "relative",
    li: {
      margin: "0",
      height: "100%",
      "&:hover > .menu-content": {
        display: "block",
      },
      "&:hover > .blur-content": {
        display: "block",
      },
      a: {
        height: "100%",
        display: "flex",
        fontSize: "15px",
        padding: "0px 20px",
        alignItems: "center",
        color: "var(--color-primary)",
        "&:hover": {
          textDecoration: "underline",
        },
        svg: {
          display: "none",
        },
      },
    },
    ".menu-content": {
      left: 0,
      zIndex: 2,
      top: "59px",
      width: "100%",
      display: "none",
      position: "fixed",
      maxHeight: "60%",
      overflowY: "auto",
      background: "var(--color-contrast)",
      ".menu-items": {
        display: "flex",
        marginTop: "40px",
        marginBottom: "50px",
        flexWrap: "wrap",
        // justifyContent: "center",
        // height: "200px",
        // overflowY: "auto",
        p: {
          margin: "8px 0",
          fontSize: "14px",
          fontWeight: "600",
          marginBottom: "3px",
        },
        ul: {
          flexDirection: "column",
          li: {
            padding: "0",
            height: "auto",
            // margin: "4px 0",
            fontSize: "14px",
            cursor: "pointer",
            fontWeight: "lighter",
          },
          ".byfabric-list": {
            width: "fit-content",
            position: "relative",
            span: {
              fontSize: "14px",
              fontWeight: "100",
              marginBottom: "10px",
            },
            small: {
              fontSize: "14px",
              fontWeight: "lighter",
              cursor: "pointer",
              display: "block",
            },
            ".item-sub-category": {
              margin: "3px 0",
            },
          },
        },
        ".menu-items-group": {
          margin: "0 4.5em",
          marginBottom: "25px!important",
        },
        "@media screen and (max-width: 992px)": {
          ".menu-items-group": {
            marginBottom: "0!important",
          },
        },
      },
    },
  },
  ".blur-content": {
    left: 0,
    top: "60px",
    width: "100%",
    height: "100vh",
    display: "none",
    position: "fixed",
    backdropFilter: "blur(4px)",
    background: "rgb(0 0 0 / 45%)",
    "&:hover": {
      display: "none!important",
    },
    "&:hover + .menu-content": {
      display: "none",
    },
  },
});

export const ListOfMenu = styled.div({
  display: "inline-flex",
  flexDirection: "column",
  ".sub-category-content": {
    opacity: 0,
    maxHeight: "0px",
    transform: "scale(1,0)",
    overflowY: "hidden",
    transition: ".3s ease all",
    transformOrigin: "top center",
    position: "absolute",
    left: "100%",
    zIndex: "3",
    width: "max-content",
    // background: "var(--color-contrast-light)",
    background: "none",
    padding: "0px 30px",
    "@media screen and (max-width: 992px)": {
      padding: "0px",
      right: "-10px",
      left: "initial",
    },
    small: {
      background: "white",
      padding: "5px 20px",
      margin: "0!important",
    },
  },
  ".sub-category-text": {
    span: {
      fontWeight: 500,
    },
    margin: "3px 0",
  },
  ".items-space-top-bottom": {
    margin: "3.5px 0",
  },
  ".sub-category-text:hover > .sub-category-content": {
    opacity: 1,
    maxHeight: "500px",
    transformOrigin: "top center",
    transform: "scale(1,1)",
    transition: ".2s ease all",
  },
});
