import React from "react";

const AfterRegister = () => {
  return (
    <>
      <div class="main-container u-margin-top-3">
        <div class="check-container">
          <div class="check-background">
            <svg
              viewBox="0 0 65 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 25L27.3077 44L58.5 7"
                stroke="white"
                stroke-width="13"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="check-shadow"></div>
        </div>
      </div>

      <div className="after-regiter-ms">
        <p>Thank you so much for pre-registration of Textile Library.</p>
        <p>We will email you when we launch the service.</p>
        <p>If you have any question, please email us.</p>
      </div>
      <div className="after-regiter-mail">
        <p>
          Email:{" "}
          <a href="mailto:info@textilelibrary-takihyo.com">
            info@textilelibrary-takihyo.com
          </a>
        </p>
      </div>
    </>
  );
};

export default AfterRegister;
