import styled from "@emotion/styled"

export const CollapseFilter = styled.div({
  padding: "0",
  ul: {
    li: {
      margin: "5px 0",
      fontSize: "15px",
      cursor: "pointer",
    },
  },
})

export const FilterStyle = styled.div({
  padding: "0",
  ul: {
    li: {
      margin: "5px 0",
      fontSize: "15px",
      cursor: "pointer",
      p: {
        display: "flex",
        alignItems: "center",
        svg: {
          marginLeft: "15px"
        }
      }
    },
  },
})

export const TitleStyle = styled.p({
  display: "flex",
  fontSize: "15px",
  padding: "10px 0",
  cursor: "pointer",
  fontWeight: "600",
  alignItems: "center",
  color: "var(--color-primary)",
  justifyContent: "space-between",
})

export const ColorStyle = styled.div({
  padding: "0",
  ul: {
    flexWrap: "wrap",
    display: "flex",
    li: {
      width: "15%",
      fontSize: "8px",
      display: "flex",
      cursor: "pointer",
      margin: "10px 5px",
      textAlign: "center",
      alignItems: "center",
      flexDirection: "column",
      span: {
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        display: "inline-block",
        border: "1px solid rgb(149 149 149 / 29%)"
      },
    },
  },
})

export const WeightStyle = styled.div({
  padding: "0",
  ul: {
    li: {
      margin: "5px 0",
      cursor: "pointer",
      p: {
        fontSize: "15px",
      },
    },
  },
})

export const MaterialStyle = styled.div({
  padding: "0",
  ul: {
    li: {
      margin: "5px 0",
      cursor: "pointer",
      p: {
        fontSize: "15px",
      },
    },
  },
})

export const FunctionStyle = styled.div({
  padding: "0",
  ul: {
    li: {
      margin: "5px 0",
      cursor: "pointer",
      p: {
        fontSize: "15px",
      },
    },
  },

})
