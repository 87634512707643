import styled from "@emotion/styled";

export const Container = styled.form`
  color: var(--color-dark);
  h2 {
    font-size: 3em;
    margin: 1em 0;
    color: var(--color-dark);
  }
`;
export const ContainerPage = styled.div`
  color: var(--color-dark);
  h2 {
    font-size: 2.5em;
    margin: 1em 0;
    color: var(--color-dark);
    @media screen and (max-width: 768px){
      margin-bottom: 0;
      font-size: 1.5em;
    }
  }
`;

export const Row = styled.div`
  padding: 1.7em 0;
  align-items: center;
  border-bottom: 1px solid var(--color-dark-shade);
`;
export const Label = styled.label`
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--color-dark);
`;
export const InputField = styled.div`
  position: relative;
  display: flex;
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="phone"] {
    height: 40px;
    width: 100%;
    padding: 0 8px;
    border-radius: 3px;
    border: 1px solid;
    border-color: var(--color-border-input);
  }
  input.border-error {
    border-color: var(--color-danger);
  }
  textarea[type="textarea"] {
    height: 80px;
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    border: 1px solid;
    border-color: var(--color-border-input);
    resize: none;
    font-family: Arial;
  }
`;
export const Select = styled.select`
  height: 40px;
  width: 100%;
  padding: 0 1em;
  border-radius: 3px;
  font-weight: 300;
  border: 1px solid var(--color-dark-shade);
`;
export const Buttons = styled.div`
  align-items: center;
  flex-direction: column;
  a {
    width: 230px;
    margin: 0.8em 0;
  }
  p {
    font-size: 1rem;
    color: var(--color-dark);
  }
`;

export const Terms = styled.div`
  margin: 1em;
  display: flex;
  .input-checkbox-filter {
    margin-left: 20px;
  }
`;
