import React, { useEffect } from "react"
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="container container-terms-conditions">
        <h1>Privacy Policy</h1>
        <div className="row">
          <div className="col-12">
            <h2>Effective as of 10.07.2019</h2>
            <p>
              We have created this Privacy Policy to explain why we collect
              particular information, how we use it, and to whom and under what
              circumstances we may share or disclose it.
            </p>
            <p>
              In order to fully understand your rights, we encourage you to read
              this Privacy Policy, which is effective as of the date set forth
              above. This Privacy Policy applies to all personal information we
              collect from and about you, including on our Site; by email; in
              messages sent to us via social media; or otherwise when you visit
              the Site, purchase product from us, engage our customer service
              representatives, or buy or inquire about goods or services offered
              by us. Except as explicitly provided herein, this Privacy Policy
              does not apply to the practices of entities that we do not own or
              control or to people that we do not employ or manage.
            </p>
            <p>
              Questions regarding this Privacy Policy should be directed to
              TEXTILE LIBRARY by emailing info@textilelibrary-takihyo.com.
            </p>
            <h2>Your Consent</h2>
            <p>
              Your use of the Site indicates that you have read and accepted our
              Privacy Policy, as outlined. This Privacy Policy is part of our
              Terms of Use (“Terms”) governing your use of the Site. Any dispute
              over privacy is subject to this Privacy Policy and our Terms.
              Please read the Terms. If you do not agree with any part of this
              Privacy Policy, please do not use the Site.
            </p>
            <p>
              TEXTILE LIBRARY may change or revise this Privacy Policy at any
              time, for any or no reason, at our sole discretion, without
              notice, simply by posting such changes or revisions on this page.
              Any such change will be effective immediately upon posting.
              However, we will not use your personal information in a way that
              is adverse and materially different from the uses described in
              this Privacy Policy without giving you notice and an opportunity
              to opt out. Your continued use of the Site signifies your
              acceptance of any such change or revision. For this reason, please
              visit this page on a regular basis to ensure that you are familiar
              with the most recent version of this Privacy Policy and the Terms.
              TEXTILE LIBRARY’s use of information is subject to the Privacy
              Policy in effect at the time that it uses such information.
            </p>
            <h2>What Information Do We Collect?</h2>
            <p>Your privacy is important to us.</p>
            <p>
              TEXTILE LIBRARY collects personal information when you shop and
              register with us on our Site. Personal information is information
              that can be used to contract or identify you. When you place an
              order or create an account on our Site, we ask for personal
              information such as your full name, email address, phone number,
              billing address, shipping address, and credit card number. The
              personal information that you provide must be true, accurate and
              complete, and all registration and account details (where
              applicable) must contain your real name, address and other
              requested information. You are responsible for keeping your
              information current and accurate. We may combine information we
              collect from and about you online with information we collect from
              and about you through other means. We may also combine your
              information with information we learn about you from other
              sources, such as account update notifications.
            </p>
            <p>
              Please note that when you delete your account information on the
              Site, we may retain your information for a reasonable period of
              time afterward for the purpose of recordkeeping, account
              management and fraud prevention activities. <br /> In some
              instances, we may ask for contact information for people other
              than yourself including, for example, e-mail addresses of friends
              to whom you send gift cards, e-cards or a link to the Site, or the
              mailing address of someone you wish us to notify about the Site.
              The information we collect is used to send the notification or
              information you request and/or to inform the recipient of TEXTILE
              LIBRARY updates and opportunities.Such contact information is not
              shared with any other organizations, other than as provided in
              this Privacy Policy. Our Site may log certain non-identifying Site
              usage data, also called anonymous information, such as the number
              of visits to our Site. We may also collect anonymous information
              such as the Internet protocol (IP) address used to connect your
              computer to the Internet; connection information such as browser
              type and version, operating system and platform; other software
              and hardware information; and information about your browsing
              behavior, such as the date/time you visit the Site, the Site pages
              that you visit, the time you spend visiting the Site or viewing a
              page, the number of times you return to the Site, and other
              click-stream data.
            </p>
            <p>
              We may also use third-party website analytics tools (such as
              Google Analytics) that collect information
              about visitor traffic on our Site and any mobile applications,
              including without limitation, prior activities on the Internet.
              This information is used for internal purposes by TEXTILE LIBRARY to
              provide better technical support and may also be provided to
              others for support, marketing, research or other business purposes
              as described in this Privacy Policy. You can read about how
              Shopify collects and processes your data in Section 3 of their
              Privacy Policy found{" "}
              <a href="https://www.shopify.com/legal/privacy">
                <span>here</span>
              </a>
              . You can also read about how Google collects and uses your data
              in their privacy policy for partners{" "}
              <a href="https://protect-us.mimecast.com/s/1joJC9rgnAFxR6XnsEtkjr">
                <span>here</span>
              </a>
              . If you would like to opt out of Google Analytics, please
              download and install the browser plugin at{" "}
              <a href="http://tools.google.com/dlpage/gaoptout?hl=en">
                <span>http://tools.google.com/dlpage/gaoptout?hl=en</span>
              </a>
              . Users can also opt out of third party interest based advertising
              by visiting the Network Advertising Initiative’s deactivation
              website at{" "}
              <a href="http://www.networkadvertising.org/choices/">
                <span>www.networkadvertising.org/choices/</span>
              </a>
              . We treat all information of the type referenced in this
              paragraph as non-personal, unless required by applicable law to do
              otherwise. Additionally, any unsolicited information that you
              provide to us shall be deemed to be non-confidential, and we shall
              be free to reproduce, use, disclose and distribute such
              unsolicited information to others without limitation or
              attribution.
            </p>
            <h2>What Do We Use Your Information For?</h2>
            <p>
              TEXTILE LIBRARY uses your information for the following
              representative purposes: to process and fulfill the orders you
              placed with us, to communicate with you about your orders, to
              contact you about promotions, sales and new products, to improve
              the Site, to improve customer service, to send periodic emails, to
              protect your rights, our rights and the rights of others, and to
              understand the shopping habits of our customers to improve their
              experience in the future.
            </p>
            <p>
              In the event we must make a change to or cancel an order, we will
              attempt to notify you via email/address/phone number provided when
              the order was placed.
            </p>
            <h2>
              Do we disclose your personal identifiable information to outside
              parties?
            </h2>
            <p>
              TEXTILE LIBRARY may share personally identifiable information
              about you to trusted third party service providers who assist us
              in operating the Site, providing the products or services to you,
              conducting our business, or servicing you, or to our affiliates or
              agents, so long as those parties agree to keep this information
              confidential. Examples include providing the shipping company with
              your address for shipping an order to you, analyzing data,
              providing marketing assistance, providing search results and links
              (including paid listings and links), processing credit card
              payments, and providing customer service.
            </p>
            <p>
              We also may share your personally identifiable information with
              our subsidiaries, joint venturers, or other companies under common
              control, in which case we will require them to honor this Privacy
              Policy. Additionally, in the event we undergo a business
              transition such as a merger, acquisition by another company, or
              sale of all or a portion of our assets, your personal information
              may be among the assets transferred. You acknowledge that such
              transfers may occur and are permitted by this Privacy Policy, and
              that any entity that acquires us, is merged with us or that
              acquires our assets may continue to process your personal
              information as set forth in this Privacy Policy.
            </p>
            <p>
              Aggregated Anonymous Information is the combination of your
              anonymous information with the anonymous information of other
              users (“Aggregated Anonymous Information”). Aggregated Anonymous
              Information does not allow you to be identified or contacted.We
              may share such Aggregated Anonymous Information with third
              parties.
            </p>
            <p>
              Additionally, we will share personally identifiable information if
              we have a good faith belief that: (i) access, use, preservation or
              disclosure of such information is reasonably necessary to satisfy
              any applicable law, legal process (such as a court order or
              subpoena), request by law enforcement or governmental authorities;
              (ii) such action is appropriate to enforce our Terms, or other
              policies of our Site, including any investigation of potential
              violations thereof; (iii) such action is necessary to detect,
              prevent, or otherwise address fraud, security or technical issues
              associated with the Site; or (iv) such action is appropriate to
              protect our rights, property or safety, or the rights, property or
              safety of our employees, affiliates, agents, contractors, other
              users of the Site, or others.
            </p>
            <p>
              TEXTILE LIBRARY will not sell, trade, or rent your information to
              anyone.
            </p>
            <h2>Do We Use Cookies?</h2>
            <p>
              Yes, we use “cookies”. Cookies are small files of information that
              a website either transfers to your computer's hard drive or stores
              temporarily in your computer's memory for record-keeping purposes.
              We may use cookies for purposes including, but not limited to,
              helping us to identify account holders and to optimize their
              shopping experience by remembering preferences. Please note that
              we do not store credit card data on our Site’s server. We may also
              use cookies to monitor and maintain information about your use of
              this Site. Most browsers automatically accept cookies. Through
              your browser, you may choose to have your computer alert you each
              time a cookie is being sent, or you can choose to turn off all
              cookies. However, if you “turn off” cookies, you may not be able
              to take full advantage of certain features of this Site.
            </p>
            <h2>Security</h2>
            <p>
              Protecting your personal information is a priority to us.We would
              like you to have a secure shopping experience at TEXTILE
              LIBRARY.We use Secure Socket Layer (SSL) protocol to protect the
              security of your online order and credit card information as it is
              transmitted to our server.We reserve the right to request further
              identification for credit card processing on any order you place
              with us.
            </p>
            <p>
              Unfortunately, no transmission of data over the Internet, and no
              system for storage of physical data, is guaranteed to be
              completely secure. It may be possible for hackers or other third
              parties not under the control of TEXTILE LIBRARY to intercept or
              access private communications unlawfully. While we strive to
              protect your personal information, TEXTILE LIBRARY cannot ensure
              or warrant the security of any information you transmit or
              otherwise provide to us. Any transmission or other provision of
              information is done at your own risk.
            </p>
            <h2>Children’s Online Privacy Protection Act Compliance</h2>
            <p>
              We are in compliance with the requirements of COPPA (Children’s
              Online Privacy Protection Act). We will not knowingly solicit,
              collect or maintain any information from anyone under thirteen
              (13) years of age. Our Site, products and services are all
              directed to people who are at least thirteen (13) years old or
              older. No personally-identifiable information should be submitted
              to, or posted at, the Site by visitors under thirteen (13) years
              of age without the prior written consent of their parent or
              guardian. If we later obtain actual knowledge that a user is under
              thirteen (13) years of age we will take steps to remove that
              user’s personal information from our systems and delete any
              related account. If you are the parent or guardian of a child whom
              you believe has disclosed personal information to us, please
              contact us at{" "}
              <a href="%22mailto:">
                <span>info@textilelibrary-takihyo.com</span>
              </a>{" "}
              so that we may delete and remove such information from our system.
            </p>
            <h2>Your Choices</h2>
            <p>
              By creating an account, you consent to receive certain
              communications from us. You may modify or opt out of receiving
              messages sent for marketing purposes, or certain other
              communications, by adjusting your account settings or following
              the unsubscribe instructions in such messages. Please note that
              despite any indicated marketing preferences, we may send you
              administrative emails regarding the Site, including, for example,
              notices of updates to our Privacy Policy.
            </p>
            <p>
              You may also revise your personal information by contacting us
              directly at{" "}
              <a href="%22mailto:">
                <span>info@textilelibrary-takihyo.com</span>
              </a>
              . When we delete any information, it will be deleted from our
              active database, but may remain in our archives. We may retain
              your information for fraud detection or similar purposes. Note
              that if we have already disclosed some of your personal
              information to third parties, we cannot access that personal
              information any longer and cannot compel the deletion or
              modification of any such information by the parties to whom we
              have made those disclosures.
            </p>
            <h2>California Privacy Rights</h2>
            <p>
              Under California’s “Shine the Light” law, California residents
              have the right to request in writing from businesses with whom
              they have an established business relationship: (i) a list of the
              categories of personal information, such as name, address, e-mail
              address, and the type of services provided to that individual,
              that a business has disclosed to third parties (including
              affiliates that are separate legal entities) during the
              immediately preceding calendar year for the third parties’ direct
              marketing purposes, and (ii) the names and addresses of all such
              third parties. To request the above information, California
              residents can email us at{" "}
              <a href="%22mailto:">
                <span>info@textilelibrary-takihyo.com</span>
              </a>
              .
            </p>
            <h2>Additional Information</h2>
            <p>
              This Privacy Policy is personal between you and us, and no one
              else shall be deemed a third party beneficiary of this Privacy
              Policy.
            </p>
            <p>
              USE OF THIS SITE SIGNIFIES YOUR AGREEMENT TO THE TERMS OF USE AND
              THIS PRIVACY POLICY.
            </p>
            <p>
              If you have any further questions regarding this Privacy Policy or
              the use of your personal information under this Privacy Policy,
              you can contact us at the following postal or email address:
            </p>
            <br />
            <p>Privacy Director</p>
            <p>
              21W 38<sup>th</sup> St, FL4, New York, NY 10018
            </p>
            <p>E-mail: info@textilelibrary-takihyo.com</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
