import React, { useState } from "react"
import { FooterComponent, List } from "./Style/FooterStyle"
import { Link } from "gatsby"
const Footer = () => {
  const [items] = useState([
    {
      id: 1,
      title: "Help",
      list: [
        { id: 0, name: "Contact Us", link: "contact-us" },
        { id: 1, name: "Terms and Conditions", link: "terms-and-conditions" },
        { id: 2, name: "Privacy Policy", link: "privacy-policy" },
      ],
    },
  ])
  return (
    <FooterComponent className="container-fluid" id="footer-id">
      <div className="container">
        <div className="row">
          {items.map(item => (
            <div className="col-lg-4" key={item.id}>
              <span>{item.title}</span>
              <List>
                {item.list.map(name => (
                  <li key={name.id}>
                    <Link to={"/" + name.link}>{name.name}</Link>
                  </li>
                ))}
              </List>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 u-text-center">
            <h4>Textile Library</h4>
            <p>copyright (c) Textile Library all rights reserved.</p>
          </div>
          {/* <div className="col-lg-4">
            <img src={paypalIcon} alt="Pay Pal" />
          </div> */}
        </div>
      </div>
    </FooterComponent>
  )
}

export default Footer
