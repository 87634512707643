import React, { createContext, useState } from "react"

export const FilterContext = createContext(null)

const FilterProvider = props => {
  const [keyFilter, setKeyFilter] = useState([])
  const [keyFilterWeight, setKeyFilterWeight] = useState("")
  const [keyFilterDetail, setKeyFilterDetail] = useState("")
  const [dataProductsFilter, setDataProductsFilter] = useState([])
  const [tempDataFilter, setTempDataFilter] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [dataMaterial, setDataMaterial] = useState([])
  const [dataWeight, setDataWeight] = useState([])
  const [dataDetail, setDataDetail] = useState([])

  const [orderData, setOrderData] = useState("DESC")

  return (
    <FilterContext.Provider
      value={{
        keyFilter,
        setKeyFilter,
        dataProductsFilter,
        setDataProductsFilter,
        tempDataFilter,
        setTempDataFilter,
        pageNumber,
        setPageNumber,
        dataWeight,
        setDataWeight,
        dataMaterial,
        setDataMaterial,
        dataDetail,
        setDataDetail,
        keyFilterWeight,
        setKeyFilterWeight,
        keyFilterDetail,
        setKeyFilterDetail,
        orderData,
        setOrderData,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  )
}

export default FilterProvider
