import { Link } from "gatsby";
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { CURRENT_USER } from "../querys";
import { useQuery } from "@apollo/client";
import { navigate } from "gatsby";
import backimage from "../images/background-home.png";
import Loader from "../components/Loader";

const NewHome = (props) => {

  const BackContainer = styled.div`
    img {
      width: 100%;
      height: 160vh;
      object-fit: cover;
    }

    .preregister-container {
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto;
      padding: 6% 12%;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `;

  /**
   * Check if the token is active
   */
  const { data, loading } = useQuery(CURRENT_USER, {
    onCompleted: () => {
      navigate("/home");
    },
    onError: () => {
      // toast.error("Please login to view the products")
      navigate("/");
    },
  });  

  return (
    <>
    {/* {loading && <Loader />} */}
      {!data && (
        <BackContainer>
          <img src={backimage} alt="" />
          {/* <div className="back-container"> */}
          <div className="preregister-container">
            <div>
              <p>Pre-register is available</p>
              <div className="button-register">
                <Link to="/register-new"> REGISTER NOW</Link>
              </div>
              <div className="login-tag">
                <Link to="/login">or Login</Link>
              </div>
            </div>
          </div>
          {/* </div> */}
        </BackContainer>
      )}
    </>
  );
};

export default NewHome;
