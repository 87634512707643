import React, { useEffect, useMemo, useRef, useState } from "react";
import UrlShare from "../components/UrlShare";
import queryString from "query-string"
import { Location } from "@reach/router"
const getFilters = (params) => {
  const filter = Object.fromEntries(params.entries());
  return { ...filter };
};
const ShareLink = () => {
  return (
    <Location>
      {({ location, navigate }) => {        
        return (
          <UrlShare
            location={location}
            navigate={navigate}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        );
      }}
    </Location>
  );
};

export default ShareLink;
