import styled from "@emotion/styled"
export const TableList = styled.table`
  margin: 1em 0;
  width: 100%;
  border-radius: 3px;
  border-collapse: collapse;
  overflow: hidden;
  thead {
    background: var(--color-dark-shade);
    th {
      padding: 0.8em;
      font-size: 0.9rem;
      color: var(--color-dark);
    }
  }
  tbody {
    color: var(--color-dark);
    font-weight: 300;
    tr {
      border-bottom: 1px solid var(--color-dark-shade);
    }
    td {
      padding: 0.8em;
    }
  }
`
export const Product = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  div {
    margin-left: 3em;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      margin-left: 1em;
    }
    @media screen and (max-width: 580px) {
      margin-left: 0.5em;
    }
    @media screen and (max-width: 580px) {
      margin-left: 0.4em;
    }
  }
`
export const Quantity = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 15em;
  @media screen and (max-width: 992px) {
    margin-right: 3em;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
  span {
    font-size: 1rem;
    margin: 0 2em;
    @media screen and (max-width: 768px) {
      margin: 0 1em;
    }
    @media screen and (max-width: 420px) {
      margin: 0 0.5em;
      font-size: 0.7rem;
    }
  }
  div {
    button {
      &:disabled{
        cursor: normal;
        background: #111111;
      }
      margin: 5px;
      width: 35px;
      height: 35px;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
      border-radius: 5px;
      color: var(--color-contrast);
      background: var(--color-primary);
      @media screen and (max-width: 768px) {
        margin: 2px;
        width: 30px;
        height: 30px;
      }
      @media screen and (max-width: 420px) {
        margin: 0px;
        width: 25px;
        height: 25px;
        font-size: 1rem;
        padding: 0;
        max-width: 25px;
        max-height: 25px;
      }
    }
  }
`
export const ColTotal = styled.div`
  a,
  span {
    margin: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 1em 0;
    font-size: 1.5rem;
  }
`

export const SectionText = styled.span`
  display: block;
  margin: 1em 0;
  font-size: 1.2em;
  background: #fbfbfb;
  width: 100%;
  display: block;
  padding: 0.2em 1.5em;
`
