import React, { createContext, useState } from "react"

export const CredentialsContext = createContext(null)

const CredentialsProvider = props => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  })

  return (
    <CredentialsContext.Provider value={{ credentials, setCredentials }}>
      {props.children}
    </CredentialsContext.Provider>
  )
}

export default CredentialsProvider
