import { useStaticQuery, graphql } from "gatsby"

const useOrders = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(svg)/" }
          absolutePath: { regex: "/icon-info/" }
        }
      ) {
        nodes {
          
          name
        }
      }
    }
  `)
  return data

}

export default useOrders
