import React, { createContext, useState } from "react";

export const SearchContext = createContext(null);

const SearchProvider = (props) => {
  const [keySearch, setKeySearch, tagButton] = useState({
    nameKey: [],
    tagKey: [],
    tagButton: [],
    tempData: [],
  });
  const [dataSearch, setDataSearch] = useState([]);
  const [tempSearchData, setTempSearchData] = useState([]);

  return (
    <SearchContext.Provider
      value={{
        keySearch,
        setKeySearch,
        tagButton,
        dataSearch,
        setDataSearch,
        tempSearchData,
        setTempSearchData,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
