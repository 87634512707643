import React, { useState } from "react"
import { IoIosArrowDown } from "react-icons/io"
import { FilterStyle, TitleStyle } from "./styled-widget"
import Collapsible from 'react-collapsible';

const FinishLookFeelWidget = ({ finishLookFeel, handleFilter, selected }) => {
  const [collapse, setCollapse] = useState<boolean>(false)

  return (
    <FilterStyle>

      <Collapsible trigger={<TitleStyle onClick={() => setCollapse(!collapse)}>
        Finish Look & Feel
        <IoIosArrowDown
          style={{
            transform: `${collapse ? "rotate(180deg)" : "rotate(0deg)"} `,
          }}
        />
      </TitleStyle>} transitionTime={200}>

        <ul >
          {finishLookFeel
            .slice(0, collapse ? finishLookFeel.length : 0)
            .map((item: any) => {
              const isSelected = selected.includes(item)
              return (
                <li onClick={() => handleFilter(item)} style={{
                  fontWeight: `${isSelected ? "bold" : ""}`,
                }}>
                  {item}
                </li>
              )
            })}
        </ul>
      </Collapsible>



    </FilterStyle>
  )
}

export default FinishLookFeelWidget
