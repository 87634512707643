import React, { FC, useContext, useEffect, useState } from "react";
import { Link } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";

interface HomePageProps {}

const HomePage: FC<HomePageProps> = () => {
  const [summerCollection, setSummerCollection] = useState<any>([]);
  const [madeCollection, setMadeCollection] = useState<any>([]);

  const data = useStaticQuery(graphql`
    query {
      allStrapiMadecollection {
        nodes {
          redirectTo
          description
          logo {
            url
          }
        }
      }
      allStrapiStockquality(sort: {fields: strapi_id, order: ASC}) {
        nodes {
          redirectTo
          title
        }
      }
    }
  `);

  useEffect(() => {
    setSummerCollection(data.allStrapiMadecollection.nodes);
    setMadeCollection(data.allStrapiStockquality.nodes);
  }, [summerCollection, madeCollection]);

  return (
    <>
      {/* {(!data ||
        summerCollection.length === 0 ||
        madeCollection.length === 0) && <Loader />} */}
      <div className="container-fluid u-margin-top-2">
        <p className="spring-summer-title u-text-center u-margin-bottom-1">
          2025 Spring & Summer
        </p>
        <div className="">
          {/* <p className="spring-summer-title u-text-center">Stock Qualities</p> */}
          <div className="row u-margin-bottom-5 u-margin-top-1 u-justify-center content-padding-cards">
            {madeCollection?.map((stock: any, i) => (
              <div className="card-summer-collection" key={i}>
                <div className="content-stock-item-box">
                  {/* style={{ backgroundImage: `url(${stock.banner.url})` }} */}
                  <Link to={`/products/?${stock.redirectTo}`}>
                    <p>{stock.title}</p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <p className="spring-summer-title u-text-center u-margin-bottom-1">
            Made to Order Collection
          </p>
          <div className="row u-margin-top-1 u-justify-center content-padding-cards">
            {summerCollection?.map((order: any, i) => (
              <div
                className="card-summer-collection u-margin-bottom-2 u-margin-top-1 content-collection-logos"
                key={i}
              >
                <Link to={`/products/?${order?.redirectTo}`}>
                  <div className="content-logos-bg">
                    <img
                      src={order?.logo.url}
                      alt=""
                      className="collection-logos"
                      width="170"
                      height="auto"
                    />
                  </div>

                  <p className="text-collection-logos">{order?.description}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
