import { useStaticQuery, graphql } from "gatsby"

const useProducts = () => {
  const data = useStaticQuery(graphql`
    query {
      allGoogleSheetDb(sort: { 
        order: DESC, 
        fields: regularPrice }) {
        nodes {
          supplier_sProductName
          supplierId
          searchWord
          productSummery
          productId
          collectionTag
          productDetail
          colorNumber_productCategory_
          inventory
          id
          detailAttribute
          fabricName
          articleNumber
          customerSpcialOrRegular
          image
          detail
          detailCategory
          detailSummery
          material
          materialCategory
          materialSummery
          regularPrice
          priceB
          weight
          weightNumber__g_m2_
          video
          color
          functions
          finish
          construction
          patternAndDesign
          finishLookAndFeel
          subImage01
          subImage02
          subImage03
          subImage04
        }
      }
    }
  `)
  return data.allGoogleSheetDb?.nodes?.map(product => ({
    supplier_sProductName: product?.supplier_sProductName || "",
    supplierId: product?.supplierId || "",
    searchWord: product?.searchWord || "",
    fabricName: product?.fabricName || "",
    productSummery: product?.productSummery || "",
    productId: product?.productId || "",
    collectionTag: product?.collectionTag || "",
    productDetail: product?.productDetail || "",
    colorNumber_productCategory_: product?.colorNumber_productCategory_ || "",
    inventory: product?.inventory,
    id: product?.id || "",
    detailAttribute: product?.detailAttribute || "",
    articleNumber: product.articleNumber || "",
    customerSpcialOrRegular: product?.customerSpcialOrRegular || "",
    image: product?.image || "",
    detail: product?.detail || "",
    detailCategory: product?.detailCategory || "",
    detailSummery: product?.detailSummery || "",
    material: product?.material || "",
    materialCategory: product?.materialCategory || "",
    materialSummery: product?.materialSummery || "",
    regularPrice: product?.regularPrice,
    priceB: product?.priceB,
    weight: product?.weight || "",
    weightNumber: product?.weightNumber__g_m2_ || "",
    video: product?.video ?? "",
    color: product?.color || "",
    functions: product?.functions || "",
    finish: product?.finish || "",
    construction: product?.construction || "",
    patternAndDesign: product?.patternAndDesign || "",
    finishLookAndFeel: product?.finishLookAndFeel || "",
    subImage01: product?.subImage01 ||"",
    subImage02: product?.subImage02 ||"",
    subImage03: product?.subImage03 ||"",
    subImage04: product?.subImage04 ||"",
  }))
}

export default useProducts
