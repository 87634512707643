import styled from "@emotion/styled"
export const Item = styled.li`
  display: flex;
  padding: 1em;
  align-items: center;
  // border-bottom: 1px solid var(--color-dark-shade);
  img {
    width: 75px;
    height: 75px;
    border-radius: 3px;
  }
  span {
    display: block;
    font-size: 1rem;
    letter-spacing: 0.2px;
    color: var(--color-dark);
    font-weight: 300;
  }
  p {
    display: flex;
    margin-top: 0.1em;
    span {
      margin-right: 1em;
    }
  }
`
export const List = styled.ul`
  padding: 0.5em;
  display: block;
  color: var(--color-dark);
  font-weight: 300;
  b {
    font-size: 1.5em;
    font-weight: 900;
  }
  li {
    font-size: 1rem;
  }
`
export const Text = styled.div`
  margin-left: 1em;
`
export const Tittle = styled.p`
  padding: 0.4em;
  margin: 1em 0;
  display: flex;
  font-size: 1.3em;
  border-radius: 3px;
  align-items: center;
  color: var(--color-dark);
  text-transform: uppercase;
  font-weight: 900;
  background: var(--color-dark-shade);
  img {
    width: 27px;
    margin-left: 0.2em;
    margin-right: 0.5em;
  }
  .delivery-icon {
    width: 35px;
  }
`
export const ShippingMethod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-dark);
  padding: 0.5em;  
  justify-content: center;
  align-items: center;
  margin-top: 2.5em;
  b {
    font-size: 14px;
    font-weight: 900;
  }
  select {
    width: 160px;
    margin: 0.5em 0;
    text-align: center;
    border: 1px solid black;
    option {
      font-size: 1rem;
    }
  }
`

export const Select = styled.select`
  height: 40px;
  padding: 0 1em;
  margin: 0 0.5em;
  border-radius: 3px;
  font-weight: 300;
  border: 1px solid var(--color-dark-shade);
`
export const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  height: 100px;
  padding: 10px;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: 300;
  border: 1px solid var(--color-dark-shade);
`
export const Buttons = styled.div`
  margin: 1em 0 0 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 0.5em;
  }
`
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "55%",
    right: "auto",
    border: "0px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 5px 15px -5px rgba(79, 79, 79, 0.6)",
  },
}

export const customStylesConfirmAddress = {
  content: {
    top: "50%",
    left: "50%",
    width: "45%",
    borderRadius: "10px",
    right: "auto",
    border: "0px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 5px 15px -5px rgba(79, 79, 79, 0.6)",
  },
}

export const modalLink = {
  content: {
    top: "50%",
    left: "50%",
    width: "50%",
    borderRadius: "10px",
    right: "auto",
    border: "0px",
    padding: "3em 1em ",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 5px 15px -5px rgba(79, 79, 79, 0.6)",
  },
}

