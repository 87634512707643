import { navigate } from "gatsby";
import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { useQuery } from "@apollo/client";
import { FIND_PRODUCT_ID } from "../querys";
import { QrSacanner } from "./styled/Button";
const ScanProduct = ({ setShowCamera }) => {
  const [data, setData] = useState("");

  const resetClick = () => {
    setData("");
  };
  const { data: FIND_ID } = useQuery(FIND_PRODUCT_ID, {
    variables: { search: data.toUpperCase() },
  });
  // useEffect(() => {
  //   if (FIND_ID?.products?.data?.length === 0) {
  //     setSearchId("ES CEROOOOO");
  //   } else {
  //     setSearchId("NO ES CEROROOO SE ENCUTRA");
  //   }
  // }, [data, setShowCamera, FIND_ID]);

  const handleQResults = (result, error) => {
    if (!!result) {
      setData(result?.text.toUpperCase());
      const lastItem = result?.text.split("/");
      const productId = lastItem[lastItem.length - 1];
      navigate(`/products/${productId.toUpperCase()}`);

      setTimeout(() => {
        resetClick();
        setShowCamera(false);
      }, 1000);
    }

    if (!!error) {
      resetClick();
    }
  };

  return (
    
      <QrSacanner className="qr-scanner">
        <div className="video-container">
          {/* <div className="box-scan">
            <div className="codebar-scanning"></div>
            <em></em>
          </div> */}
          <button
            className="btn-close-qr-camera"
            onClick={() => {
              resetClick(), setShowCamera(false);
            }}
          >
            Back
          </button>
          <QrReader
            scanDelay={500}
            className="qrscan-container"
            constraints={{ facingMode: "environment" }}
            onResult={handleQResults}
          />
          {data && <p className="qr-tooltip">{data}</p>}
          {/* {searchId && <p className="qr-tooltip">{searchId}</p>} */}
        </div>
      </QrSacanner>
 
  );
};
export default ScanProduct;
