import { useStaticQuery, graphql } from "gatsby";

const useIndexMap = () => {
  const data = useStaticQuery(graphql`
    {
      allTokyoMapsIndexMap {
        nodes {
          index
          reference
          order
        }
      }
    }
  `);
  return data;
};

export default useIndexMap;
