import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

import { FilterPriceRange } from "../../models/filter";
import { CollapseFilter, TitleStyle } from "./styled-widget";
import _ from "underscore";
import Collapsible from "react-collapsible";

const PriceRangeWidget = ({
  rangeData,
  handleFilter,
  min_selected,
  max_selected,
}) => {
  const [ranges, setRanges] = useState<FilterPriceRange[]>([]);

  const [collapse, setCollapse] = useState<boolean>(false);
  useEffect(() => {
    _.each(
      rangeData,
      _.iteratee((range) =>
        setRanges((prevState) => [...prevState, range.split(",")])
      )
    );
  }, [rangeData]);

  return (
    <CollapseFilter>
      <Collapsible
        trigger={
          <TitleStyle onClick={() => setCollapse(!collapse)}>
            Price Range
            <IoIosArrowDown
              style={{
                transform: `${collapse ? "rotate(180deg)" : "rotate(0deg)"} `,
              }}
            />
          </TitleStyle>
        }
        transitionTime={200}
      >
        <ul>
          {ranges
            .slice(0, collapse ? ranges.length : 0)
            .map((price: FilterPriceRange, i) => {
              // const isSelected = selected.includes(item)
              return (
                <li key={i}
                  onClick={() => handleFilter(price[0], price[1])}
                  style={{
                    fontWeight: `${
                      min_selected === price[0] && max_selected === price[1]
                        ? "bold"
                        : ""
                    }`,
                  }}
                >
                  {price[0] === "Over" ? "" : "$"}
                  {price[0]} - ${price[1]}/yard
                </li>
              );
            })}
        </ul>
      </Collapsible>
    </CollapseFilter>
  );
};

export default PriceRangeWidget;
