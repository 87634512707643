import React, { useState, useEffect } from "react"

import OrderProgressBar from "./order-progressbar"
import DataTable from "react-data-table-component"
import { customStylesTable } from "../../../services/modal"

const OrderInformation = ({ dataOrder, defaultDataFedex, page }) => {
  const [uppage, setUpPage] = useState(0)
  useEffect(() => {
    if (uppage === 0) window.scrollTo(0, 0)
    
  })

  const [isub_menu, setISubMenu] = useState(false)
  const handleClickItem = e => {
    if (isub_menu === false) {
      e.target.closest("div").classList.add("item_selected")
      setISubMenu(true)
      setUpPage(1)
    } else {
      e.target.closest("div").classList.remove("item_selected")
      setISubMenu(false)
      setUpPage(1)
    }
  }

  const columns = [
    {
      name: "Item",
      center: true,
      cell: row => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={`https://drive.google.com/thumbnail?authuser=0&sz=w120&id=${
              row.image.match(/[-\w]{25,}/)
                ? row.image.match(/[-\w]{25,}/)[0]
                : ""
            }`}
            alt="product"
            className="table_image"
          />
          <span className="product_down" onClick={handleClickItem}>
            &nbsp;&nbsp;▼
          </span>
          <div className="product_info">
            <p className="product_info_number">{row.articleNumber}</p>
            <p className="product_info_color">Color {row.colorNumber}</p>
            <p className="product_info_quantity">Quantity {row.quantity}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Weight",
      center: true,
      selector: row => "Where to get it?",
    },
    {
      name: "Size",
      center: true,
      selector: row => "Where to get it?",
    },
    {
      name: "Total Price",
      center: true,
      selector: row => "$ " + row.totalPrice,
    },
    {
      name: "Shipment Order",
      center: true,
      selector: row => "Where to get it?",
    },
  ]

  return (
    <>
      <OrderProgressBar page={page}></OrderProgressBar>
      <form>
        <h2 className="order-subtitle">Order Information Product</h2>
        {dataOrder.detailProducts.length > 0 ? (
          <div className="table_style_product">
            <DataTable columns={columns} data={dataOrder.detailProducts} customStyles={customStylesTable}/>
          </div>
        ) : (
          <p className="no-data-order"> No Swatch on this order...</p>
        )}
        <h2 className="order-subtitle">Order Information Swatch</h2>
        {dataOrder.detailSwatch.length > 0 ? (
          <div className="table_style_product">
            <DataTable columns={columns} data={dataOrder.detailSwatch} customStyles={customStylesTable}/>
          </div>
        ) : (
          <p className="no-data-order"> No Swatch on this order...</p>
        )}
        <h2 className="order-subtitle">Customs Clearance Detail</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Payment Type</label>
            <input value={defaultDataFedex.paymentType}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Commodities Description</label>
            <input value={defaultDataFedex.commoditiesDescription}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Country of Manufacture</label>
            <input value={defaultDataFedex.countryOfManufacture}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Quantity</label>
            <input value={defaultDataFedex.quantity}></input>
          </div>
        </div>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Quantity Units</label>
            <input value={defaultDataFedex.quantityUnits}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Amount</label>
            <input value={defaultDataFedex.amount}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Currecy</label>
            <input value={defaultDataFedex.currency}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Customs Value Amount</label>
            <input value={defaultDataFedex.customsValueAmount}></input>
          </div>
        </div>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Customs Value Currency</label>
            <input value={defaultDataFedex.customsValueCurrency}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Unit Weight</label>
            <input value={defaultDataFedex.commoditiesUnitWeight}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Unit Value</label>
            <input value={defaultDataFedex.commoditiesUnitValue}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Document Only</label>
            <input value={defaultDataFedex.documentOnly}></input>
          </div>
        </div>
        <h2 className="order-subtitle">Shipping Document Specification</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Shipping Document Type</label>
            <input value={defaultDataFedex.shippingDocumentType}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Stock Type</label>
            <input value={defaultDataFedex.stockType}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Document Format Type</label>
            <input value={defaultDataFedex.documentFormatType}></input>
          </div>
        </div>
        <h2 className="order-subtitle">Shipping Information</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Service Type</label>
            <input value={defaultDataFedex.serviceType}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Packaging Type</label>
            <input value={defaultDataFedex.packagingType}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Pickup Type</label>
            <input value={defaultDataFedex.pickupType}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Shipping Charges Payment</label>
            <input value={defaultDataFedex.shippingChargesPayment}></input>
          </div>
        </div>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Block Insight Visibility</label>
            <input value={defaultDataFedex.blockInsightVisibility}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Ship Date Stamp</label>
            <input value={defaultDataFedex.shipDateStamp}></input>
          </div>
        </div>
        <h2 className="order-subtitle">Label Specification</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Image Type</label>
            <input value={defaultDataFedex.imageType}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Labe Stock Type</label>
            <input value={defaultDataFedex.labelStockType}></input>
          </div>
        </div>
        <h2 className="order-subtitle">Requested Package Line Items</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Unit Weight</label>
            <input value={defaultDataFedex.requestedUnitWeight}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Unit Value</label>
            <input value={defaultDataFedex.requestedUnitValue}></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label>Account Number Value</label>
            <input value={defaultDataFedex.accountNumberValue}></input>
          </div>
        </div>
      </form>
    </>
  )
}
export default OrderInformation
