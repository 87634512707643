import { apiBase, apiBigImage, apiImage, apiVideo } from "./appenv";
// import noImage from "../images/icons/no-image.svg";

import { toast } from "react-toastify";

export const getPaymentIntent = async (token: string, data: any) => {
  if (!token || !data) {
    return toast.error("Error connecting to server, please try again later");
  }

  const resp = await fetch(`${apiBase}/api/send-to-stripe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(data),
  });
  const json = await resp.json();
  return json;
};

export const updatePaymentIntent = async (token: string) => {
  const resp = await fetch(`${apiBase}/orderhistories/updatepayment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: "",
  });
  const json = await resp.json();

  return json;
};

export const createCustomer = async (token: string, data: any) => {
  const resp = await fetch(`${apiBase}/orderhistories/createcustomer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(data),
  });
  const json = await resp.json();
  return json;
};

export const createCharge = async (token: string, data: any) => {
  const resp = await fetch(`${apiBase}/orderhistories/createcharge`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(data),
  });
  const json = await resp.json();
  return json;
};

export const addDataToGoogleSheet = async (token: string, data: any) => {
  const resp = await fetch(`${apiBase}/api/send-orders-to-sheet`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(data),
  });
  const json = await resp.json();
  return json;
};

export const sendOrderMail = async (token: string, data: any) => {
  const resp = await fetch(`${apiBase}/api/mails`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(data),
  });
  const json = await resp.json();
  return json;
};

export const getImageFromDrive = (image: any, size?: string) => {
  return `${apiImage(size)}${
    image?.match(/[-\w]{25,}/) && image?.match(/[-\w]{25,}/)[0]
  }`;
};

export const getBigImageFromDrive = (image: any, size?: string) => {
  return `${
    image?.match(/[-\w]{25,}/) &&
    apiBigImage + image?.match(/[-\w]{25,}/)[0] + "&s=300x150"
  }`;
};

export const getVideoFromDrive = (video: string) => {
  return `${apiVideo}${
    video?.match(/[-\w]{25,}/) && video?.match(/[-\w]{25,}/)[0]
  }`;
};

export const sendContactUsMail = async (data: any) => {
  const resp = await fetch(`${apiBase}/contact-us-forms/mail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await resp.json();
  return json;
};

export const sendMailPreRegister = async (data: any, token: any) => {
  const resp = await fetch(`${apiBase}/api/send-mail-owner`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(data),
  });
  const json = await resp.json();
  return json;
};

export const getLinkProducts = async (data: any, token: string) => {
  const resp = await fetch(`${apiBase}/api/generate-url`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(data),
  });
  const json = await resp.json();
  return json;
};

export const getUser = async (email: any, token: string) => {
  const resp = await fetch(
    `${apiBase}/api/users?filters[email][$eq]=${email}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const json = await resp.json();
  return json;
};

export const sendMessageToChat = async (message: any) => {
  const resp = await fetch(`${apiBase}/api/tl-chat`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(message),
  });
  const json = await resp.json();
  return json;
};

export const sendSearchHistory = async (token: string, data: {}) => {
  const resp = await fetch(`${apiBase}/api/search-history`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(data),
  });
  const json = await resp.json();
  return json;
};

export const getCurrentUser = async (token) => {
  const resp = await fetch(`${apiBase}/api/users/me?populate=role`, {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const json = await resp.json();
  return json;
};


export const getCartItem = async (id: string, token: string) => {
  const resp = await fetch(
    `${apiBase}/api/cart-items?filters[user_permissions][$eq]=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    }
  );
  const json = await resp.json();
  return json;
};
