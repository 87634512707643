import { useStaticQuery, graphql } from "gatsby"

const useAddress = () => {
  const queryAddress = useStaticQuery(graphql`
    query {
      allGoogleSheetShipping(sort: {order: ASC, fields: area}) {
        nodes {
          area
        }
      }
    }
  `)
  return queryAddress
}

export default useAddress