import { Link } from "gatsby";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import MenuCollection from "./menu-collection";
import MenuByFabric from "./menu-by-fabric";
import { navigate } from "gatsby";
// Styled
import { MenuStyled } from "./style";
import { IoIosArrowDown } from "react-icons/io";
import Collapsible from "react-collapsible";
import { GetQuerysContext } from "../../context/GetQuerysContext";
import { AuthUserContext } from "../../context/AuthUser";
import { CURRENT_USER, USER_DATA } from "../../querys";
import { useQuery } from "@apollo/client";

const getFilters = (params) => {
  const filter = Object.fromEntries(params.entries());
  return { ...filter };
};

const MenuFilter = ({ handleShowMenu }) => {
  const { data } = useQuery(CURRENT_USER);
  const { authUser, setAuthUser } = useContext(AuthUserContext);

  const { data: userData } = useQuery(USER_DATA, {
    variables: { id: authUser ? authUser?.user?.id || data?.me?.id : "" },
  });

  const { AllQuery } = useContext(GetQuerysContext);
  const backdrop_collection = useRef(null);
  const backdrop_fabric = useRef(null);
  const closeCollectionMenu = () => {
    backdrop_collection.current.classList.add("u-display-none");
    backdrop_fabric.current.classList.add("u-display-none");
    setTimeout(() => {
      backdrop_collection.current.classList.remove("u-display-none");
      backdrop_fabric.current.classList.remove("u-display-none");
    }, 500);
  };

  const params = useMemo(() => new URLSearchParams(AllQuery), [AllQuery]);
  const updateURL = (newValues) => {
    Object.keys(newValues).forEach((key) => {
      let value = newValues[key];
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (!value) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    });
    navigate(`/products/?${params.toString()}`);
    // }
  };

  const [filter, setFilter] = useState(getFilters(params));
  const filterRef = useRef(filter);
  filterRef.current = filter;

  const [smartphoneMenu, setSmartphoneMenu] = useState(false);

  const mediaQuery = window.matchMedia("(max-width: 992px)").matches;

  useEffect(() => {
    if (mediaQuery) {
      setSmartphoneMenu(true);
    } else {
      setSmartphoneMenu(false);
    }
  }, [mediaQuery]);

  const handleSmartphone = () => {
    setSmartphoneMenu(!smartphoneMenu);
  };

  const onChangeFilterMenu = (filter) => {
    updateURL(filter);
    // setFilter(filter);
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...filter,
    }));
  };

  const [collapseCollection, setCollapseCollection] = useState(false);
  const [collapseByFabric, setCollapseByFabric] = useState(false);

  return (
    <MenuStyled>
      <ul className="header-collection">
        <li>
          {mediaQuery ? (
            <Collapsible
              trigger={
                <span
                  onClick={() => setCollapseCollection(!collapseCollection)}
                >
                  Collection
                  <IoIosArrowDown
                    className="filter-arrow"
                    style={{
                      transform: `${
                        collapseCollection ? "rotate(180deg)" : "rotate(0deg)"
                      } `,
                    }}
                  />
                </span>
              }
              transitionTime={200}
            >
              <MenuCollection
                filter={filter}
                onChangeFilter={(filter) => {
                  updateURL(filter);
                  setFilter(filter);
                }}
                handleShowMenu={handleShowMenu}
                reference={backdrop_collection}
                closeCollectionMenu={closeCollectionMenu}
              />
            </Collapsible>
          ) : (
            <>
              <Link to="#" className="show-desktop">
                Collections
                <IoIosArrowDown className="filter-arrow" />
              </Link>
              <div className="blur-content"></div>
              <MenuCollection
                filter={filter}
                onChangeFilter={(filter) => {
                  updateURL(filter);
                  setFilter(filter);
                }}
                handleShowMenu={handleShowMenu}
                reference={backdrop_collection}
                closeCollectionMenu={closeCollectionMenu}
              />
            </>
          )}
        </li>
        <li>
          {mediaQuery ? (
            <Collapsible
              trigger={
                <span onClick={() => setCollapseByFabric(!collapseByFabric)}>
                  By Fabric
                  <IoIosArrowDown
                    className="filter-arrow"
                    style={{
                      transform: `${
                        collapseByFabric ? "rotate(180deg)" : "rotate(0deg)"
                      } `,
                    }}
                  />
                </span>
              }
              transitionTime={200}
            >
              <MenuByFabric
                filter={filter}
                onChangeFilter={(filter) => {
                  updateURL(filter);
                  setFilter(filter);
                }}
                handleShowMenu={handleShowMenu}
                reference={backdrop_fabric}
                closeCollectionMenu={closeCollectionMenu}
              />
            </Collapsible>
          ) : (
            <>
              <Link to="#">
                By Fabric <IoIosArrowDown className="filter-arrow" />
              </Link>
              <div className="blur-content"></div>
              <MenuByFabric
                filter={filter}
                onChangeFilter={(filter) => {
                  updateURL(filter);
                  setFilter(filter);
                }}
                handleShowMenu={handleShowMenu}
                reference={backdrop_fabric}
                closeCollectionMenu={closeCollectionMenu}
              />
            </>
          )}
        </li>
        <li>
          <p
            className="btn-link-menu"
            onClick={() => {
              onChangeFilterMenu({
                by_fabric: "",
                stockoperationorbyorder: "",
                sustainable: "Sustainable",
                collection: "",
              }),
                handleShowMenu();
            }}
          >
            Sustainable{" "}
          </p>
        </li>
        <li>
          <p
            className="btn-link-menu"
            onClick={() => {
              onChangeFilterMenu({
                by_fabric: "",
                stockoperationorbyorder: "Stock Operation",
                sustainable: "",
                collection: "",
              }),
                handleShowMenu();
            }}
          >
            Stock Operation
          </p>
        </li>
        {userData?.usersPermissionsUser.data.attributes.tagForMe === "" ||
        !userData?.usersPermissionsUser.data.attributes?.tagForMe ? (
          ""
        ) : (
          <li style={{ display: "flex", alignItems: "center" }}>
            <button
              className="btn-link-menu-tag"
              onClick={() => {
                onChangeFilterMenu({
                  tag: userData?.usersPermissionsUser.data.attributes?.tagForMe,
                  by_fabric: "",
                  stockoperationorbyorder: "",
                  sustainable: "",
                  collection: "",
                }),
                  handleShowMenu();
              }}
            >
              For You
            </button>
          </li>
        )}
      </ul>
    </MenuStyled>
  );
};

export default MenuFilter;
