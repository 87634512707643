import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { navigate } from "gatsby";
import { SearchContext } from "../context/SearchContext";
import { FilterContext } from "../context/FilterContext";
import { GetQuerysContext } from "../context/GetQuerysContext";
import { sendSearchHistory } from "../services/api";
import { toast } from "react-toastify";

const getFilters = (params) => {
  if (params) {
    const filter = Object.fromEntries(params?.entries());

    return { ...filter };
  }
};

const MenuSearch = ({ user }) => {
  const { AllQuery } = useContext(GetQuerysContext);

  const { setKeySearch, keySearch } = useContext(SearchContext);
  const { setDataProductsFilter } = useContext(FilterContext);
  useEffect(() => {
    setKeySearch({ nameKey: "", tagKey: [], tagButton: [] });
    return () => {
      setDataProductsFilter([]);
    };
  }, []);
  const handleChangeSearch = (event) => {
    const query = event.target.value;
    const queryWithRemoveSpace = query.split(" ").join(",");

    setKeySearch({ nameKey: queryWithRemoveSpace, tagKey: [], tagButton: [] });
    if (query.trim().length === 0) {
      event.target.value = "";
    }
  };

  const params = useMemo(() => new URLSearchParams(AllQuery), [AllQuery]);
  const [filter, setFilter] = useState(getFilters(params));
  const filterRef = useRef(filter);
  filterRef.current = filter;

  const updateURL = (newValues) => {
    Object.keys(newValues).forEach((key) => {
      let value = newValues[key];
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (!value) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    });
    navigate(`/products/?${params.toString()}`);
    // }
  };

  const onChangeFilterMenu = async (e, filter) => {
    e.preventDefault();
    await sendSearchHistory(localStorage.getItem("token"), {
      Date: new Date().toLocaleString(),
      user: user,
      search_key: keySearch.nameKey,
    })
      .then((response) => console.log(""))
      .catch(() => toast.error("Data not found, please try again"));
    updateURL(filter);
    setFilter(filter);
  };
  
  return (
    <>
      <div className="search-container" id="search-container">
        <form
          className="search-box"
          onSubmit={(e) =>
            onChangeFilterMenu(e, { ...filter, q: keySearch.nameKey })
          }
        >
          <input
            type="text"
            className="text search-input"
            onKeyUp={(e) => handleChangeSearch(e)}
            placeholder="Search Product..."
          />
        </form>
      </div>
    </>
  );
};

export default MenuSearch;
