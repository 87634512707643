import React from "react"
import { Helmet } from "react-helmet"

import { StaticQuery, graphql } from "gatsby"

export const Seo = ({ description, keywords, image, title, url, author }) => {
  return (
    <StaticQuery
      query={detailsSeo}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaUrl = url || data.site.siteMetadata.url
        const metaImage = image || data.site.siteMetadata.image
        const metaKeywords = keywords || []
        const metaAuthor = author || data.site.siteMetadata.author
        return (
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
            title={title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:image:width`,
                content: "auto",
              },
              {
                property: `og:image:height`,
                content: "auto",
              },
              {
                property: `og:image:secure_url`,
                content: metaImage,
              },
              {
                property: `og:site_name`,
                content: "Textile Library",
              },
              {
                property: `og:locale`,
                content: "en_EN",
              },
              {
                property: `og:type`,
                content: "page",
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:author`,
                content: metaAuthor,
              },
              {
                name: `twitter:site`,
                content: "@textilelibrary",
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: metaImage,
              },
              {
                name: `twitter:card`,
                content: "summary_large_image",
              },
              {
                name: `twitter:creator`,
                content: "@textilelibrary",
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords,
                  }
                : []
            )}
          ></Helmet>
        )
      }}
    />
  )
}

const detailsSeo = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        image
      }
    }
  }
`
