import styled from "@emotion/styled"

export const FooterComponent = styled.footer`
  margin-top: 5em;
  padding-top: 5em;
  padding-bottom: 4em;
  background: var(--color-dark-tint);
  border-top: 1px solid var(--color-dark-shade);
  span {
    font-size: 0.8rem;
    font-weight: 900;
    color: var(--color-primary-light);
  }
  h4 {
    font-size: 2rem;
    font-weight: 900;
    color: var(--color-primary-light);
  }
  p {
    font-size: 0.8rem;
    letter-spacing: 1px;
    color: var(--color-primary-light);
  }
`
export const List = styled.ul`
  margin-bottom: 5em;
  li {
    margin: 2px 0;
    font-size: 0.9rem;
    color: var(--color-primary-light);
    font-weight: 300;
    a {
      color: var(--color-primary);
    }
  }
`
