import { useStaticQuery, graphql } from "gatsby"

const useGetData = () => {
  const getData = useStaticQuery(graphql`
    query {
      allGoogleSheetMenuCollection {
        nodes {
          collection
          items
          shortLink
        }
      }
      allGoogleSheetMenuByfabric {
        nodes {
          fabric
          subCategory
          items
          shortLink
        }
      }
      allGoogleSheetFilterItems {
        nodes {
          priceRange
          weight
          color
          materials
          function
          finish
          construction
          pattern__Design
          finishLook__Feel
        }
      }
    }
  `)
  return getData
}

export default useGetData
