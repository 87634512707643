import React, { useState, useEffect, useContext } from "react";
import { Link, navigate } from "gatsby";
import { useMutation } from "@apollo/client";
import { NEW_USER } from "../mutations";
import { toast } from "react-toastify";
import client from "../apollo/client";
import { FilterContext } from "../context/FilterContext";
import textileLibraryLogo from "../images/textile_library_logo.png";
import { tokenMail } from "../services/appenv";
import { sendMailPreRegister } from "../services/api";

const RegisterTokyoShowroom = () => {
  const { setDataProductsFilter, setTempDataFilter } =
    useContext(FilterContext);
  // Regex for email
  const emailRegex = RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  /**
   * State for registration inputs
   */
  const [register, setRegister] = useState({
    firstname: "",
    lastname: "",
    companyName: "",
    email: "",
    password: "",
    messageError: {},
  });
  const { messageError } = register;

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("user_data")) {
        navigate("/orders");
      } else {
        navigate("/products");
      }
    } else {
      client.clearStore();
      localStorage.removeItem("token");
      localStorage.removeItem("user_data");
    }
    return () => {
      setDataProductsFilter([]);
      setTempDataFilter([]);
    };
  }, []);

  const formValidate = (errors, ...data) => {
    let valid = true;
    Object.values(errors).forEach((val) => {
      val && (valid = false);
    });
    Object.values(data).forEach((val) => {
      val === "" && (valid = false);
    });
    return valid;
  };
  /**
   * Method that captures the value of each input
   * @param {*Parameter for input} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    let errros = register.messageError;
    switch (name) {
      case "firstname":
        errros.firstname = value === "" ? "This field is required" : "";
        break;
      case "lastname":
        errros.lastname = value === "" ? "This field is required" : "";
        break;
      case "email":
        errros.email =
          value === ""
            ? "This field is required"
            : "" || emailRegex.test(value)
            ? ""
            : "This is not a valid email";
        break;
      case "password":
        errros.password = value === "" ? "This field is required" : "";
        break;
      default:
        break;
    }
    setRegister((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValidate(register.messageError, register)) {
      handleRegisterUser();
      handleSendEmail();
      e.target.reset();
    } else {
      toast.error("Please complete the form");
    }
  };
  const handleSendEmail = async () => {
    const dataPreRegister = {
      email: register.email,
      firstName: register.firstname,
      lastName: register.lastname,
      companyName: register.companyName,
      typecustomer: "Special_customer",
      registrationform: "Tokyo_Form",
    };
    await sendMailPreRegister(dataPreRegister, tokenMail);
  };
  const handleRegisterUser = () => {
    addUser({
      variables: {
        data: {
          firstName: register.firstname,
          lastName: register.lastname,
          password: register.password,
          username: register.email,
          email: register.email,
          companyName: register.companyName,
          role: 1,
          confirmed: true,
          typecustomer: "Special_customer",
          registrationform: "Tokyo_Form",
        },
      },
    });
  };

  const [addUser, { loading: mutationLoading, error: mutationError }] =
    useMutation(NEW_USER, {
      onCompleted: () => {
        toast.success("Your registration was completed successfully");
        setRegister({
          firstname: "",
          lastname: "",
          companyName: "",
          email: "",
          password: "",
          messageError: {},
        });
        navigate("/successful-registration");
      },

      onError: (error) => {
        toast.error("The user is already registered");
      },
    });

  return (
    <>
      <div className="home-flex-content">
        <div className="home-page">
          <div className="home-page__text">
            <img src={textileLibraryLogo} alt="" />
            <h3>Register for scan barcode </h3>
          </div>
          <form
            noValidate
            className="home-page__form"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="u-position-relative">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.email ? "border-error" : ""}
                placeholder="Email Address *"
              />
              {messageError.email && (
                <small className="field-error field-error-home">
                  {messageError.email}
                </small>
              )}
            </div>
            <div className="u-position-relative">
              <input
                type="text"
                name="companyName"
                onChange={handleChange}
                onBlur={handleChange}
                placeholder="Company / Brand Name"
              />
            </div>
            <div className="fields-group-name-lastname">
              <div className="u-position-relative">
                <input
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  onBlur={handleChange}
                  className={messageError.firstname ? "border-error" : ""}
                  placeholder="First Name*"
                />
                {messageError.firstname && (
                  <small className="field-error field-error-home">
                    {messageError.firstname}
                  </small>
                )}
              </div>
              <div className="u-position-relative">
                <input
                  type="text"
                  name="lastname"
                  onChange={handleChange}
                  onBlur={handleChange}
                  className={messageError.lastname ? "border-error" : ""}
                  placeholder="Last Name *"
                />
                {messageError.lastname && (
                  <small className="field-error field-error-home">
                    {messageError.lastname}
                  </small>
                )}
              </div>
            </div>
            <div className="u-position-relative">
              <input
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.password ? "border-error" : ""}
                placeholder="Password *"
              />
              {messageError.password && (
                <small className="field-error field-error-home">
                  {messageError.password}
                </small>
              )}
            </div>
            <button
              type="submit"
              className="u-text-uppercase btn btn-primary btn-submit"
            >
              Submit
            </button>
          </form>
          <p className="home-page__link">
            <Link to="/login">LOGIN</Link>
          </p>
        </div>

        {/* <div className="home-page__links-footer">
          <Link to="/login">LOGIN</Link>
          <Link to="#">About</Link>
          <Link to="/terms-and-conditions">Terms & Condition</Link>
        </div> */}
      </div>
    </>
  );
};

export default RegisterTokyoShowroom;
