import React, { useState } from "react"

import { Container } from "../../components/styled/ProductsStyled"
import { USER_ORDER, getFedexOrder } from "../../querys"
import { useQuery, useMutation } from "@apollo/client"
import Loading from "../../components/Loading"
import axios from "axios"
import { toast } from "react-toastify"
import ShippingInformation from "./components/ShippingInformation"
import Modal from "react-modal"
import { AddFedexOrder, ModifyStatusOrder } from "../../mutations"
const UserOrderPage = ({ id }) => {
  const oid = id
  const [lswatch, setLSwatch] = useState()
  const [lproduct, setLProduct] = useState()
  const [dataSendShipment, setDataSendShipment] = useState()

  const [pending, setPendig] = useState(false)
  const [dbutton, setDButton] = useState(true)

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      width: "55%",
      right: "auto",
      border: "0px",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      boxShadow: "0px 5px 15px -5px rgba(79, 79, 79, 0.6)",
    },
  }
  const {data: fedexOrder} = useQuery(getFedexOrder, {
       variables: {
         order_id: oid,
       },
       onCompleted:() => {}
     })
 

  const { data: dataOrder, loading: queryLoading } = useQuery(USER_ORDER, {
    variables: { order_id: oid },
    onCompleted: () => {
      const dataSendShipment = {
        orderId: dataOrder.orders[0].id,
        personName: dataOrder.orders[0].username,
        companyName: dataOrder.orders[0].companyName,
      }
      setDataSendShipment(dataSendShipment)

      if (dataOrder.orders[0].status === "Sent") {
        setDButton(false)
      }

      setLSwatch(dataOrder.orders[0].detailSwatch.length)
      setLProduct(dataOrder.orders[0].detailProducts.length)
    },
  })

  const [urlShip, setUrlShip] = useState("")
  const [shippingInformation, setShippingInformation] = useState({})
  // const getFedexOrders = () => {
  //   axios.get("http://localhost:1337/fedexorders").then(response => {
  
  //   })
  // }

  const [modalTransaction, setModalTransaction] = useState(false)
  const closeModalTransaction = () => {
    setModalTransaction(false)
  }

  const [updateStatus] = useMutation(ModifyStatusOrder, {
    onCompleted: () => {
      setDButton(false)
    },
  })

  const [addFedexOrder] = useMutation(AddFedexOrder, {
    onCompleted: () => {},
    refetchQueries: ["orderUser"],
  })
  const handleSendFedex = () => {
    setPendig(true)
    updateStatus({
      variables: {
        order_id: {
          where: {
            id: oid,
          },
          data: {
            status: "Sent",
          },
        },
      },
    })
    axios
      .post(
        "https://takihyo-textile-admin.herokuapp.com/fedexorders/send",
        dataSendShipment
      )
      .then(response => {
        const { transactionId, output } = response.data
        
        const {
          serviceCategory,
          serviceType,
          shipDatestamp,
          shipmentDocuments,
        } = output.transactionShipments[0]
        setUrlShip(output.transactionShipments[0].shipmentDocuments[0].url)
        const dataInformation = {
          transactionId: transactionId,
          serviceCategory: serviceCategory,
          serviceType: serviceType,
          shipDatestamp: shipDatestamp,
          document1: {
            contentType: shipmentDocuments[0].contentType,
            trackingNumber: shipmentDocuments[0].trackingNumber,
            url: shipmentDocuments[0].url,
          },
          document2: {
            contentType: shipmentDocuments[1].contentType,
            trackingNumber: shipmentDocuments[1].trackingNumber,
            url: shipmentDocuments[1].url,
          },
        }
        setShippingInformation(dataInformation)

        addFedexOrder({
          variables: {
            input: {
              data: {
                order: oid,
                dataFedex: JSON.stringify(output),
                transaction_id: dataInformation.transactionId,
                category: dataInformation.serviceCategory,
                service_type: dataInformation.serviceType,
                ship_date: dataInformation.shipDatestamp,
                Shipping_pdf: dataInformation.document1.url,
                Commercial_invoice_pdf: dataInformation.document2.url,
              },
            },
          },
        })
        toast.success(`Success! transaction number: ${transactionId}`)

        window.location.reload();

      })
      .catch(error => {
        toast.error(`Error, please enter a valid address`)
      })
      .finally(() => {
        setPendig(false)
      })
  }
  return (
    <>
      {queryLoading ? (
        <>
          <Loading />
          <div className="message-loading">
            <div className="loading loading-card"></div>
            <p>Loading data.... Please wait.</p>
          </div>
        </>
      ) : (
        ""
      )}
      {pending ? (
        <>
          <Loading />
          <div className="message-loading">
            <div className="loading loading-card"></div>
            <p>Processing the shipment.... Please wait.</p>
          </div>
        </>
      ) : (
        ""
      )}
      <Container className="container">
        <div className="btn-transaction-modal">
          <h1 className="order-title">{"Order #" + oid} </h1>
          {dbutton ? (
            ""
          ) : (
            <button onClick={() => setModalTransaction(true)}>
              Fedex transaction information
            </button>
          )}
        </div>
        {dataOrder === undefined ? (
          ""
        ) : (
          <>
            <form>
              <h2 className="order-subtitle">User Data</h2>
              <div className="form-style">
                <div className="form-style__col col-md-6 col-sm-12">
                  <label>Company Name</label>
                  <input
                    value={dataOrder.orders[0].companyName}
                    disabled
                  ></input>
                </div>
                <div className="form-style__col col-md-6 col-sm-12">
                  <label>User Name</label>
                  <input value={dataOrder.orders[0].username} disabled></input>
                </div>
              </div>
              <div className="form-style">
                <div className="form-style__col col-md-4 col-sm-12">
                  <label>Email</label>
                  <input value={dataOrder.orders[0].email} disabled></input>
                </div>
                <div className="form-style__col col-md-4 col-sm-12">
                  <label>Phone</label>
                  <input value={dataOrder.orders[0].phone} disabled></input>
                </div>
                <div className="form-style__col col-md-4 col-sm-12">
                  <label>Order Date</label>
                  <input value={dataOrder.orders[0].DateOrder} disabled></input>
                </div>
              </div>
              <h2 className="order-subtitle">Shipping Data</h2>
              <div className="form-style">
                <div className="form-style__col col-md-6 col-sm-12">
                  <label>Address</label>
                  <input
                    value={dataOrder.orders[0].shippingAddress.address}
                    disabled
                  ></input>
                </div>
                <div className="form-style__col col-md-6 col-sm-12">
                  <label>Street</label>
                  <input
                    value={dataOrder.orders[0].shippingAddress.street}
                    disabled
                  ></input>
                </div>
              </div>
              <div className="form-style">
                <div className="form-style__col col-md-4 col-sm-12">
                  <label>City</label>
                  <input
                    value={dataOrder.orders[0].shippingAddress.city}
                    disabled
                  ></input>
                </div>
                <div className="form-style__col col-md-4 col-sm-12">
                  <label>Zip Code</label>
                  <input
                    value={dataOrder.orders[0].shippingAddress.zipcode}
                    disabled
                  ></input>
                </div>
                <div className="form-style__col col-md-4 col-sm-12">
                  <label>Phone Number</label>
                  <input
                    value={dataOrder.orders[0].shippingAddress.phoneNumber}
                    disabled
                  ></input>
                </div>
              </div>
              <h2 className="order-subtitle">Swatch Details</h2>
              {lswatch === 0 ? (
                <p className="no-data-order"> No Swatch on this order...</p>
              ) : (
                <ul className="order-list">
                  {dataOrder.orders[0].detailSwatch.map((swatch, i) => (
                    <li key={i}>
                      <div className="form-style">
                        <div className="form-style__col col-md-3 col-sm-6">
                          <label>Color Number</label>
                          <input value={swatch.colorNumber} disabled></input>
                        </div>
                        <div className="form-style__col col-md-3 col-sm-6">
                          <label>Article Number</label>
                          <input value={swatch.articleNumber} disabled></input>
                        </div>
                        <div className="form-style__col col-md-3 col-sm-6">
                          <label>Quantity</label>
                          <input value={swatch.quantity} disabled></input>
                        </div>
                        <div className="form-style__col col-md-3 col-sm-6">
                          <label>Regular Price</label>
                          <input
                            value={swatch.regularPrice + " $"}
                            disabled
                          ></input>
                        </div>
                      </div>
                      <div className="form-style">
                        <div className="form-style__col col-md-4 col-sm-12">
                          <label>Total Price</label>
                          <input
                            value={swatch.totalPrice + " $"}
                            disabled
                          ></input>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}

              <h2 className="order-subtitle">Product Details</h2>
              {lproduct === 0 ? (
                <p className="no-data-order"> No Products on this order...</p>
              ) : (
                <ul className="order-list">
                  {dataOrder.orders[0].detailProducts.map((prod, i) => (
                    <li key={i}>
                      <div className="form-style">
                        <div className="form-style__col col-md-3 col-sm-6">
                          <label>Color Number</label>
                          <input value={prod.colorNumber} disabled></input>
                        </div>
                        <div className="form-style__col col-md-3 col-sm-6">
                          <label>Article Number</label>
                          <input value={prod.articleNumber} disabled></input>
                        </div>
                        <div className="form-style__col col-md-3 col-sm-6">
                          <label>Quantity</label>
                          <input value={prod.quantity} disabled></input>
                        </div>
                        <div className="form-style__col col-md-3 col-sm-6">
                          <label>Regular Price</label>
                          <input
                            value={prod.regularPrice + " $"}
                            disabled
                          ></input>
                        </div>
                      </div>
                      <div className="form-style">
                        <div className="form-style__col col-md-4 col-sm-12">
                          <label>Total Price</label>
                          <input
                            value={prod.totalPrice + " $"}
                            disabled
                          ></input>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </form>
            {dbutton ? (
              <div className="form-style form-style__center">
                <button
                  className="form-style__button"
                  onClick={() => handleSendFedex()}
                >
                  Send with Fedex
                </button>
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </Container>

      <Modal isOpen={modalTransaction} style={customStyles}>
        <div className="head-modal">
          <p>Shipment information</p>
          <button onClick={closeModalTransaction}>X</button>
        </div>
        <div className="content-card-radio">
          {dbutton ? (""):(
            <ShippingInformation information={fedexOrder} />
          )}
        </div>
        <div className="u-margin-top-1">
          <button
            className="u-margin-auto btn btn-danger u-size-150"
            onClick={closeModalTransaction}
          >
            OK
          </button>
        </div>
      </Modal>
    </>
  )
}

export default UserOrderPage
