import React, { useState } from "react"
import { toast } from "react-toastify"
import axios from "axios"
import { any } from "prop-types"
import { apiSheet } from "../services/appenv"
import {
  driveCACertificate,
  driveNYCerfificate,
} from "../services/externalUrls"

import logoTex from "../images/logoTex.svg"
import uploadIcon from "../images/icons/upload-file.icon.svg"
const ResaleCertificate = () => {
  // Regex for email
  const emailRegex = RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
  /**
   * State for registration inputs
   */
  const [register, setRegister] = useState({
    companyName: "",
    fullName: "",
    email: "",
    checkNy: false,
    checkCa: false,
    certificate: any,
    messageError: {
      companyName: "",
      fullName: "",
      email: "",
    },
  })
  const { messageError } = register

  const [checkNy, setcheckNy] = useState(false)
  const [checkCa, setcheckCa] = useState(false)
  const handleCheckNY = event => {
    let cny = event.target.checked
    if (cny == true) {
      setcheckNy(true)
      setcheckCa(false)
    } else {
      setcheckNy(false)
    }
  }
  const handleCheckCA = event => {
    let cca = event.target.checked
    if (cca == true) {
      setcheckCa(true)
      setcheckNy(false)
    } else {
      setcheckCa(false)
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    let errors = register.messageError
    switch (name) {
      case "companyName":
        errors.companyName = value === "" ? "This field is required" : ""
        break
      case "fullName":
        errors.fullName = value === "" ? "This field is required" : ""
        break
      case "email":
        errors.email =
          value === ""
            ? "This field is required"
            : "" || emailRegex.test(value)
            ? ""
            : "This is not a valid email"
        break
      default:
        break
    }
    setRegister(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const [certificate, setCertificate] = useState()
  const handleFileChange = e => {
    
    var fileToLoad = e.target.files[0]
    // FileReader function for read the file.
    var fileReader = new FileReader()
    // Convert data to base64
    fileReader.readAsDataURL(fileToLoad)
    var base64
    // Onload of file read the file content
    fileReader.onload = function (fileLoadedEvent) {
      base64 = fileLoadedEvent.target.result
      setCertificate(base64)
    }
  }

  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data[0]).forEach(val => {
      if (val === "") {
        valid = false
      }
    })
    return valid
  }

  const HEADERS = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  }

  const handleSubmit = e => {
    e.preventDefault()

    register.certificate = certificate
    register.checkNy = checkNy
    register.checkCa = checkCa
    if (formValidate(register.messageError, register)) {
      if (checkNy == false && checkCa == false) {
        toast.error("Please, you have to select one of the checkboxs options.")
      } else {
        if (certificate) {
          
          axios
            .post(`${apiSheet}/resaleForm/test-drive.php`, register, HEADERS)
            .then(response => {
          
            })
            .catch(error => {})
        } else {
          toast.error("Please upload your certificate")
        }
      }
    } else {
      toast.error("Please complete the form correctly")
    }
  }

  return (
    <>
      <div className="container resale-flex-content">
        <div className="resale-page">
          <div className="resale-page__text">
            <img src={logoTex} alt="logoTex" />
            <h1>Resale Certificate Form </h1>
            <div className="resale-page__left">
              <p>
                Please kindly submit the Resale Certificate so that Takihyo does
                not need to charge you sales tax on shipments to the State of
                New York and California.
              </p>
              <p>
                <b>Donwload from here</b>
              </p>
              <p>
                NY:{" "}
                <a href={driveNYCerfificate} target="_blank" rel="noopener">
                  NY-Resale Certificate.pdf
                </a>
              </p>
              <p>
                CA:{" "}
                <a href={driveCACertificate} target="_blank" rel="noopener">
                  CA-Resale Certificate.pdf
                </a>
              </p>
            </div>
          </div>
          <form
            noValidate
            className="resale-page__form"
            onSubmit={e => handleSubmit(e)}
          >
            <div className="checkbox-style">
              <p>
                <b>Select state for your shipping address: </b>
              </p>
              <div className="checkbox-style__boxes margin-bottom30">
                <input
                  type="checkbox"
                  name="checkTrue"
                  label="CA"
                  onChange={event => handleCheckNY(event)}
                  checked={checkNy}
                />
                <label>NY</label>
                <input
                  type="checkbox"
                  name="checkFalse"
                  onChange={event => handleCheckCA(event)}
                  checked={checkCa}
                />
                <label>CA</label>
              </div>
            </div>
            <div className="input-style">
              <input
                type="text"
                name="companyName"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.companyName ? "border-error" : ""}
                placeholder="Company Name*"
              />
              {messageError.companyName && (
                <small className="field-error-resale">
                  {messageError.companyName}
                </small>
              )}
            </div>
            <div className="input-style">
              <input
                type="text"
                name="fullName"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.fullName ? "border-error" : ""}
                placeholder="Full Name*"
              />
              {messageError.fullName && (
                <small className="field-error-resale">
                  {messageError.fullName}
                </small>
              )}
            </div>
            <div className="input-style">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.email ? "border-error" : ""}
                placeholder="Email Address *"
              />
              {messageError.email && (
                <small className="field-error-resale">
                  {messageError.email}
                </small>
              )}
            </div>
            <div className="file-style mt-3">
              <p>
                <b>Please attach your certificate:</b>
              </p>
              <label htmlFor="file-upload" className="custom-file-upload">
                <img src={uploadIcon}></img> File Upload
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={e => handleFileChange(e)}
              />
            </div>
            <button
              type="submit"
              className="u-text-uppercase btn btn-primary btn-submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ResaleCertificate
