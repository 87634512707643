import styled from "@emotion/styled"
export const ContainerFilter = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  font-weight: 300;
  @media screen and (max-width: 769px) {
    margin-top: 0.5em;
  }
  span {
    font-size: 15px;
    font-weight: bold;
  }
`
export const Text = styled.div`
  font-size: 1rem;
  color: var(--color-dark);
  .text-count-products {
    padding: 0;
  }
`
export const ContentSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  @media screen and (max-width: 769px){
    align-items: center;
    justify-content: space-between;
  }
`
export const Select = styled.select`
  color: var(--color-primary);
  padding-left: 10px;
  padding-right: 25px;
  /* needed */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0.9rem;
  /* SVG background image */
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%2205515'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 10px;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-color: var(--color-contrast-light);
  height: 40px;
  margin: 0 0.5em;
  border-radius: 3px;
  font-weight: 300;
  border: 1px solid var(--color-dark-shade);
  margin-right: 0;
`
export const Container = styled.div`
  h5 {
    font-size: 1.2rem;
    color: var(--color-primary);
  }
`
