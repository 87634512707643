import styled from "@emotion/styled";

export const TitlePage = styled.h1({
  marginTop: "1em",
  marginLeft: "1em",
  fontSize: "44px",
  marginBottom: "0.7em",
  "@media screen and (max-width: 992px)": {
    fontSize: "24px",
    marginLeft: "10px",
  },
});

export const TitleCategory = styled.p({
  fontSize: "21px",
  marginTop: "2.5em",
  paddingLeft: "15px",
  position: "relative",
  marginBottom: "20px",
  textTransform: "capitalize",
  fontWeight: "600",
  "&:first-child": {
    marginTop: "0",
  },
  "&:before": {
    left: "0",
    width: "5px",
    content: '""',
    height: "100%",
    background: "black",
    position: "absolute",
  },
  "@media screen and (max-width: 992px)": {
    fontSize: "16px",
    fontWeight: "400",
  },
});

export const ItemMap = styled.div({
  marginTop: "15px",
  marginBottom: "15px",
  ".ReactCollapse--collapse .ReactCollapse--content": {
    "@media screen and (max-width: 992px)": {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
});

export const TitleItem = styled.p({
  display: "flex",
  fontSize: "20px",
  marginBottom: "10px",
  alignItems: "center",
  marginTop: "10px",
  b: {
    fontWeight: "500",
    "@media screen and (max-width: 992px)": {
      fontWeight: "700",
    },
  },
  small: {
    cursor: "pointer",
    marginLeft: "2em",
    marginRight: "1.3em",
    fontSize: "15px",
    fontWeight: "500",
    svg: {
      fontSize: "10px",
    },
    "@media screen and (max-width: 992px)": {
      fontSize: "12px",
    },
  },
  span: {
    cursor: "pointer",
    color: "white",
    fontSize: "15px",
    padding: "0 8px",
    marginLeft: "20px",
    background: "#FF0000",
    "@media screen and (max-width: 992px)": {
      fontSize: "12px",
      marginLeft: "0",
    },
  },
  "@media screen and (max-width: 992px)": {
    fontSize: "14px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  ".detail-buttons": {
    "@media screen and (max-width: 992px)": {
      fontSize: "14px",
      marginTop: "8px",
      display: "flex",
      flexDirection: "row-reverse",
    },
  },
});

export const DescriptionItem = styled.p({
  fontSize: "14px",
  lineHeight: "25px",
  letterSpacing: "0.5px",
  a: {
    color: "#5591ef",
  },
});

export const ImgContentItem = styled.div({
  display: "flex",
  flexWrap: "wrap",
  "@media screen and (max-width: 992px)": {
    flexDirection: "column",
  },
});

export const ImgItem = styled.div({
  width: "50%",
  margin: "20px 0",
  paddingLeft: "30px",
  "&:first-child": {
    paddingLeft: "0",
    paddingRight: "30px",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  "@media screen and (max-width: 992px)": {
    width: "100%",
    paddingRight: "0",
    paddingLeft: "0",
    margin: "8px 0",
    "&:first-child": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
});

export const TtitleIndex = styled.p({
  fontSize: "16px",
});
export const IndexList = styled.ul({});

export const MapContent = styled.div({
  // top: "75px",
  width: "45%",
  height: "calc(100vh - 197px)",
  position: "relative",
  top: "-140px",
  "@media screen and (max-width: 992px)": {
    right: "0",
    width: "50%",
    paddingLeft: "0.5em",
    top: "10px",
    position: "absolute",
    height: "calc(100vh - 200px)",
  },
});

export const MainContent = styled.div({
  width: "55%",
  paddingRight: "2em",
  "@media screen and (max-width: 992px)": {
    width: "50%",
    paddingRight: "0.5em",
  },
});

export const FluidContent = styled.div({
  ".stycky-content": {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: "0 2em",
    "@media screen and (max-width: 992px)": {
      padding: "0",
    },
  },

  "@media screen and (max-width: 992px)": {
    padding: "0",
    paddingLeft: "5px!important",
    marginBottom: "8em",
  },
});
