import React from "react"
import { Link } from "gatsby"
const NotFoundPage = () => (
  <section className="container container-error404">
    <h1 className="title-404">404: Not Found</h1>
    <p>Sorry! The page you're looking for does not exist.</p>
    <Link
      to="/"
      className="u-margin-tb-2 u-margin-bottom-2 btn btn-primary btn-largesss"
    >
      Go back home
    </Link>
  </section>
)

export default NotFoundPage
