import React, { useEffect } from "react"
const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="container container-terms-conditions">
        <h1>Terms of Use</h1>
        <div className="row">
          <div className="col-12">
            <h2>Effective as of 10.13.2022</h2>
            <h2>Legal Agreement; Revisions</h2>
            <p>
              This website, located at the URL www.textilelibrary-takihyo.com (the
              “Site”), is operated by TEXTILE LIBRARY, on behalf of Takiyho Co.
              Ltd (collectively “TEXTILE LIBRARY”, “we,” “us” or “our”).
            </p>
            <p>
              Please take a moment to review the following basic terms that
              govern your use of and purchase of products from the Site. By
              using our Site, you consent to follow and be bound by our Terms of
              Use (the "Agreement" or “Terms”), which are effective as of the
              date set forth above.
            </p>
            <p>
              These Terms are a legal agreement between you and us, and they
              contain important information on your legal rights, remedies and
              obligations. By accessing, browsing or otherwise using the Site,
              you: (i) acknowledge that you have read, understand and agree to
              be bound by these Terms; and (ii) represent that you are an adult
              and have the legal capacity to enter a contract in the
              jurisdiction where you reside. You may not access, browse or use
              the Site and you should discontinue these activities immediately,
              if you: (a) do not agree to these Terms; or (b) are prohibited
              from accessing or using the Site by applicable law.
            </p>
            <p>
              We may on occasion change, move or delete portions of, or may add
              to, these Terms, simply by posting such changes or revisions on
              this page. Your continued use of the Site after a posted change or
              revision in these Terms signifies your acceptance of any such
              change or revision. For this reason, please visit this page on a
              regular basis to ensure that you are familiar with the most recent
              version of these Terms.
            </p>
            <p>
              In case of a violation of these Terms, we reserve the right to
              seek all remedies available for such violation, whether by law, in
              equity or otherwise. Your failure to follow these Terms may result
              in suspension or termination of your access to the Site, without
              notice, in addition to other available remedies.
            </p>
            <p>
              You can address your questions or concerns regarding these Terms
              to info@textilelibrary-takihyo.com.
            </p>
            <h2>Privacy Policy</h2>
            <p>
              We respect your privacy and share your concern about its
              protection. Our <a href="/privacy-policy" style={{textDecoration:"underline"}}>Privacy Policy</a> constitutes a part of these Terms
              and explains how we may collect, use and protect information that
              we learn from or about you as a result of your use of the Site.
            </p>
            <h2>Site Content and Ownership</h2>
            <p>
              TEXTILE LIBRARY and/or its affiliates, service providers,
              distributors, vendors, contractors, sponsors, advertisers,
              licensors and/or licensees (collectively, “TEXTILE LIBRARY
              Parties”) are the exclusive owners or licensees of all content and
              materials on the Site (“Site Content”) and of all related
              intellectual property rights.
            </p>
            <p>
              The Site as a whole, as well as the Site Content, are intended
              solely for personal, noncommercial use (except for the purchase of
              merchandise from our site) by the users of our Site.
            </p>
            <h2>Prohibited Conduct</h2>
            <p>You agree that you will not:</p>
            <ul>
              <li>
                Download, archive, store, copy, reproduce, compile, recompile,
                use, modify, adapt, translate or prepare any summaries,
                abstracts, derivative works or materials of the Site or any Site
                Content, in whole or in part, access, use, license, sell,
                transfer, disclose, publish, transmit, display, distribute,
                provide or permit access to, in any way for any public or
                commercial purpose, the Site or any Site Content;
              </li>
              <li>Collect, compile or recompile any Site Content;</li>
              <li>
                Post, create, or make available through the website any content
                to which you do not own the rights;
              </li>
              <li>
                Impersonate any person or entity or misrepresent your
                affiliation with any person or entity, whether actual or
                fictitious, including anyone associated with the Site, the
                TEXTILE LIBRARY Parties or otherwise affiliated with us;
              </li>
              <li>Use an inappropriate username or screen name;</li>
              <li>
                Insert your own or a third party’s advertising, branding or
                other promotional content into any Site Content;
              </li>
              <li>
                Obtain or attempt to gain unauthorized access to other computer
                systems, materials, information or any services available on or
                through the Site through any means;
              </li>
              <li>
                Engage in any automatic or unauthorized means of accessing,
                logging-in or registering on the Site, or obtaining lists of
                users or other information from or through the Site;
              </li>
              <li>
                Use the Site in any manner that could interrupt, damage,
                disable, overburden or impair the Site or interfere with any
                other party’s use and enjoyment of the Site, including sending
                mass unsolicited messages or “flooding” servers;
              </li>
              <li>
                Use the Site in violation of our intellectual property or other
                proprietary or legal rights, or the rights of any third party;
              </li>
              <li>
                Use the Site in violation of any applicable law or for any
                unlawful purpose
              </li>
              <li>
                Attempt (or encourage or support anyone else’s attempt) to
                circumvent, reverse-engineer, decrypt, or otherwise alter, or
                interfere with, the Site.
              </li>
            </ul>
            <h2>Your Account and Your User Information</h2>
            <p>
              If you create an account (“Your Account”) or otherwise provide
              user or login information (“Your User Information”) when accessing
              the Site, you must provide us with complete and accurate
              information. You grant to us, the TEXTILE LIBRARY Parties, and all
              other persons and entities involved in the operation of the Site
              the right to use, store, monitor, retrieve and transmit Your User
              Information in connection with the operation of the Site. Our
              information collection and use policies with respect to the
              privacy of Your User Information are set forth in our Privacy
              Policy, which is incorporated in these Terms by reference for all
              purposes.
            </p>
            <p>
              We reserve the right to terminate Your Account or to refuse
              services to you, without prior notice to you, at any time and for
              any or no reason. You have the right to cancel Your Account at any
              time.
            </p>
            <h2>Product Purchases</h2>
            <p>
              All product purchases placed on or through the Site are subject to
              our acceptance, in our sole discretion. If your credit card has
              already been charged for a purchase that is subsequently cancelled
              and not received, a credit shall issue to your credit card
              account. We also reserve the right, in our sole discretion, to
              take steps to verify your identity to process your order.
            </p>
            <p>
              If you place an order through the Site, you represent and warrant
              that you are a bona fide end-user customer and will not deliver,
              sell or otherwise distribute our products or purchase our products
              for commercial purposes or any other commercial benefit. If we
              believe, in our sole discretion, that an order would violate the
              terms of the preceding sentence or that a user is engaging in
              fraudulent, objectionable or grey market activities, we may refuse
              such order.
            </p>
            <p>
              No warranties are made by TEXTILE LIBRARY with respect to any of
              the products, merchandise and/or services provided, featured,
              endorsed, sold or distributed on or through the Site. You agree
              that TEXTILE LIBRARY is not liable or responsible to you with
              respect to any products, merchandise, advice, activities, and/or
              services provided, featured, endorsed, sold or distributed on or
              through the Site, including illegal, offensive or illicit
              materials or items, even if such materials or items violate these
              Terms. If you are a California resident, you waive California
              Civil Code §1542, which says: "A general release does not extend
              to claims which the creditor does not know or suspect to exist in
              his favor at the time of executing the release, which if known by
              him must have materially affected his settlement with the debtor."
            </p>
            <p>
              We strive to provide accurate pricing information regarding the
              products and services available through the Site. We cannot,
              however, insure against pricing errors. We reserve the right, at
              our sole discretion and at any time, to not process or to cancel
              any orders placed for a product or service whose price was
              incorrectly posted on the Site as a result of an error and/or
              limit from time to time the quantity of products that may be
              ordered by a user in a single buying session. If this occurs, we
              will notify you by email. In addition, we reserve the right, at
              our sole discretion, to correct any error in the stated price or
              revise the pricing for products or services offered through the
              Site at any time. Our return policy is set forth in the TEXTILE
              LIBRARY Return Policy(the “Return Policy”), which is incorporated
              in these Terms by reference for all purposes.
            </p>
            <p>
              EXCEPT AS OTHERWISE SET FORTH IN THE RETURN POLICY, YOU
              ACKNOWLEDGE AND AGREE THAT ANY CHARGES FOR PRODUCTS OR SERVICES
              ORDERED THROUGH THE SITE ARE NOT REFUNDABLE IN WHOLE OR IN PART. 
              YOU ARE FULLY LIABLE FOR ALL CHARGES TO YOUR ACCOUNT, INCLUDING
              ANY UNAUTHORIZED CHARGES.
            </p>
            <h2>Third Party Content</h2>
            <p>
              The Site may contain links to other websites, or content including
              graphics and photographs, that are owned or operated by third
              parties and may be of interest to our users (collectively, “Third
              Party Content”). We do not investigate or monitor Third Party
              Content, and therefore are not responsible or liable for the same.
              You are advised that different terms of use and privacy policies
              may apply to your use and access of Third Party Content. When you
              use a link to go from our Site to a third party website, these
              Terms and our <a href="/privacy-policy" style={{textDecoration:"underline"}}>Privacy Policy</a> are no longer in effect. We encourage
              users to read and consider the policies of these other websites
              before using them.
            </p>
            <h2>Warranty Disclaimers</h2>
            <p>
              We administer, control and operate the Site from our offices in
              the State of New York, the United States of America. The Site is
              accessible worldwide; however, some of its features or functions
              may not be available or appropriate for use outside of the United
              States and/or may not be available to all persons or in all
              geographic locations. We make no representation that the Site and
              the Site Content are appropriate or authorized for use in all
              countries, states, provinces, counties or any other jurisdictions. 
              Your access and use of the Site may not be legal in your
              jurisdiction. If you choose to access, browse or use the Site, you
              do so on your own initiative and risk, and you are responsible for
              compliance with all applicable laws if, and to the full extent
              that, such laws and restrictions are applicable. We reserve the
              right to limit, in our sole discretion, the provision of any
              feature or function of the Site to any person and geographic area. 
              Any offer for any service, feature or function made on the Site is
              void where prohibited.
            </p>
            <p>
              The Site provides information of a general nature only and you are
              responsible for determining whether it applies to your specific
              situation. We specifically disclaim any liability concerning any
              action that any person may take based on any information or
              guidance provided on or through the Site. We attempt to provide
              accurate descriptions and representations of products and services
              on the Site. We do not warrant, however, that the descriptions and
              representations are accurate, complete, reliable, current or
              error-free. If a product or service offered on the Site is not as
              described or shown, your sole remedy is to return the item if
              permitted under the Terms, Return Policy and any other TEXTILE
              LIBRARY policy.
            </p>
            <p>
              THE SITE AND THE SITE CONTENT (INCLUDING ALL SERVICES) ARE
              PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS AND WITHOUT
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST
              EXTENT PERMITTED BY LAW, EACH OF THE TEXTILE LIBRARY PARTIES
              EXPRESSLY DISCLAIM ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND,
              WHETHER EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF CONDITION,
              ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY,
              COMPLETENESS, CURRENCY, RELIABILITY OR USEFULNESS OF THE SITE
              CONTENT AND ANY WARRANTIES OF MERCHANTABILITY, SATISFACTORY
              QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR
              NON-INFRINGEMENT, OR THAT ARISES FROM ANY COURSE OF DEALINGOR
              TRADE PRACTICE.
            </p>
            <p>
              YOU USE AND ACCESS THE SITE AND THE SITE CONTENT AT YOUR OWN RISK
              AND DISCRETION. NO ADVICE, RESULTS, STATEMENT OR INFORMATION,
              WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM OR ON BEHALF OF THE
              TEXTILE LIBRARY PARTIES OR THROUGH THE SITE SHALL CREATE ANY
              REPRESENTATION OR WARRANTY NOT EXPRESSLY MADE HEREIN.
            </p>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF CERTAIN
              WARRANTIES, SO THE FOREGOING DISCLAIMERS MAY NOT APPLY TO YOU.
            </p>
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT YOUR SOLE REMEDY FOR ANY PROBLEMS
              OR DISSATISFACTION WITH THE SITE IS TO TERMINATE YOUR ACCOUNT AND
              DISCONTINUE ALL USE OF THE SITE.
            </p>
            <h2>Limitation of Liability of the TEXTILE LIBRARY Parties</h2>
            <p>
              EXCEPT AS SET OUT BELOW, UNDER NO CIRCUMSTANCES, SHALL THE TEXTILE
              LIBRARY PARTIES BE LIABLE FOR ANY DAMAGES, LOSS OR INJURY OF ANY
              KIND THAT ARISES OUT OF OR IN CONNECTION WITH YOUR USE OR
              INABILITY TO USE THE SITE OR THE SITE CONTENT; OR ANY INFORMATION,
              ADVICE, SOFTWARE, GOODS OR SERVICES OBTAINED THROUGH THE SITE, OR
              ANY DIRECT, INDIRECT, GENERAL, SPECIAL, CONSEQUENTIAL, INCIDENTAL,
              PUNITIVE, EXEMPLARY, ENHANCED, TREBLED OR MULTIPLE DAMAGES
              WHATSOEVER, REGARDLESS OF THE THEORY OF LIABILITY, AND WHETHER OR
              NOT SUCH LOSSES OR DAMAGES ARE FORESEEABLE OR THE TEXTILE LIBRARY
              PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR
              DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ANY REMEDY OF ITS
              ESSENTIAL PURPOSE.
            </p>
            <p>
              BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF LIABILITY OF CERTAIN DAMAGES, THOSE ASPECTS OF THE
              ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES OR
              JURISDICTIONS, THE TEXTILE LIBRARY PARTIES’ LIABILITY SHALL BE
              LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
            </p>
            <p>
              IN NO EVENT SHALL THE TEXTILE LIBRARY PARTIES BE LIABLE FOR, OR IN
              CONNECTION WITH, ANY CONTENT POSTED, TRANSMITTED, EXCHANGED OR
              RECEIVED BY OR ON BEHALF OF ANY USER OR OTHER PERSON ON OR THROUGH
              THE SITE (INCLUDING ANY OF YOUR CONTENT).
            </p>
            <p>
              IN NO EVENT SHALL THE TEXTILE LIBRARY PARTIES’ TOTAL LIABILITY TO
              YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED, IN THE
              AGGREGATE (A) THE AMOUNT, IF ANY, PAID BY YOU FOR YOUR USE OF THE
              SITE OR FOR ANY OF YOUR ACTIVITIES ON THE SITE DURING THE THREE
              MONTHS IMMEDIATELY PRECEDING YOUR CLAIM, OR (B) ONE HUNDRED U.S.
              DOLLARS (US$100), WHICHEVER IS GREATER .
            </p>
            <h2>Modification, Suspension and Termination</h2>
            <p>
              We reserve the right to modify, suspend or terminate, in our sole
              discretion, at any time, temporarily or permanently, for any or no
              reason and without notice to you, and without any liability to
              you, any portion of the Site.
            </p>
            <p>
              We may change products or services mentioned on the Site at any
              time with or without notice.
            </p>
            <h2>Assignment</h2>
            <p>
              You agree that we may assign any of our rights, and/or transfer,
              sub-contract or delegate any of our obligations, under these
              Terms. Your agreement to these Terms is personal to you and you
              may not transfer or assign it to any third party.
            </p>
            <h2>Governing Law and Jurisdiction</h2>
            <p>
              You agree that the laws of the United States of America and the
              laws of the State of New York, without regard to the principles of
              conflicts of laws, will govern your use of the Site, these Terms
              and all matters relating to your access to, and/or use of, the
              Site, including all disputes between you and TEXTILE LIBRARY. You
              irrevocably submit to the exclusive jurisdiction of, and venue in,
              the state and federal courts seated in New York County, New York,
              and the related appellate courts, in any related action, dispute
              or proceeding. Notwithstanding the above sentence, TEXTILE LIBRARY
              shall have the right to apply for an injunction or other emergency
              relief in any jurisdiction.
            </p>
            <h2>Entire Agreement</h2>
            <p>
              These Terms shall be deemed to include all other notices,
              policies, disclaimers and other terms and conditions contained in
              the site, including our <a href="/privacy-policy" style={{textDecoration:"underline"}}>Privacy Policy</a>, Return Policy, or
              specified in the Site Content, such as terms of sale, or other
              terms relating to transactions or activities with respect to
              products or services (“Additional Terms”); provided, however, that
              these Terms shall prevail in the event of a conflict with any such
              Additional Terms.
            </p>
            <p>
              These Terms including our <a href="/privacy-policy" style={{textDecoration:"underline"}}>Privacy Policy</a>, Return Policy, and any
              Additional Terms constitute the entire agreement between you and
              us and supersede all prior or contemporaneous communications and
              proposals, whether electronic, oral or written, between you and
              us, with respect to your use of the Site and all matters relating
              to your access to, and/or use of, the Site.
            </p>
            <p>
              If any part of these Terms is determined to be invalid or
              unenforceable under applicable law including the warranty
              disclaimers and liability limitations stated above, then the
              invalid or unenforceable provision(s) will be deemed superseded by
              a valid, enforceable provision that most closely matches the
              intent of the original provision, and the remainder of these Terms
              shall continue in full effect.
            </p>
            <p>
              Any rights not expressly granted in these Terms are reserved to
              TEXTILE LIBRARY.
            </p>
            <h2>Third Party Beneficiaries</h2>
            <p>
              Each of TEXTILE LIBRARY’s service providers and licensors shall be
              third party beneficiaries to these Terms and the Additional Terms,
              and shall be entitled to directly rely on and enforce any
              provisions of these Terms or Additional Terms which confer a
              benefit on or rights in favor of TEXTILE LIBRARY. No other person
              or entity shall be a third party beneficiary to these Terms or the
              Additional Terms.
            </p>
            <h2>Survival of Terms</h2>
            <p>
              All provisions of these Terms and of the Additional Terms that
              consist of or relate to notices, ownership of intellectual or
              other property, confidentiality obligations, representations,
              warranties, limitations of liability, disclaimers,
              [indemnification, ][dispute resolution,] governing law, venue or
              jurisdiction, or any prohibitions or restrictions respecting any
              access to, use of, or other activities concerning the Site or any
              Site Content, shall survive the revocation, expiration or
              termination, in whole or in part, of these Terms or the Additional
              Terms.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsConditions
