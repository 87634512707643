import React, { FC, useState } from "react"
import { IoIosArrowDown } from "react-icons/io"
import _ from "underscore"
import { FilterStyle, TitleStyle } from "./styled-widget"
import Collapsible from "react-collapsible"

interface WeightData {
  dataCatMaterial: any
  selected: any
  handleFilter: any
}

const MaterialWidget: FC<WeightData> = ({
  dataCatMaterial,
  handleFilter,
  selected
}) => {
  const [collapse, setCollapse] = useState<boolean>(false)
  
  return (
    <FilterStyle>
      <Collapsible
        trigger={
          <TitleStyle onClick={() => setCollapse(!collapse)}>
            Material
            <IoIosArrowDown
              style={{
                transform: `${collapse ? "rotate(180deg)" : "rotate(0deg)"} `,
              }}
            />
          </TitleStyle>
        }
        transitionTime={200}
      >
        {dataCatMaterial
          .slice(0, collapse ? dataCatMaterial.length : 0)
          .map(cat => {
            const isSelected = selected.includes(cat)
            return (
              <ul>
                <li onClick={() => handleFilter(cat)} style={{
                  fontWeight: `${isSelected ? "bold" : ""}`,
                }}>{cat}</li>
              </ul>
            )
          }

          )}
      </Collapsible>
    </FilterStyle>
  )
}

export default MaterialWidget
