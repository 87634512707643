import React from "react"
import { Link } from "gatsby"
const WrapperInfo = ({ dataItems }) => {
  return (
    <div className="content-blog__item-right--card-right">
      {dataItems.map((item, i) => (
        <Link
          key={i}
          to={`/info/${item && item.slug}`}
          className="content-blog__item-right--card-card"
        >
          <img src={item.image[0].url} alt="" />
          <div className="content-blog__item-right--items-text">
            <h5>{item.title}</h5>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default WrapperInfo
