import React, { useState } from "react";
import Modal from "react-modal";
import { useMutation, useQuery } from "@apollo/client";
import { CURRENT_USER } from "../querys";
import { CREATE_ADDRESS, EDIT_ADDRESS, DELETE_ADDRESS } from "../mutations";
import {
  Select,
  RowFields,
  InputField,
} from "../components/tabs/components/fields";
import { toast } from "react-toastify";

import useAddress from "../hooks/useAddress";
import { customStyles } from "../services/modal";

const ModalAddress = ({
  positionRow,
  typeModal,
  address,
  setAddress,
  openModal,
  setModalAddress,
  onCancel,
  mainAddress,
}) => {
  const queryAddress = useAddress();
  const list = queryAddress.allGoogleSheetShipping.nodes;
  Modal.setAppElement("#___gatsby");
  /**
   * Body Forms New Edit
   */

  const { data: dataUser } = useQuery(CURRENT_USER);
  const [messageError, setMessageError] = useState({
    lastname: "",
    firstname: "",
    address: "",
    zipcode: "",
    cityName: "",
    aptNumber: "",
    street: "",
    phone: "",
  });

  const formValidate = (messageError, ...data) => {
    let valid = true;
    Object.values(messageError).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    Object.values(data).forEach((val) => {
      val === "" && (valid = false);
    });
    return valid;
  };

  /**
   * Method that captures the value of each input
   * @param {*Parameter for input} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = messageError;
    switch (name) {
      case "lastname":
        errors.lastname = value === "" ? "This field is required" : "";
        break;
      case "firstname":
        errors.firstname = value === "" ? "This field is required" : "";
        break;
      case "companyName":
        errors.companyName = value === "" ? "This field is required" : "";
        break;
      case "address":
        errors.address = value === "DEFAULT" ? "This field is required" : "";
        break;
      case "zipcode":
        errors.zipcode = value === "" ? "This field is required" : "";
        break;
      case "cityName":
        errors.cityName = value === "" ? "This field is required" : "";
        break;
      case "street":
        errors.street = value === "" ? "This field is required" : "";
        break;
      case "phone":
        errors.phone = value === "" ? "This field is required" : "";
        break;
      default:
        break;
    }
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [addAddress, { loading: mutationLoading }] = useMutation(
    CREATE_ADDRESS,
    {
      onCompleted: () => {
        setModalAddress(false);
        toast.success("Your registration was completed successfully");
      },
      onError: () => {
        toast.error("Please complete the form");
      },
      refetchQueries: ["getAddress", "getMainAddress", "getSecontaryAddress"],
    }
  );

  const [editAddress] = useMutation(EDIT_ADDRESS, {
    onCompleted: () => {
      toast.success("Your registration was completed successfully");
    },
    onError: () => {
      toast.error("Please complete the form");
    },
    refetchQueries: ["getAddress", "getMainAddress", "getSecontaryAddress"],
  });

  const [deleteAddress] = useMutation(DELETE_ADDRESS, {
    onCompleted: () => {
      setModalAddress(false);
      toast.success("successfully removed");
    },
    onError: () => {
      toast.error("An error occurred while removing the address");
    },
  });
  const handleDeleteAddress = () => {
    deleteAddress({
      variables: {
        id: positionRow,
      },
      refetchQueries: ["getAddress", "getMainAddress", "getSecontaryAddress"],
    });
  };

  const handleSubmitNew = (e) => {
    e.preventDefault();
    if (
      address.lastname === "" ||
      address.firstname === "" ||
      address.address === "" ||
      address.zipcode === "" ||
      address.cityName === "" ||
      address.street === "" ||
      address.phone === ""
    ) {
      toast.error("Please complete the form");
    } else {
      if (formValidate(messageError, address)) {
        handleCreateNewAddress();
      } else {
        toast.error("Please complete the required fields");
      }
    }
  };
  const handlSubmitEdit = (e) => {
    e.preventDefault();
    if (
      address.lastname === "" ||
      address.firstname === "" ||
      address.companyName === "" ||
      address.address === "" ||
      address.zipcode === "" ||
      address.cityName === "" ||
      address.street === "" ||
      address.phone === ""
    ) {
      toast.error("Please complete the required fields");
    } else {
      if (formValidate(messageError, address)) {
        setModalAddress(false);
        handleEditAddress();
      } else {
        toast.error("Please complete the form");
      }
    }
  };
  const handleCreateNewAddress = () => {
    addAddress({
      variables: {
        data: {
          address: address.address,
          lastname: address.lastname,
          firstname: address.firstname,
          cityName: address.cityName,
          zipcode: address.zipcode,
          aptNumber: address.aptNumber,
          phone: address.phone,
          companyName: address.companyName,
          street: address.street,
          main: mainAddress === 0 ? true : false,
          users_permissions_user: dataUser.me.id,
        },
      },
    });
  };

  const handleEditAddress = () => {
    editAddress({
      variables: {
        id: positionRow,
        data: {
          address: address.address,
          lastname: address.lastname,
          firstname: address.firstname,
          cityName: address.cityName,
          zipcode: address.zipcode,
          aptNumber: address.aptNumber,
          phone: address.phone,
          main: false,
          companyName: address.companyName,
          street: address.street,
        },
      },
    });
  };

  return (
    <Modal isOpen={openModal} style={customStyles}>
      <form className="container address-modal" noValidate>
        <RowFields className="row u-width-100">
          <div className="col-lg-2 col-md-3">
            <label>Full Name</label>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6	u-margin-top-1-5-rp-575">
            <InputField>
              <input
                type="text"
                placeholder="First Name (required)"
                name="firstname"
                onChange={handleChange}
                className={messageError.firstname ? "border-error" : ""}
                value={address && address.firstname}
              />
              {messageError.firstname && (
                <small className="field-error field-error--address-form">
                  {messageError.firstname}
                </small>
              )}
            </InputField>
          </div>
          <div className="col-lg-5 col-md-4 col-sm-6">
            <InputField>
              <input
                type="text"
                placeholder="Last Name (required)"
                name="lastname"
                onChange={handleChange}
                className={messageError.lastname ? "border-error" : ""}
                value={address && address.lastname}
              />
              {messageError.lastname && (
                <small className="field-error field-error--address-form">
                  {messageError.lastname}
                </small>
              )}
            </InputField>
          </div>
        </RowFields>
        <RowFields className="row u-width-100">
          <div className="col-lg-2 col-md-3">
            <label>Company name</label>
          </div>
          <div className="col-lg-10 col-md-9">
            <InputField>
              <input
                type="text"
                placeholder="Company Name (optional)"
                name="companyName"
                onChange={handleChange}
                value={address && address.companyName}
              />
            </InputField>
          </div>
        </RowFields>
        <RowFields className="row u-width-100">
          <div className="col-lg-2 col-md-3 ">
            <label>Address</label>
          </div>
          <div className="col-lg-10 col-md-9 col-sm-12 u-margin-top-1-5">
            <InputField>
              <input
                type="text"
                placeholder="Street · building name (eg 1-3-5) (required)"
                name="street"
                onChange={handleChange}
                className={`${messageError.street ? "border-error" : ""}`}
                value={address && address.street}
              />
              {messageError.street && (
                <small className="field-error field-error--address-form address-error">
                  {messageError.street}
                </small>
              )}
            </InputField>
          </div>
          <div className="col-lg-2 col-md-3"></div>
          <div className="col-lg-5 col-md-4 col-sm-6 u-margin-top-1-5">
            <InputField>
              <input
                type="text"
                placeholder="Apt/Unit Number"
                name="aptNumber"
                onChange={handleChange}
                value={address && address.aptNumber}
              />
            </InputField>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6 u-margin-top-1-5">
            <InputField>
              <input
                type="text"
                placeholder="City name (example: Kanda Jimbocho, Chiyoda ku) (required)"
                name="cityName"
                onChange={handleChange}
                className={messageError.cityName ? "border-error" : ""}
                value={address && address.cityName}
              />
              {messageError.cityName && (
                <small className="field-error field-error--address-form">
                  {messageError.cityName}
                </small>
              )}
            </InputField>
          </div>
          <div className="col-lg-2 col-md-3"></div>
          <div className="col-lg-9 col-md-9 col-sm-9 u-margin-top-1-5-rp-575">
            <p className="not-pobox">*NOT available to use PO Box.</p>
          </div>
          <div className="col-lg-2 col-md-3"></div>
          <div className="col-lg-6 col-md-6 col-sm-9 u-margin-top-1-5-rp-575">
            <InputField>
              <Select
                defaultValue={address && address.address}
                name="address"
                onChange={handleChange}
                className={`${messageError.address ? "border-error" : ""} `}
              >
                <option disabled hidden value={address && address.address}>
                  {address && address.address}
                </option>
                {list.map((option, i) => (
                  <option key={i} value={option.id}>
                    {option.area}
                  </option>
                ))}
              </Select>
              {messageError.address && (
                <small className="field-error field-error--address-form">
                  {messageError.address}
                </small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-3">
            <InputField>
              <input
                type="number"
                placeholder="Zip code (required)"
                name="zipcode"
                onChange={handleChange}
                className={messageError.zipcode ? "border-error" : ""}
                value={address && address.zipcode}
              />
              {messageError.zipcode && (
                <small className="field-error field-error--address-form">
                  {messageError.zipcode}
                </small>
              )}
            </InputField>
          </div>
        </RowFields>
        <RowFields className="row u-width-100">
          <div className="col-lg-2 col-md-3 col-sm-2 u-margin-top-1-5-rp">
            <label>Phone</label>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-10 u-margin-top-1-5-rp u-margin-top-1-5-rp-575">
            <InputField>
              <input
                type="tel"
                placeholder="Phone Number (required)"
                name="phone"
                onChange={handleChange}
                className={messageError.phone ? "border-error" : ""}
                value={address && address.phone}
              />
              {messageError.phone && (
                <small className="field-error field-error--address-form">
                  {messageError.phone}
                </small>
              )}
            </InputField>
          </div>
        </RowFields>
        <div className="u-text-center buttons-modal">
          <input
            type="button"
            onClick={() => {
              onCancel();
              setMessageError({});
            }}
            className="btn btn-primary u-size-150"
            defaultValue="Cancel"
          />

          {typeModal === "add" ? (
            <>
              <button
                type="submit"
                className="btn btn-danger u-size-150"
                onClick={handleSubmitNew}
              >
                {mutationLoading ? <div className="loading"></div> : "Add"}
              </button>
            </>
          ) : (
            <>
              <button
                type="submit"
                className="btn btn-danger u-size-150"
                onClick={handlSubmitEdit}
              >
                {mutationLoading ? (
                  <div className="loading"></div>
                ) : (
                  "Save Changes"
                )}
              </button>
              <hr />
              <button
                className="btn btn-secondary u-size-150 u-margin-left"
                type="button"
                onClick={() => handleDeleteAddress()}
              >
                Delete
              </button>
            </>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default ModalAddress;
