import React from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import CheckOutForm from "../components/CheckoutForm";

import { STRIPE_PRIVATE_KEY } from "../models/stripe-pk";

const MakePaymentPage = () => {
  const stripe = loadStripe(STRIPE_PRIVATE_KEY);
  return (
    <div className="container">
      <h2 className="head-text-cart title-credit-card">
        Credit card information input form
      </h2>
      <div className="card-payment">
        <Elements stripe={stripe}>
          <CheckOutForm />
        </Elements>
      </div>
    </div>
  );
};

export default MakePaymentPage;
