import React from "react"
import { Link } from "gatsby"

import { BiLinkExternal } from "react-icons/bi"

const ShippingInformation = ({ information }) => {
  
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <th className="u-text-align-left">Transaction Id:</th>
            <td>{information.fedexorders[0].transaction_id}</td>
          </tr>
          <tr>
            <th className="u-text-align-left">Category:</th>
            <td>{information.fedexorders[0].category}</td>
          </tr>
          <tr>
            <th className="u-text-align-left">Service Type:</th>
            <td>{information.fedexorders[0].service_type}</td>
          </tr>
          <tr>
            <th className="u-text-align-left">Ship Dates:</th>
            <td>{information.fedexorders[0].ship_date}</td>
          </tr>
          <tr>
            <th className="u-text-align-left">Information 1:</th>
            <td className="u-display-flex u-align-center">
              <Link
                className="btn-information"
                to={information.fedexorders[0].Shipping_pdf ? information.fedexorders[0].Shipping_pdf : ""}
                target="_blank"
              >
                Shipping PDF
                <BiLinkExternal />
              </Link>
            </td>
          </tr>
          <tr>
            <th className="u-text-align-left">Information 2:</th>
            <td className="u-display-flex u-align-center">
              {//information.document2.contentType
              }
              <Link
                className="btn-information"
                to={information.fedexorders[0].Commercial_invoice_pdf ? information.fedexorders[0].Commercial_invoice_pdf : ""}
                target="_blank"
              >
                Commercial Invoice PDF
                <BiLinkExternal />{" "}
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <ul>
      <li> Transaction Id: {information.transactionId} </li>
      <li> Category: {information.serviceCategory} </li>
      <li> Service Type: {information.serviceType} </li>
      <li> Ship Dates: {information.shipDatestamp} </li>
      <li>
        Information 1:
        {information.document1.contentType}
        <Link
          className="btn-information"
          to={information.document1 ? information.document1.url : ""}
          target="_blank"
        >
          <BiLinkExternal />
        </Link>
      </li>
      <li>
        Information 2:
        {information.document2.contentType}
        <Link
          className="btn-information"
          to={information.document2 ? information.document2.url : ""}
          target="_blank"
        >
          <BiLinkExternal />{" "}
        </Link>
      </li>
    </ul> */}
    </div>
  )
}

export default ShippingInformation
