import React, { useEffect } from "react"

// Components
import ContactForm from "../components/ContactForm"
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])  
  return (
    <>
      <section className="container">
          <ContactForm/>
    </section>
    </>
  )
}

export default ContactUs