import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link } from "gatsby";
import { FORGOT_PASSWORD } from "../mutations";
import { useMutation, useQuery } from "@apollo/client";
import Countdown from "react-countdown";
import { GET_USER } from "../querys";
import { getUser } from "../services/api";
import { tokenUser } from "../services/appenv";

const ForgotInformation = () => {
  // Regex for email
  const emailRegex = RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const [emailText, setEmailText] = useState({
    email: "",
    messageError: {},
  });
  const [timeRefresh, setTimeRefresh] = useState(false);
  const { messageError } = emailText;

  const { data } = useQuery(GET_USER, {
    variables: {
      email: emailText.email,
    },
  });
  

  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      toast.success(
        "An email was sent to you with the address to retrieve your password",
        { autoClose: 8000 }
      );
      setTimeRefresh(true);
      setTimeout(() => {
        setTimeRefresh(false);
      }, 300000);
      document.getElementById("formChange").reset();
      emailText.email = "";
    },
    onError: () => {
      toast.error("There was an error with the server, please try again later");
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errros = emailText.messageError;
    switch (name) {
      case "email":
        errros.email =
          value === ""
            ? "This field is required"
            : "" || emailRegex.test(value)
            ? ""
            : "This is not a valid email";
        break;
      default:
        break;
    }
    setEmailText((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSendEmail = () => {
    getUser(emailText.email, tokenUser).then((response) => {      
      if (response.length === 0) {
        return toast.error("Email is not registered");
      }

      if (response[0]?.typecustomer) {
      return forgotPassword({
        variables: {
          email: emailText.email,
        },
      });
      }else{
        toast.error("User not confirmed, please wait for confirmation");
      }
    });
  };
  const formValidate = (errors, ...data) => {
    let valid = true;
    Object.values(errors).forEach((val) => {
      val && (valid = false);
    });
    Object.values(data).forEach((val) => {
      val === "" && (valid = false);
    });
    return valid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailText.email === "") {
      toast.error("Please complete the form");
    } else {
      if (formValidate(emailText.messageError, emailText)) {
        handleSendEmail();
      } else {
        toast.error("Invalid email");
      }
    }
  };
  const renderer = ({ minutes, seconds }) => (
    <span>
      {minutes} minutes, {seconds} seconds
    </span>
  );
  return (
    <>
      <section className="container container-card-login">
        <h2>Password Reset</h2>
        <div className="container-card-login__content">
          <form
            className="row"
            id="formChange"
            noValidate
            onSubmit={handleSubmit}
          >
            <p className="u-margin-t-none u-margin-bottom-2">
              Enter your email address
            </p>
            <div className="col-lg-12 ">
              <div className="container-card-login__input">
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  id="changeType"
                  className={messageError.email ? "border-error" : ""}
                  style={{ paddingRight: "40px" }}
                />
                {messageError.email && (
                  <small className="field-error field-error-home">
                    {messageError.email}
                  </small>
                )}
              </div>
            </div>
            <div className="col-lg-12 ">
              <button type="submit" className="btn btn-primary">
                {timeRefresh === true ? (
                  <Countdown
                    renderer={renderer}
                    date={Date.now() + 300000}
                  ></Countdown>
                ) : (
                  "Reset your password"
                )}
              </button>
            </div>
            <div className="content-buttons-login">
              {/* <Link to="/register-new-member" className="forgot">
                <span>Sign Up Now</span>
              </Link> */}
              <Link to="/login" className="forgot">
                Do you already have an account?
              </Link>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default ForgotInformation;
