import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ADDRESS_BY_ID } from "../querys";

import {
  Row,
  Text,
  TextAddress,
  CardAddress,
  ButtonChange,
} from "../components/tabs/components/fields";

import Loading from "../components/Loading";
import { FaRegAddressBook } from "react-icons/fa";

import ModalAddress from "../components/Modal-address";
import {
  Container,
  ContainerPage,
} from "../components/styled/RegisterMemberStyled";

const EditAddresesses = ({ current_user }) => {
  const { data, loading } = useQuery(GET_ADDRESS_BY_ID, {
    variables: { id: parseInt(current_user.id) },
  });

  const [typeModal, setTypeModal] = useState("");
  const [modalAddress, setModalAddress] = useState(false);

  const [address, setAddress] = useState({
    user: current_user.id,
  });
  const [positionRow, setPositionRow] = useState("");
  const handleModalAddress = (typeModal, row, position) => {
    setTypeModal(typeModal);
    if (typeModal === "edit") {
      setPositionRow(position);
      setAddress(row);
    } else {
      setAddress({
        user: current_user.id,
      });
    }
    setModalAddress(true);
  };

  return (
    <div className="container">
      {loading && <Loading />}
      <ContainerPage>
        <h2>My Addresses</h2>
        <div className="container-full u-margin-top-1">
          {data?.addresses.data.length === 0 ? (
            <div className="u-text-center no-address-found">
              <FaRegAddressBook />
              <p>No addresses found</p>
              <button
                onClick={() => handleModalAddress("add", null)}
                className=""
              >
                Add your first address
              </button>
            </div>
          ) : (
            <div className="container-full">
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    onClick={() => handleModalAddress("add", null)}
                    className="u-margin-tb-1 btn btn-primary u-size-btn"
                  >
                    Add a New Address
                  </button>
                  {/* <Text style={{ width: "auto" }}>
                    {data?.addresses.data.length} found address registrations.
                  </Text> */}
                </div>

                {data?.addresses.data.map((addressRow, i) => (
                  <div className="col-lg-6" key={i}>
                    <div className="container">
                      <Row className="row border-top">
                        <CardAddress className="col-lg-8 col-md-8">
                          <TextAddress>
                            {addressRow.attributes.lastname}{" "}
                            {addressRow.attributes.firstname}
                          </TextAddress>
                          <TextAddress>
                            {addressRow.attributes.street}{" "}
                            {addressRow.attributes.aptNumber}
                            {addressRow.attributes.address}{" "}
                            {addressRow.attributes.cityName}{" "}
                            {addressRow.attributes.zipcode}
                          </TextAddress>
                          <TextAddress>
                            {addressRow.attributes.phone}
                          </TextAddress>
                        </CardAddress>
                        <ButtonChange className="col-lg-4 col-md-4">
                          <button
                            onClick={() =>
                              handleModalAddress(
                                "edit",
                                addressRow.attributes,
                                addressRow.id
                              )
                            }
                            className="btn btn-danger u-size-btn"
                          >
                            Change
                          </button>
                        </ButtonChange>
                      </Row>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </ContainerPage>

      <ModalAddress
        mainAddress={data?.addresses.data.length}
        positionRow={positionRow}
        typeModal={typeModal}
        address={address}
        openModal={modalAddress}
        setAddress={setAddress}
        onCancel={() => {
          setModalAddress(false);
        }}
        setModalAddress={setModalAddress}
      />
    </div>
  );
};

export default EditAddresesses;
