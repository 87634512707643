import React, { createContext, useState } from "react"
export const AuthUserContext = createContext(null)

const AuthUserProvider = props => {  
  const [authUser, setAuthUser] = useState({
    logged: false,
    user: {},
  })
  return (
    <AuthUserContext.Provider value={{ authUser, setAuthUser }}>
      {props.children}
    </AuthUserContext.Provider>
  )
}

export default AuthUserProvider
