import React, { useMemo } from "react"

import { useStaticQuery, graphql } from "gatsby"
import { Seo } from "../seo"
import WrapperInfo from "../wrapper-info"

const InfoArticle = ({ id }) => {
  const query = useStaticQuery(graphql`
    {
      allStrapiBlogPage {
        nodes {
          keywords
          lastUpdate(formatString: "YYYYY/MM/DD")
          metaDescription
          datePublication(formatString: "YYYYY/MM/DD")
          title
          slug
          image {
            url
          }
          content {
            data {
              content
            }
          }
        }
      }
    }
  `)
  const matchBlog = useMemo(
    () => query.allStrapiBlogPage.nodes.find(x => x.slug === id),
    [query, id]
  )
  const { title, image, metaDescription, keywords, content, slug } = matchBlog
  return (
    <>
      <Seo
        title={title}
        image={image[0].url}
        description={metaDescription}
        author={`Textile library`}
        url={`https://textilelibrary-takihyo.com/info/${slug}`}
        keywords={[keywords]}
      />

      <img src={image[0].url} alt="" className="banner-blog-description" />
      <div className="container content-blog">
        <div className="row">
          <div className="col-lg-9">
            <div className="content-blog__description">
              <h1>{title}</h1>
              <div
                className="info"
                dangerouslySetInnerHTML={{
                  __html: `<div>${content.data.content}</div>`,
                }}
              ></div>
            </div>
          </div>
          <div className="col-lg-3 content-blog__item-right">
            <WrapperInfo dataItems={query.allStrapiBlogPage.nodes} />
          </div>
        </div>
      </div>
    </>
  )
}

export default InfoArticle
