import styled from "@emotion/styled"

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em 0;
  a,
  span {
    margin: 0.5em 0;
  }
`

// For Card Product
export const Card = styled.div`
  cursor: pointer;
  color: var(--color-dark);
  position: relative;  
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  svg{
    opacity: .1;
    color: #747474;
  }
  .icon-no-image{
    background: #fdfdfd;
  }
  img, .icon-no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7em;
    width: 100%;
    padding: 5px 2px;
    object-fit: cover;
    height: 550px;
    border-radius: 3px;
    @media screen and (max-width: 1700px) {
      height: 400px;
    }
    @media screen and (max-width: 1390px) {
      height: 350px;
    }
    @media screen and (max-width: 1200px) {
      height: 400px;
    }
    @media screen and (max-width: 1050px) {
      height: 340px;
    }
    @media screen and (max-width: 850px) {
      height: 280px;
    }
    @media screen and (max-width: 768px) {
      height: 480px;
    }
    @media screen and (max-width: 680px) {
      height: 380px;
    }
    @media screen and (max-width: 520px) {
      height: 320px;
    }
    @media screen and (max-width: 420px) {
      height: 250px;
    }
  }
  a {
    width: 100%;
  }
  span {
    font-size: 1rem;
    font-weight: 300;
  }
  p {
    font-size: 0.8rem;
    font-weight: 300;
    span {
      font-size: 1rem;
      margin-right: 5px;
      color: var(--color-dark);
      font-weight: 900;
    }
  }
  h4 {
    margin: 0.5em 0;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 3px;
    color: var(--color-dark);
    font-weight: 300;
    background: var(--color-dark-tint);
  }
`
