import React, { useContext, useState } from "react";

import styled from "@emotion/styled";

import { useQuery, useMutation } from "@apollo/client";
import { GET_FAVORITE, GET_FAVORITE_ITEMS, USER_DATA } from "../querys";
import { DELETE_FAVORITE } from "../mutations";
import Loading from "../components/Loading";
import { toast } from "react-toastify";

import { getLinkProducts } from "../services/api";

import CardProduct from "../components/cards/CardProduct";
import Modal from "react-modal";
import { modalLink } from "../components/styled/ShooppingStyled";
import { IoMdClose } from "react-icons/io";
import SmallLoader from "../components/small-loader";
import { AuthUserContext } from "../context/AuthUser";
import { GrFavorite } from "react-icons/gr";

Modal.setAppElement("#___gatsby");

const Text = styled.span`
  margin: 1em 0;
  display: block;
  font-size: 1.3em;
`;

const FavoritesInfo = ({ current_user }) => {
  const { authUser } = useContext(AuthUserContext);

  const [favoriteItem, setFavoriteItem] = useState({});
  const { data: favoriteList, loading: loadingFavorite } = useQuery(
    GET_FAVORITE_ITEMS,
    {
      variables: {
        id: authUser?.user?.id,
      },
      onCompleted: (data) => {
        setFavoriteItem(data);
      },
    }
  );

  const { data: userData, loading: queryLoading } = useQuery(USER_DATA, {
    variables: { id: authUser ? authUser.user.id : current_user.id },
    onCompleted: () => {},
  });

  const { data, loading, error } = useQuery(GET_FAVORITE, {
    variables: { id: current_user.id },
    onCompleted: () => {},
  });

  const [stateDelete, setStateDelete] = useState(false);
  const [clearFavorite, { loading: loadingDelete }] = useMutation(
    DELETE_FAVORITE,
    {
      onCompleted: () => {},
      onError: () => {
        setStateDelete(true);
      },
    }
  );

  const handleClearAllFavorite = () => {
    clearFavorite({
      variables: { id: favoriteList?.favorites?.data[0]?.id },
      refetchQueries: ["getFavoriteItem"],
    });
  };
  const [allSelect, setAllSelect] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectAll = () => {
    if (allSelect) {
      // Deseleccionar todos los elementos
      setSelectedItems([]);
    } else {
      // Seleccionar todos los elementos
      const elements =
        favoriteList?.favorites?.data[0].attributes.favoritelist.map(
          (element) => element.productsid
        );
      setSelectedItems(elements);
    }
    // Actualizar el estado de allSelect
    setAllSelect(!allSelect);
  };

  const handleCheckItem = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    }
  };

  const [showBtnSelectall, setShowBtnSelectall] = useState(false);

  const token = localStorage.getItem("token");

  const dataSend = {
    id: authUser?.user?.id,
    data: selectedItems,
  };

  const [urlModal, setUrlModal] = useState(false);
  const [url, setUrl] = useState("");
  const [pending, setPending] = useState(false);
  const handleMakeLink = () => {
    setPending(true);
    setUrlModal(true);
    getLinkProducts(dataSend, token)
      .then((response) => setUrl(response?.token))
      .catch((error) =>
        toast.error(
          "There was an error generating the url, please try again later"
        )
      )
      .finally(() => setPending(false));
  };

  const handleClick = () => {
    toast.success("URL copied!");
    const el = document.createElement("textarea");
    el.value = `${window.location.origin}/share?q=${encodeURIComponent(url)}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const handleCloseModalLink = () => {
    setUrlModal((urlModal) => !urlModal);
    setSelectedItems([]);
    setShowBtnSelectall(false);
    setAllSelect(false);
  };


  return (
    <>
      {(loading || queryLoading) && <Loading />}

      {favoriteList ? (
        <div className="container">
          {loadingDelete && <Loading />}
          <div className="container-full">
            <div className="content-btn-favorite">
              {favoriteList && (
                <>
                  {favoriteList?.favorites?.data[0] && (
                    <Text>
                      {
                        favoriteList?.favorites?.data[0].attributes.favoritelist
                          .length
                      }{" "}
                      found favorites
                    </Text>
                  )}
                </>
              )}
              {favoriteList?.favorites?.data.length !== 0 ? (
                <div className="u-display-flex">
                  {selectedItems.length !== 0 && showBtnSelectall ? (
                    <button
                      style={{ width: "100px" }}
                      type="button"
                      onClick={() => handleMakeLink()}
                      className="btn u-text-center btn-outline"
                    >
                      MAKE LINK
                    </button>
                  ) : (
                    ""
                  )}

                  {!showBtnSelectall ? (
                    <button
                      style={{ width: "100px" }}
                      type="button"
                      onClick={() =>
                        setShowBtnSelectall(
                          (showBtnSelectall) => !showBtnSelectall
                        )
                      }
                      className="btn u-text-center btn-outline"
                    >
                      SHARE
                    </button>
                  ) : (
                    <button
                      style={{ width: "130px" }}
                      className="btn u-text-center btn-outline u-margin-left-1"
                      onClick={handleSelectAll}
                    >
                      {allSelect ? "UNCHECK ALL" : "SELECT ALL"}
                    </button>
                  )}

                  <button
                    style={{ width: "100px" }}
                    disabled={
                      favoriteList?.favorites?.data[0]?.attributes?.favoritelist
                        .length === 0
                    }
                    className="btn btn-danger u-margin-left-1"
                    onClick={() => handleClearAllFavorite()}
                  >
                    Clear All
                  </button>
                </div>
              ) : (
                <div className="no-favorite-data">
                  <GrFavorite />
                  <p>Add a favorite to your list to see it here</p>
                </div>
              )}
            </div>

            <div className={`row ${showBtnSelectall && "select-active"}`}>
              {favoriteList?.favorites?.data[0]?.attributes?.favoritelist.map(
                (card, i) => (
                  <div
                    className="col-xl-3 col-lg-4 col-md-4 col-6 u-margin-bottom-3 u-position-relative"
                    key={i}
                  >
                    {showBtnSelectall ? (
                      <div className="content-checkbox-mark">
                        <label className="checkbox">
                          <input
                            id={card?.productsid}
                            type="checkbox"
                            value={card?.productsid}
                            checked={selectedItems.includes(card?.productsid)}
                            onChange={(e) => handleCheckItem(e)}
                          />

                          <span className="checkmark"></span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    <CardProduct
                      confirmed={0}
                      data={card}
                      like_button={false}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Modal isOpen={urlModal} style={modalLink}>
        <div className="container">
          <button
            className="btn-close-link"
            onClick={() => handleCloseModalLink()}
          >
            <IoMdClose />
          </button>
          <div className="row content-modal-check-fields">
            <p className="title-alignament">This is your shared link</p>

            {pending ? (
              <SmallLoader />
            ) : (
              <>
                <div style={{ width: "85%", paddingRight: "30px" }}>
                  <p className="wrap-text">{`${
                    window.location.origin
                  }/share?q=${encodeURIComponent(url)}`}</p>
                </div>
                <button
                  style={{ width: "13%" }}
                  className="btn u-text-center btn-outline"
                  onClick={handleClick}
                >
                  Copy
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FavoritesInfo;
