import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { Seo } from "../components/seo"
import Footer from "../components/layout/Footer"

import WrapperInfo from "../components/wrapper-info"

const InfoBlog = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiBlogPage {
        nodes {
          keywords
          lastUpdate(formatString: "YYYYY/MM/DD")
          metaDescription
          datePublication(formatString: "YYYYY/MM/DD")
          title
          slug
          image {
            url
          }
          content {
            data {
              content
            }
          }
        }
      }
    }
  `)
  const dataItems = query.allStrapiBlogPage.nodes
  return (
    <>
      <Seo
        title={"Info"}
        description={"Articles"}
        author={`Textile library`}
        url={`https://textilelibrary-takihyo.com/info`}
        keywords={["info"]}
      />
      <div className="container content-blog">
        <div className="row">
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                {dataItems.map((item, i) => (
                  <Link
                    key={i}
                    to={`/info/${item.slug}`}
                    className="col-lg-6 content-blog__item"
                  >
                    <div className="content-blog__item--card">
                      <img src={item.image[0].url} alt="" />
                      <h1>{item.title}</h1>
                      <div
                        style={{ fontSize: "14px" }}
                        dangerouslySetInnerHTML={{
                          __html: `<div>${item.metaDescription.substr(0, 250)}</div>`,
                        }}
                      ></div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-3 content-blog__item-right">
            <WrapperInfo dataItems={dataItems} />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default InfoBlog
