import React, { FC, useState } from "react"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import { TitleStyle, WeightStyle } from "./styled-widget"
import { IoIosArrowDown } from "react-icons/io"
import Collapsible from "react-collapsible"
interface WeightData {
  weightList: any
  selected: string
  handleSelect: any
  w_min: number
  w_max: number
  handleSlider: any
}

const WeightWidget: FC<WeightData> = ({
  weightList,
  selected,
  handleSelect,
  w_min,
  w_max,
  handleSlider,
}) => {
  const [collapse, setCollapse] = useState<boolean>(false)  
  return (
    <WeightStyle>
      <Collapsible
        trigger={
          <TitleStyle onClick={() => setCollapse(!collapse)}>
            Weight
            <IoIosArrowDown
              style={{
                transform: `${collapse ? "rotate(180deg)" : "rotate(0deg)"} `,
              }}
            />
          </TitleStyle>
        }
        transitionTime={200}
      >
        <div>
          <ul>
            {weightList
              .slice(0, collapse ? weightList.length : 0)
              .map((option: any) => {
                const isSelected = selected.includes(option.name)
                return (
                  <li onClick={() => handleSelect(option.name)}>
                    <p
                      style={{
                        fontWeight: `${isSelected ? "bold" : ""}`,
                      }}
                    >
                      {option.name}
                    </p>
                  </li>
                )
              })}
          </ul>
          <div className="u-padding-slider slider-content">
            <span
              className="slider-content__min"
              style={{
                left: `calc(${w_min}% / 10 - ${w_min === 0 ? "2px" : "8px"} )`,
              }}
            >
              {w_min}
            </span>

            <span
              className="slider-content__max"
              style={{
                left: `calc(${w_max}% / 10 - 8px)`,
              }}
            >
              {w_max}
            </span>
            <Slider
              onAfterChange={e => handleSlider(e)}
              className="weight-slider"
              range
              dots
              min={0}
              max={1000}
              step={100}
              defaultValue={[w_min || 0, w_max || 1000]}
              marks={{
                0: "0 g/m2",
                1000: "1000 g/m2",
              }}
            />
          </div>
        </div>
      </Collapsible>
    </WeightStyle>
  )
}

export default WeightWidget
