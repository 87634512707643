import React, { useEffect, useState, useContext } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { useQuery, useMutation } from "@apollo/client";
import { USER_DATA } from "../querys";
import {
  ADD_ORDER,
  DELETE_SHIPPING_CART,
  GET_SHIPPING_CART,
} from "../mutations";
import { toast } from "react-toastify";
import { navigate, Link } from "gatsby";
import Loading from "../components/Loading";
import Loader from "./Loader";
import axios from "axios";
import _ from "underscore";

import {
  addDataToGoogleSheet,
  createCustomer,
  getPaymentIntent,
  sendOrderMail,
} from "../services/api";

import { CartContentContext } from "../context/CartContentContext";
import { apiBase } from "../services/appenv";

import errorStripe from "../images/icons/error-data.icon.svg";
import { AuthUserContext } from "../context/AuthUser";

const Card_Styles = {
  style: {
    base: {
      padding: "24px 12px",
      fontSize: "1rem",
      backgroundColor: "white",
    },
  },
};

const CheckOutForm = () => {
  const { authUser } = useContext(AuthUserContext);
  const [pendingData, setPendingData] = useState(false);
  const { paymentOrder, setPaymentOrder } = useContext(CartContentContext);
  const stripe = useStripe();
  const elements = useElements();
  const [getidCartpproduct, setGetidCartpproduct] = useState("");


  useEffect(() => {
    if(Object.keys(paymentOrder).length === 0){
      toast.error("Select delivery address or add new delivery address")
      navigate("/shopping")
    }
  }, [])  

  useQuery(GET_SHIPPING_CART, {
    variables: {
      id: authUser?.user?.id,
    },
    onCompleted: (data) => {
      setGetidCartpproduct(data.cartItems.data[0]?.id);
    },
  });
  const [userOwner, setUserOwner] = useState({});
  const { data: userData, loading: queryLoading } = useQuery(USER_DATA, {
    variables: { id: authUser?.user?.id },
    onCompleted: (data) => {
      setUserOwner(data.usersPermissionsUser.data.attributes);
    },
  });

  const filterMainAddress = _.filter(
    userOwner?.addresses?.data,
    (address) => address?.attributes?.main === true
  );

  const [loadingPay, setLoadingPay] = useState(false);
  // const HEADERS = {
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }
  const [payMethod, setPayMethod] = useState("");

  const [addOrder] = useMutation(ADD_ORDER, {
    onCompleted: () => {},
    refetchQueries: ["orderUser"],
  });

  const [deleteItem] = useMutation(DELETE_SHIPPING_CART);

  const handleDeleteItem = async () => {
    await deleteItem({
      variables: {
        id: getidCartpproduct ? getidCartpproduct : "",
      },
      refetchQueries: ["getCartItems", "getCartItem"],
    });
  };
  const handleSubmit = async (method) => {
    //   setLoadingPay(true)
    setPendingData(true);
    const uniqid = require("uniqid");

    const token = localStorage.getItem("token");
    if (!stripe || !elements) {
      return;
    }
    //   const dataCustomer = {
    //     email: email,
    //     description: `This description is for ${email} customer`,
    //   }
    //   const customer = await createCustomer(token, dataCustomer)
    const totalAmount = parseFloat(
      parseFloat(paymentOrder?.shippingCost) +
        parseFloat(paymentOrder?.totalPriceProducts)
    ).toFixed(2);
    const dataSend = {
      amount: totalAmount,
      currency: "usd",
      payment_method_types: ["card"],
    };

    const response = await getPaymentIntent(token, dataSend);
    const card = elements.getElement(CardElement);
    const { clientSecret, id } = response;
    const getToken = await stripe.createToken(card);

    await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
        },
      })
      .then((data) => {
        // console.log(data.status, "datadatadatadatadatadata");
      });

    await addOrder({
      variables: {
        data: {
          id_payment: id,
          card_id: getToken?.token?.id,
          users_permissions_user: authUser?.user?.id,
          dateOrder: new Date(),
          amount: parseFloat(
            parseFloat(paymentOrder?.shippingCost) +
              parseFloat(paymentOrder?.totalPriceProducts)
          ),
          username: userOwner?.firstName + " " + userOwner?.lastName,
          companyName: userOwner?.companyName,
          status: "Ordered",
          phone: filterMainAddress[0]?.attributes?.phone,
          email: userOwner?.email,
          payment_method: "card",
          harikae: userOwner?.harikae?.data?.attributes?.harikae,
          detailSwatch: paymentOrder?.products?.map((product) => ({
            articleNumber: product.productId,
            colorNumber: product.colortype,
            quantity: parseFloat(product.quantity),
            regularPrice: parseFloat(product.price),
            image: product.image,
            supplierid: product?.supplierid,
            supplierproductname: product?.supplierproductname,
          })),
          shippingFee: parseFloat(paymentOrder?.shippingCost),
          shippingAddress: {
            address: paymentOrder?.shippingAddress?.address,
            city: paymentOrder?.shippingAddress?.cityName,
            shippingUser:
              paymentOrder?.shippingAddress?.firstname +
              " " +
              paymentOrder?.shippingAddress?.lastname,
            phoneNumber: paymentOrder?.shippingAddress?.phone,
            zipcode: paymentOrder?.shippingAddress?.zipcode,
            street: paymentOrder?.shippingAddress?.street,
          },
          status: "Ordered",
        },
      },
      refetchQueries: ["orderUser"],
    });
    const dataSEND = {
      date_order: new Date().toLocaleString(),
      id_order: uniqid.time("COO-"),
      company_name: userOwner?.companyName,
      email: userOwner?.email,
      user_name: userOwner?.firstName + " " + userOwner?.lastName,
      payment_method: "card",
      total_amount: totalAmount,
      shipping_fee: paymentOrder?.shippingCost,
      article: paymentOrder.products,
      address_shipping: paymentOrder?.shippingAddress?.address,
      city_shipping: paymentOrder?.shippingAddress?.cityName,
      user_shipping:
        paymentOrder?.shippingAddress?.firstname +
        paymentOrder?.shippingAddress?.lastname,
      phone_number_shipping: paymentOrder?.shippingAddress?.phone,
      street_shipping: paymentOrder?.shippingAddress?.street,
      zip_code_shipping: paymentOrder?.shippingAddress?.zipcode,
      order_state: "Ordered",
      harikae: userOwner?.harikae?.data?.attributes?.harikae,
    };

    await addDataToGoogleSheet(token, dataSEND)
      .then((response) => {
        if (response.status === 200) {
          setPendingData(false);
        } else {
          setPendingData(false);
        }
      })
      .catch(() => {
        setPendingData(false);
      });

    // setPendingData(true);
    await sendOrderMail(token, dataSEND)
      .then((response) => {
        if (response.status === "successful") {
          handleDeleteItem();
          setPaymentOrder({});
        } else {
          setPendingData(false);
        }
      })
      .catch(() => {
        toast.error("An error occurred with the email service");
        setPendingData(false);
      });

    navigate("/thanks-for-purchase");
    toast.success("Your order was successful!");
  };
  return (
    <>
      {pendingData && <Loader />}

      {elements === null || stripe === null ? (
        <>
          <Loading />
          <div className="message-loading">
            <img
              className="icon-error-stripe"
              src={errorStripe}
              width=""
              alt=""
            />
            <p className="message-error-stripe">
              An error occurred while continuing with card payment. Please try
              again later.
            </p>
            <Link
              to="/products"
              className="btn btn-danger btn-width-150 u-margin-top-1"
            >
              Back to products
            </Link>
          </div>
        </>
      ) : (
        ""
      )}
      {queryLoading ||
        (loadingPay && (
          <>
            <Loading />

            <div className="message-loading">
              <div className="loading loading-card"></div>
              <p>Proceeding to checkout ... please wait.</p>
            </div>
          </>
        ))}
      <div style={{ margin: "24px 0" }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="container">
            <div className="row">
              <div className="content-dataUser">
                <div>
                  <span>Full Name</span>
                  <input
                    value={userOwner?.firstName + " " + userOwner?.lastName}
                    disabled
                  ></input>
                </div>
                <div>
                  <span>Email</span>
                  <input disabled value={userOwner?.email} />
                </div>
                <div>
                  
                  <span>Address</span>
                  <input disabled value={filterMainAddress[0]?.attributes?.address} />
                </div>
                <div>
                  <span>Country</span>
                  <input disabled value={filterMainAddress[0]?.attributes?.country} />
                </div>
                <div>
                  <span>State</span>
                  <input disabled value={filterMainAddress[0]?.attributes?.state} />
                </div>
                <div>
                  <span>Zip Code</span>
                  <input disabled value={filterMainAddress[0]?.attributes?.zipcode} />
                </div>
                <div>
                  <span>Phone Number</span>
                  <input disabled value={filterMainAddress[0]?.attributes?.phone} />
                </div>
              </div>

              <>
                <h6 className="text-shipping-info">Shipping Information</h6>
                <div className="content-dataUser">
                  <div>
                    <span>Full Name</span>
                    <input
                      value={
                        paymentOrder?.shippingAddress?.firstname +
                        " " +
                        paymentOrder?.shippingAddress?.lastname
                      }
                      disabled
                    ></input>
                  </div>
                  <div>
                    <span>Address</span>
                    <input
                      disabled
                      value={paymentOrder?.shippingAddress?.address}
                    />
                  </div>
                  <div>
                    <span>City</span>
                    <input
                      disabled
                      value={paymentOrder?.shippingAddress?.cityName}
                    />
                  </div>
                  <div>
                    <span>Street</span>
                    <input
                      disabled
                      value={paymentOrder?.shippingAddress?.street}
                    />
                  </div>
                  <div>
                    <span>Zipcode</span>
                    <input
                      disabled
                      value={paymentOrder?.shippingAddress?.zipcode}
                    />
                  </div>
                  <div>
                    <span>Phone Number</span>
                    <input
                      disabled
                      value={paymentOrder?.shippingAddress?.phone}
                    />
                  </div>
                </div>
              </>

              <div className="col-md-12">
                <h6 className="u-margin-left-none text-shipping-info">
                  Payment Method
                </h6>
                <>
                  <div className="u-margin-top-2 u-margin-bottom-2">
                    <CardElement options={Card_Styles} />
                  </div>

                  <button
                    onClick={() => handleSubmit("card")}
                    disabled={!stripe}
                    className="btn btn-primary"
                  >
                    {queryLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span className="pay-text">
                        Pay ${" "}
                        {parseFloat(
                          parseFloat(paymentOrder?.shippingCost) +
                            parseFloat(paymentOrder?.totalPriceProducts)
                        ).toFixed(2)}
                        {/* Pay $ {totalAmout.toFixed(2)} */}
                      </span>
                    )}
                  </button>
                </>

                {payMethod === "after_pay" && (
                  <button
                    onClick={() => handleSubmit("afterpay")}
                    disabled={!stripe}
                    className="btn btn-primary"
                  >
                    {queryLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span className="pay-text">
                        Afterpay $ 5151
                        {/* Afterpay $ {totalAmout.toFixed(2)} */}
                      </span>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CheckOutForm;
