import React from "react";

import { ApolloProvider } from "@apollo/client";
import client from "./client";
import { ToastContainer, toast, cssTransition } from "react-toastify";

import SearchProvider from "../context/SearchContext";
import FilterProvider from "../context/FilterContext";
import Main from "../routes/Main.routes";
import AuthUserProvider from "../context/AuthUser";
import CredentialsProvider from "../context/CredentialsContext";
import CartContentProvider from "../context/CartContentContext";
import GetQuerysProvider from "../context/GetQuerysContext";

import Chatbot from "../components/Chatbot";

export const wrapRootElement = () => {
  const customToast = cssTransition({
    enter: "fade-in-fwd",
    exit: "fade-out-bck",
  });
  return (
    // <ServerLocation url={window.location.pathname}>
    <ApolloProvider client={client}>
      <ToastContainer
        draggable={false}
        autoClose={4000}
        pauseOnHover={false}
        position={toast.POSITION.BOTTOM_RIGHT}
        transition={customToast}
      />

      <FilterProvider>
        <SearchProvider>
          <CredentialsProvider>
            <AuthUserProvider>
              <CartContentProvider>
           
                  <GetQuerysProvider>
                    <Main />
                    <Chatbot />
                  </GetQuerysProvider>
            
              </CartContentProvider>
            </AuthUserProvider>
          </CredentialsProvider>
        </SearchProvider>
      </FilterProvider>
    </ApolloProvider>
  );
};
