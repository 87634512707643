import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_ADDRESS, NEW_USER } from "../../mutations";
import {
  Container,
  Row,
  InputField,
  Label,
  Buttons,
  Terms,
} from "../styled/RegisterMemberStyled";
import { navigate, Link } from "gatsby";
import { toast } from "react-toastify";
import { userTokenID } from "../../services/appenv";
const RegisterMember = () => {
  // Regex for email
  const emailRegex = RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  /**
   * State for registration inputs
   */
  const [register, setRegister] = useState({
    firstname: "",
    lastName: "",
    companyName: "",
    email: "",
    repeatEmail: "",
    address: "",
    addressOptional: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    phone: "",
    password: "",
    repeatPassword: "",
    termsCondition: false,
    messageError: {},
  });

  const { messageError } = register;

  const cancelRegister = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const formValidate = (errors, ...data) => {
    let valid = true;
    Object.values(errors).forEach((val) => {
      val && (valid = false);
    });
    Object.values(data).forEach((val) => {
      val === "" && (valid = false);
    });
    return valid;
  };
  const [checkTermsConditions, setcheckTermsConditions] = useState(false);
  const handleCheckConfirm = (event) => {
    setcheckTermsConditions(event.target.checked);
  };
  /**
   * Method that captures the value of each input
   * @param {*Parameter for input} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    let errros = register.messageError;
    switch (name) {
      case "firstname":
        errros.firstname = value === "" ? "This field is required" : "";
        break;
      case "lastName":
        errros.lastName = value === "" ? "This field is required" : "";
        break;
      case "email":
        errros.email =
          value === ""
            ? "This field is required"
            : "" || emailRegex.test(value)
            ? ""
            : "Invalid email";
        errros.repeatEmail =
          register.email !== register.repeatEmail ? "Emails don't match" : "";
        break;
      case "repeatEmail":
        errros.repeatEmail =
          register.email !== register.repeatEmail ? "Emails don't match" : "";
        break;
      case "address":
        errros.address = value === "" ? "This field is required" : "";
        break;
      case "city":
        errros.city = value === "" ? "This field is required" : "";
        break;
      case "state":
        errros.state = value === "" ? "This field is required" : "";
        break;
      case "country":
        errros.country = value === "" ? "This field is required" : "";
        break;
      case "zipcode":
        errros.zipcode = value === "" ? "This field is required" : "";
        break;
      case "phone":
        errros.phone = value === "" ? "This field is required" : "";
        break;
      case "password":
        errros.password = value === "" ? "This field is required" : "";
      case "repeatPassword":
        errros.repeatPassword =
          register.password !== register.repeatPassword
            ? "Passwords do not match"
            : "";
        break;
      default:
        break;
    }

    setRegister((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [createAddress, { loading: loadingAddress, error: errorAddress }] =
    useMutation(CREATE_ADDRESS, {
      context: {
        headers: {
          Authorization: `Bearer ${userTokenID}`,
        },
      },
      onCompleted: () => {
        toast.success("Your address was added successfully");
      },
      onError: () => {},
    });

  const [currentRegister, setCurrentRegister] = useState("");
  const [addUser, { loading: mutationLoading, error: mutationError }] =
    useMutation(NEW_USER, {
      onCompleted: (data) => {        
        setCurrentRegister(data?.createUsersPermissionsUser?.data?.id);
        toast.success("Your registration was completed successfully");
        setTimeout(() => {
          createAddress({
            variables: {
              data: {
                address: register.address,
                addressOptional: register.addressOptional,
                lastname: register.lastName,
                firstname: register.firstname,
                cityName: register.city,
                zipcode: register.zipcode,
                country: register.country,
                state: register.state,
                phone: register.phone,
                companyName: register.companyName,
                street: "",
                main: true,
                users_permissions_user:
                  data?.createUsersPermissionsUser?.data?.id,
              },
            },
          });
          navigate("/login");
        }, 500);
      },

      onError: () => {
        toast.error("The user is already registered");
      },
    });  
  const handleSubmit = (e) => {
    e.preventDefault();
    handleRegisterUser();
    if (formValidate(register.messageError, register)) {
      if (!checkTermsConditions) {
        toast.error("Please, you have to accept the terms and conditions");
      } else {
      }
    } else {
      toast.error("Please complete the form");
    }
  };

  const handleRegisterUser = async () => {
    await addUser({
      variables: {
        data: {
          username: register.email,
          email: register.email,
          password: register.password,
          role: 1,
          firstName: register.firstname,
          lastName: register.lastName,
          typecustomer: "Regular_customer",
          registrationform: "New_user_Form",

          // confirmed: checkTermsConditions,
          // address: register.address,
          // city: register.city,
          // addressOptional: register.addressOptional,
          // state: register.state,
          // country: register.country,
          // companyName: register.companyName,
          // zipcode: register.zipcode,
          // phoneNumber: register.phone,
        },
      },
    });
  };

  return (
    <>
      <Container className="container" onSubmit={handleSubmit} noValidate>
        <h2>Registration</h2>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="name">Name</Label>
          </div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="text"
                id="name"
                name="firstname"
                placeholder="First Name (required)"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.firstname ? "border-error" : ""}
              />
              {messageError.firstname && (
                <small className="field-error">{messageError.firstname}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="text"
                placeholder="Last Name (required)"
                name="lastName"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.lastName ? "border-error" : ""}
              />
              {messageError.lastName && (
                <small className="field-error">{messageError.lastName}</small>
              )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="company">Company</Label>
          </div>
          <div className="col-lg-8">
            <InputField>
              <input
                type="text"
                id="company"
                name="companyName"
                placeholder="Company name (optional)"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.companyName ? "border-error" : ""}
              />
              {messageError.companyName && (
                <small className="field-error">
                  {messageError.companyName}
                </small>
              )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="email">Email</Label>
          </div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="E-Mail Address (required)"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.email ? "border-error" : ""}
              />
              {messageError.email && (
                <small className="field-error">{messageError.email}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="email"
                name="repeatEmail"
                placeholder="Confirm E-mail Address"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.repeatEmail ? "border-error" : ""}
              />
              {messageError.repeatEmail && (
                <small className="field-error">
                  {messageError.repeatEmail}
                </small>
              )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="address">Address</Label>
          </div>
          <div className="col-lg-8 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Address 1 (required)"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.address ? "border-error" : ""}
              />
              {messageError.address && (
                <small className="field-error">{messageError.address}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-8 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                name="addressOptional"
                placeholder="Address 2 (optional)"
                onChange={handleChange}
              />
            </InputField>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-4 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="City (required)"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.city ? "border-error" : ""}
              />
              {messageError.city && (
                <small className="field-error">{messageError.city}</small>
              )}
            </InputField>
          </div>

          <div className="col-lg-4 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                placeholder="State (required)"
                name="state"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.state ? "border-error" : ""}
              />
              {messageError.state && (
                <small className="field-error">{messageError.state}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="text"
                placeholder="Country (required)"
                name="country"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.country ? "border-error" : ""}
              />
              {messageError.country && (
                <small className="field-error">{messageError.country}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="text"
                placeholder="Zip Code (required)"
                name="zipcode"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.zipcode ? "border-error" : ""}
              />
              {messageError.zipcode && (
                <small className="field-error">{messageError.zipcode}</small>
              )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="phone">Phone Number</Label>
          </div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="phone"
                id="phone"
                placeholder="Phone number (required)"
                name="phone"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.phone ? "border-error" : ""}
              />
              {messageError.phone && (
                <small className="field-error">{messageError.phone}</small>
              )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="password">Password</Label>
          </div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password (required)"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.password ? "border-error" : ""}
              />
              {messageError.password && (
                <small className="field-error">{messageError.password}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4">
            <InputField>
              <input
                type="password"
                name="repeatPassword"
                placeholder="Repeat password"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.repeatPassword ? "border-error" : ""}
              />
              {messageError.repeatPassword && (
                <small className="field-error">
                  {messageError.repeatPassword}
                </small>
              )}
            </InputField>
          </div>
        </Row>
        <Buttons className="row u-text-center">
          <Terms>
            <div className="input-checkbox-filter">
              <input
                type="checkbox"
                id="terms"
                name="termsCondition"
                className="check-condition"
                checked={checkTermsConditions}
                onChange={(event) => handleCheckConfirm(event)}
              />
              <label htmlFor="terms" className="u-margin-tb-2">
                Please agree to the{" "}
                <Link to="/terms-and-conditions">terms</Link> of service and
                proceed.
              </label>
              {/* {messageError.termsCondition && (
                <small>{messageError.termsCondition}</small>
              )} */}
            </div>
          </Terms>
          <button
            type="submit"
            className="btn btn-danger btn-width-200 u-margin-bottom-1"
          >
            {mutationLoading ? (
              <div className="loading"></div>
            ) : (
              "Save my profile"
            )}
          </button>
          <button
            type="button"
            onClick={cancelRegister}
            className="btn btn-primary btn-width-200"
          >
            Cancel
          </button>
        </Buttons>
      </Container>
    </>
  );
};

export default RegisterMember;
