import React, { useState, useRef, useEffect } from "react";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

import { RiCloseFill } from "react-icons/ri";
import { apiGPT } from "../services/appenv";
import { sendMessageToChat } from "../services/api";

const API_KEY = apiGPT;

const systemMessage = {
  role: "assistant",
  content:
    "Explain things like you're talking to a software professional with 2 years of experience.",
};

const Chatbot = () => {
  const [messages, setMessages] = useState([
    {
      message: "Hi, can I help you with something?",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  // useEffect(() => {
  //   sendMessageToChat().then((message) => console.log(message));
  // }, []);

  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    const apiRequestBody = {
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };
    await sendMessageToChat(apiRequestBody).then((data) => {      
      setMessages([
        ...chatMessages,
        {
          message: data?.response[0]?.message?.content,
          sender: "ChatGPT",
        },
      ]);
      setIsTyping(false);
    });
  }
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef(null);
  const toggleChatbot = () => {
    setIsOpen(!isOpen);
    btnRef?.current?.classList.toggle("active");
  };

  return (
    <div className="chatbot-content">
      <div className="u-position-relative">
        {isOpen && (
          <div
            style={{
              position: "relative",
              height: "450px",
              width: "320px",
              bottom: "40px",
            }}
          >
            <div className="chatbot-content__header" onClick={toggleChatbot}>
              Got a question? ask the assistant <RiCloseFill />
            </div>
            <MainContainer style={{ flexDirection: "column" }}>
              <ChatContainer>
                <MessageList
                  autoScrollToBottom="true"
                  autoScrollToBottomOnMount="true"
                  scrollBehavior="smooth"
                  typingIndicator={
                    isTyping ? (
                      <TypingIndicator content="Assistant is typing" />
                    ) : null
                  }
                >
                  {messages.map((message, i) => {
                    return <Message key={i} model={message} />;
                  })}
                </MessageList>
                <MessageInput
                  placeholder="Type message here"
                  onSend={handleSend}
                  // fileAttach={false}
                  attachmentsButton={false}
                />
              </ChatContainer>
            </MainContainer>
          </div>
        )}
        <div
          className="bubble-button btn_chat"
          ref={btnRef}
          onClick={toggleChatbot}
        >
          {/* {isOpen ? <RiCloseFill /> : <BsChatLeftDots />} */}
          <svg
            className="chat-bubble"
            width="60"
            height="60"
            viewBox="0 0 100 100"
          >
            <g className="bubble">
              <path
                className="line line1"
                d="M 30.7873,85.113394 30.7873,46.556405 C 30.7873,41.101961
36.826342,35.342 40.898074,35.342 H 59.113981 C 63.73287,35.342
69.29995,40.103201 69.29995,46.784744"
              />
              <path
                className="line line2"
                d="M 13.461999,65.039335 H 58.028684 C
63.483128,65.039335
69.243089,59.000293 69.243089,54.928561 V 45.605853 C
69.243089,40.986964 65.02087,35.419884 58.339327,35.419884"
              />
            </g>
            <circle className="circle circle1" r="1.9" cy="50.7" cx="42.5" />
            <circle className="circle circle2" cx="49.9" cy="50.7" r="1.9" />
            <circle className="circle circle3" r="1.9" cy="50.7" cx="57.3" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
