import { useStaticQuery, graphql } from "gatsby";

const useListMap = () => {
  const data = useStaticQuery(graphql`
    {
      allTokyoMapsListMap {
        nodes {
          category
          title
          description
          lat
          lng
          image1
          image2
        }
      }
    }
  `);
  return data;
};

export default useListMap;
