import React, { useEffect, useState } from "react"
import { useStripe } from "@stripe/react-stripe-js"
import { toast } from "react-toastify"
import { Link, navigate } from "gatsby"
const AfterPayReturn = ({ search }) => {
  
  const stripe = useStripe()
  const { payment_intent_client_secret } = search

  const [paymentInfo, setPaymentInfo] = useState({})

  useEffect(() => {
    if (!stripe) {
      return
    }
    const getPaymentIntentInfo = async () => {
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(
        payment_intent_client_secret
      )
      if (error) {
        toast.error(error.message)
      }
      
      setPaymentInfo(paymentIntent)
    }
    getPaymentIntentInfo()
  }, [payment_intent_client_secret, stripe])
  return (
    <>
      <div className="container thanks-for-purchase">
        {paymentInfo.status === "succeeded" ? (
          <>
            <h3>Thank you for your purchase!</h3>
            <p>
              Your order was successful. You will receive an email confirmation
              shortly. If you have any questions about your purchase, don’t
              hesitate{" "}
              <Link to="/contact-us" target="_blank">
                contact us.
              </Link>
            </p>
            <div className="u-display-flex">
              <button
                onClick={() => navigate("/")}
                type="submit"
                className="u-margin-left-05 u-margin-right-05 btn btn-primary u-margin-bottom-1"
              >
                Back to Library
              </button>
              <button
                onClick={() => navigate("/order-history")}
                type="submit"
                className="u-margin-left-05 u-margin-right-05 btn btn-outline-primary u-margin-bottom-1"
              >
                View your Orders
              </button>
            </div>
          </>
        ) : (
          <>
            <h3>Purchase error</h3>
            <p>
              An error occurred when making the purchase, please verify that the
              data is correct or {" "} 
              <Link to="/contact-us" target="_blank">
                contact us.
              </Link>
            </p>
            <div className="u-display-flex">
              <button
                onClick={() => navigate("/")}
                type="submit"
                className="u-margin-left-05 u-margin-right-05 btn btn-primary u-margin-bottom-1"
              >
                Back to Library
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default AfterPayReturn
