import React, { useState, useContext, useEffect } from "react";
import { LOGIN } from "../../mutations";
import { useMutation } from "@apollo/client";

import { navigate, Link } from "gatsby";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { CredentialsContext } from "../../context/CredentialsContext";
import client from "../../apollo/client";
import { AuthUserContext } from "../../context/AuthUser";

import eyeIcon from "../../images/icons/eye.icon.svg";
import eyeCloseIcon from "../../images/icons/eye-close.icon.svg";
import { getCurrentUser } from "../../services/api";

const Login = () => {
  const { setAuthUser } = useContext(AuthUserContext);

  const { credentials, setCredentials } = useContext(CredentialsContext);

  const [showIcon, setShowIcon] = useState(false);
  const [messageError] = useState({ username: "", password: "" });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("user_data")) {
        navigate("/orders");
      } else {
        navigate("/home");
      }
    } else {
      client.clearStore();
      localStorage.removeItem("token");
      localStorage.removeItem("user_data");
      navigate("/login");
    }
  }, []);

  const formValidate = (errors, ...data) => {
    let valid = true;
    Object.values(errors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    Object.values(data).forEach((val) => {
      val === "" && (valid = false);
    });
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = messageError;
    switch (name) {
      case "username":
        errors.username = value === "" ? "This field is required" : "";
        break;
      case "password":
        errors.password = value === "" ? "This field is required" : "";
        break;
      default:
        break;
    }
    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // setInitialState({ errors, [name]: value })
  };

  /**
   * Mutation for login auth with strapi graphql
   */
  const [login, { loading: mutationLoading, error: mutationError }] =
    useMutation(LOGIN, {
      onCompleted: async (data) => {
        const { jwt } = data.login;
        // console.log(data, 'datadatadata')
        if (data) {
          localStorage.setItem("token", jwt);
          // setAuthUser({ logged: true, user: data.login.user });

          await getCurrentUser(jwt).then((response) => {            
            if (response?.role) {
              if (response.role.id === 3) {
                navigate("/orders");
              } else {
                navigate("/home");
              }
              setAuthUser({
                logged: true,
                typeCustomer: response.typecustomer,
                role: response.role.id,
                user: { ...data.login.user, confirmed: response.typecustomer },
              });
              toast.success("Successful login");
              // Cookies.set("user_id", response.role.id, {
              //   httpOnly: false,
              //   secure: true,
              // });
            } else {
              toast.error("The user does not have access");
            }
          });
        }

        // toast.success("Successful login");

        // window.location.href = "/products"
        // if (data.login.user.id == "115") {
        //   localStorage.setItem("user_data", data.login.user.id);
        //   navigate("/orders");
        // } else {
        //   navigate("/home");
        // }
      },
      onError: () => {
        navigate("/login");
        setAuthUser({ logged: false, user: {} });
        toast.error("Invalid username or password");
      },
      refetchQueries: ["refreshCurrentUser"],
    });

  const handleLogin = () => {
    // localStorage.removeItem("token")
    const { username, password } = credentials;
    login({
      variables: {
        login: username,
        password,
      },
    });
  };

  /**
   *  Method for identifier user with jwt
   * @param {*Only prevent the reload page} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (messageError.username !== "" || messageError.password !== "") {
      toast.error("Please, enter username and password");
    } else {
      if (formValidate(messageError, credentials)) {
        handleLogin();
      } else {
        toast.error("Fill in the required fields");
      }
    }
  };

  /**
   * Handle => Change icon and type for input password
   */
  const handleChangeType = () => {
    const ELEMENT_TYPE_CHANGE = document.getElementById("changeType");
    if (ELEMENT_TYPE_CHANGE.type === "text") {
      ELEMENT_TYPE_CHANGE.type = "password";
      setShowIcon(false);
    } else {
      ELEMENT_TYPE_CHANGE.type = "text";
      setShowIcon(true);
    }
  };

  return (
    <>
      {mutationLoading && <Loading />}
      {mutationLoading && (
        <div className="message-loading">
          <div className="loading loading-card"></div>
          <p>Please wait a moment to prepare your textile library.</p>
        </div>
      )}
      <section className="container container-card-login">
        <h2>Login</h2>
        <div className="container-card-login__content">
          {/* <img className="image-user-login" src={userIcon} alt="user" /> */}
          <form onSubmit={handleSubmit} noValidate>
            <div className="container-card-login__input">
              <input
                type="text"
                name="username"
                placeholder="E-mail address"
                onChange={handleChange}
                onBlur={handleChange}
                className={`u-width-100 ${
                  messageError.username ? "border-error" : ""
                } `}
              />
              {messageError.username && (
                <small className="field-error">{messageError.username}</small>
              )}
            </div>

            <div className="container-card-login__input u-flex-column-smartphone">
              <div className="u-position-relative content-input-password">
                <input
                  type="password"
                  name="password"
                  id="changeType"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleChange}
                  className={`u-width-100 ${
                    messageError.password ? "border-error" : ""
                  } `}
                />
                <span onClick={handleChangeType}>
                  {showIcon ? (
                    <img className="icon-password" src={eyeCloseIcon} alt="" />
                  ) : (
                    <img className="icon-password" src={eyeIcon} alt="" />
                  )}
                </span>
                {messageError.password && (
                  <small className="field-error">{messageError.password}</small>
                )}
              </div>
              <button
                type="submit"
                disabled={mutationLoading ? true : false}
                className="btn btn-primary button-log-in"
              >
                {mutationLoading ? <div className="loading"></div> : "Login"}
              </button>
            </div>

            <p>
              Please agree to the <Link to="/terms-and-conditions">terms</Link>{" "}
              of service and proceed.
            </p>
            <div className="content-buttons-login">
              {/* <Link to="/register-new-member" className="forgot">
                <span>Sign Up Now</span>
              </Link> */}
              <Link to="/forgot-information" className="forgot">
                Forgot login information?
              </Link>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Login;
