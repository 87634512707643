import styled from "@emotion/styled"

export const Select = styled.select`
  height: 40px;
  width: 100%;
  padding: 0 0.5em;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 300;
  border: 1px solid;
  border-color: var(--color-border-input);
  select.border-error {
    border-color: var(--color-danger);
  }
`
export const RowFields = styled.div`
  padding: 1.5em 0;
  align-items: center;
  border-bottom: 1px solid var(--color-dark-shade);
  &.only-right {
    padding: 0.8em 0;
    .col-lg-8 {
      div {
        margin-top: 2em;
      }
    }
  }
`

export const Row = styled.div`
  border-bottom: 1px solid var(--color-dark-shade);
`
export const Text = styled.span`
  margin: 1em 0;
  display: block;
  font-size: 1.3em;
`
export const TextAddress = styled.span`
  display: block;
  font-size: 1em;
  color: var(--color-dark);
  font-weight: 300;
`
export const CardAddress = styled.div`
  margin: 0.5em 0;
`
export const ButtonChange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const InputField = styled.div`
  input[type="text"],
  input[type="tel"],
  input[type="number"] {
    height: 40px;
    width: 100%;
    padding: 0 8px;
    border-radius: 3px;
    border: 1px solid;
    border-color: var(--color-border-input);
    font-size: 1rem;
  }
  input.border-error {
    border-color: var(--color-danger);
  }
`
