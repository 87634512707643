// https://takihyo-textile-admin.herokuapp.com
// export const apiBase = "http://localhost:1337"
export const apiBase = "https://takihyo-textile-admin.herokuapp.com";

export const apiGPT = "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";

export const apiSheet = "https://takihyoapp.info";

export const apiImage = (size?: string) => {
  return `https://drive.google.com/thumbnail?authuser=0&sz=w${
    size ? size : "1000"
  }&id=`;
};

export const MapKey = "AIzaSyClFlQfqKBk3MEDo-Wv36WrzQBNf1WPk8I";

export const apiBigImage = "https://drive.google.com/uc?id=";

export const apiVideo = "https://drive.google.com/uc?export=preview&id=";

export const tokenUser =
  "55646d363682e43ade7cee353c8ea87e83d05e2fdfb99875068700e439038c18ba88a0cf8970acab1a2494f8696cf18bbbd427af0aae4adbeb919c67e6c6c418f4dc17e0fe312f8d050251c0d569de0ffb472661695f6602488e800ab565c66c7713e3512ad3b87dec0ab897bcad6595bd628fd8b22039b69c8e90c28ec608d1";

export const tokenMail =
  "7849e70f9d9c63f3376fc7bc3ef4e3f67a9a2e17b6ac2061281db63f82238ea429d267eb0e4b834102b13df7f48f0e15439180ed83a6f54ef9812aaba5721fd0f82790a2a561b1e484acc6b57195a44ec25923248594b1e453390f85046fed4b381d5d62d5f81154e413c018cd0ce0664c67c58af1ac5d5be3338646a1daf5a6";

export const userTokenID =
  "edad52cfff545950a8e2d64f6a6e9362b68e4879ac5000903c29d8657c92c52dd36a5734b093af3f841578a5d244f8753747ba6744e670664a2941b036957566f408b2bfbd8bf1bb9841c8aa193eaa90e9db202a09456284cb6d814a4f75f82d6d2980e6aa9b6b24beb2fb15511435a8dd4cf7651342fcad3921f342543e2788";
// user: nelson@supersonicdesign.com
// password: El#no18DXyHL
