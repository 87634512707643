import { Link } from "gatsby";
import React from "react";

const SuccessRegister = () => {
  return (
    <>
      <div class="main-container u-margin-top-3">
        <div class="check-container">
          <div class="check-background">
            <svg
              viewBox="0 0 65 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 25L27.3077 44L58.5 7"
                stroke="white"
                stroke-width="13"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="check-shadow"></div>
        </div>
      </div>

      <div className="after-regiter-ms">
        <p>Thank you for your registration in Textile Library.</p>
        <p>Now you can log in from the login page</p>
        <Link to="/login" style={{width: "200px", marginTop: "1.5em"}} className="btn btn-primary button-log-in">Log in</Link>
        <p style={{ marginTop: "2em" }}>
          If you have any question, please email us.
        </p>
      </div>
      <div className="after-regiter-mail">
        <p>
          Email:{" "}
          <a href="mailto:info@textilelibrary-takihyo.com">
            info@textilelibrary-takihyo.com
          </a>
        </p>
      </div>
    </>
  );
};

export default SuccessRegister;
