import React, { useContext } from "react";

import jwt from "jsonwebtoken";
import { GET_PRODUCTS_FAVORITE } from "../querys/index";
import { useQuery } from "@apollo/client";
import CardProduct from "./cards/CardProduct";
import Loading from "./Loading";
import { AuthUserContext } from "../context/AuthUser";
const UrlShare = (props) => {
  const decoded = jwt.decode(props?.search.q);

  const  { authUser } = useContext(AuthUserContext)
  const { data: dataProducts, loading } = useQuery(GET_PRODUCTS_FAVORITE, {
    variables: {
      vip: authUser.typeCustomer === "Admin" ? undefined : "2",
      search: decoded.data,
    },
  });  
  return (
    <>
      {loading && <Loading />}
      <div className="container u-margin-top-3">
        <p className="title-of-share-page">
          Favorite #{props?.search.q.slice(-4)}
        </p>
        <div className="row">
          {dataProducts?.products?.data.map((item, i) => (
            <div className="col-xl-3 col-lg-4 col-md-4 col-6" key={i}>
              <CardProduct data={item.attributes} like_button={false} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UrlShare;
