import styled from "@emotion/styled"

interface ButtonProps {
  color: string
  weight: string
  size: string
}

export const ButtonTL = styled.button<ButtonProps>`
  border: none;
  height: 30px;
  padding: 0 12px;
  cursor: pointer;
  display: inline-block;
  text-transform: capitalize;
  color: var(--color-contrast);
  background: var(
    ${({ color }) =>
      color === "primary"
        ? `--color-primary`
        : color === "secondary"
        ? `--color-secondary`
        : `--color-primary`}
  );
  font-size: var(
    ${({ size }) =>
      size === "small"
        ? "--size-small"
        : size === "default"
        ? `--size-default`
        : size === "large"
        ? `--size-large`
        : `--size-default`}
  );
  font-weight: var(
    ${({ weight }) =>
      weight === "light"
        ? `--family-light`
        : weight === "bold"
        ? `--family-bold`
        : weight === "regular"
        ? `--famili-regular`
        : `--famili-regular`}
  );
`


export const QrSacanner = styled.div({
  overflow: "hidden",
  // position: "relative",
  width: "100%",
  paddingTop: "100%",
  ".qrscan-container": {
    height: "100%",
    div: {
      height: "100%",
      paddingTop: "100%!important",
    },
  },
  ".video-container": {
    top: "0px",
    left: "0px",
    zIndex: 1,
    boxSizing: "border-box",
    // border: "40px solid rgb(0 0 0 / 0%)",
    // boxShadow: "rgb(255 209 0) 0px 0px 0px 5px inset",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "9999",
    video: {
      top: "0px",
      left: "0px",
      display: "block",
      position: "absolute",
      overflow: "hidden",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
});
