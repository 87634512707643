import React, { createContext, useState } from "react";

export const GetQuerysContext = createContext(null);

const GetQuerysProvider = (props) => {
  const [AllQuery, setAllQuery] = useState();

  return (
    <GetQuerysContext.Provider value={{ AllQuery, setAllQuery }}>
      {props.children}
    </GetQuerysContext.Provider>
  );
};

export default GetQuerysProvider;
