import styled from "@emotion/styled";

export const HeaderComponent = styled.header`
  height: 61px;

  display: flex;
  height: 61px;
  z-index: 99;
  background: white;
  position: relative;
  justify-content: center;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-dark-shade);
  justify-content: space-between;
  height: 60px;
  position: fixed;
  z-index: 3;
  background: white;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  @media screen and (max-width: 992px) {
    height: 65px;
    padding-right: 20px;
    padding-left: 20px;
    left: 0;
    right: 0;
    width: auto;
  }
  .title-header {
    display: flex;
    margin: 0 25px;
    align-items: center;
    @media screen and (max-width: 600px) {
      margin: 0 10px;
    }
    img {
      width: 210px;
      @media screen and (max-width: 520px) {
        width: 145px;
        object-fit: cover;
        height: 30px;
        object-position: 83%;
      }
      @media screen and (max-width: 420px) {
        width: 115px;
        object-fit: cover;
        height: 25px;
        object-position: 78%;
      }
    }
  }

  .title-header-only-text {
    display: none;    
    margin: 0 25px;
    align-items: center;

    font-weight: 900;
    font-size: 21px;
    letter-spacing: -1px;
    color: black;
    margin-left: 5px;
    @media screen and (max-width: 520px) {
      // display: flex;
    }
  }

  @media screen and (min-width: 992px) {
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    width: auto;
    position: fixed;
    background: #ffffff;
  }
`;
export const Menu = styled.a`
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  font-size: 1.5em;
  align-items: center;
  @media screen and (max-width: 600px) {
    width: 25px;
    height: 25px;
  }
`;
export const Cart = styled.div`
  position: relative;
  .btn-cart {
    display: flex;
    margin-left: 15px;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: 420px) {
      margin-left: 10px;
    }
    img {
      width: 21px;
    }
  }
`;
export const LoginUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    display: flex;
    margin-left: 15px;
    align-items: center;
    color: var(--color-primary);
    @media screen and (max-width: 520) {
      margin-left: 0;
    }
    img {
      width: 19px;
    }
  }
`;
