import React, { useContext, useState } from "react";
import { EDIT_ADDRESS, EDIT_USER } from "../mutations";
import { useMutation, useQuery } from "@apollo/client";
import { USER_DATA } from "../querys";
import {
  Container,
  Row,
  InputField,
  //   Select,
  Label,
  Buttons,
} from "../components/styled/RegisterMemberStyled";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { AuthUserContext } from "../context/AuthUser";
const EditMemberInfo = () => {
  const { authUser } = useContext(AuthUserContext);
  const emailRegex = RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const [registerValues, setRegisterValues] = useState({});

  const { data, loading, error } = useQuery(USER_DATA, {
    variables: { id: authUser?.user?.id },
    onCompleted: () => {},
  });
  const formValidate = (errors, ...data) => {
    let valid = true;
    Object.values(errors).forEach((val) => {
      val && (valid = false);
    });
    Object.values(data).forEach((val) => {
      val === "" && (valid = false);
    });
    return valid;
  };
  const [messageError, setMessageError] = useState({});
  /**
   * Method that captures the value of each input
   * @param {*Parameter for input} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    let errros = messageError;
    switch (name) {
      case "firstname":
        errros.firstname = value === "" ? "This field is required" : "";
        break;
      case "lastName":
        errros.lastName = value === "" ? "This field is required" : "";
        break;
      case "email":
        errros.email =
          value === ""
            ? "This field is required"
            : "" || emailRegex.test(value)
            ? ""
            : "Invalid email";
        // errros.repeatEmail =
        //   registerValues.email !== registerValues.repeatEmail
        //     ? "Emails don't match"
        //     : "";
        break;
      // case "repeatEmail":
      //   errros.repeatEmail = value === "";
      //   errros.repeatEmail =
      //     registerValues.email !== registerValues.repeatEmail
      //       ? "Emails don't match"
      //       : "";
      //   break;
      case "address":
        errros.address =
          value === ""
            ? "This field is required"
            : "" || value.length > 3
            ? ""
            : "A minimum of 3 characters";
        break;
      case "city":
        errros.city =
          value === ""
            ? "This field is required"
            : "" || value.length > 3
            ? ""
            : "A minimum of 3 characters";
        break;
      case "state":
        errros.state =
          value === ""
            ? "This field is required"
            : "" || value.length > 1
            ? ""
            : "A minimum of 2 characters";
        break;
      case "country":
        errros.country =
          value === ""
            ? "This field is required"
            : "" || value.length > 1
            ? ""
            : "A minimum of 2 characters";
        break;
      case "zipcode":
        errros.zipcode =
          value === ""
            ? "This field is required"
            : "" || value.length > 3
            ? ""
            : "A minimum of 3 characters";
        break;
      case "phone":
        errros.phone =
          value === ""
            ? "This field is required"
            : "" || value.length > 3
            ? ""
            : "A minimum of 3 characters";
        break;
      // case "password":
      //   errros.password =
      //     value === ""
      //       ? "This field is required"
      //       : "" || value.length > 3
      //       ? ""
      //       : "A minimum of 3 characters";
      //   errros.repeatPassword =
      //     registerValues.password !== registerValues.repeatPassword
      //       ? "Passwords do not match"
      //       : "";
      //   break;
      // case "repeatPassword":
      //   errros.repeatPassword =
      //     registerValues.password !== registerValues.repeatPassword
      //       ? "Passwords do not match"
      //       : "";
      //   break;
      default:
        break;
    }
    setRegisterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  /**
   * Mutation EDIT_USER => update user
   */
  const [editUser, { loading: mutationLoading, error: mutationError }] =
    useMutation(EDIT_USER, {
      onCompleted: () => {
        toast.success("Successfully saved");
      },
      onError: () => {
        toast.error(
          "An error occurred while saving, verify that the required fields are complete."
        );
      },
      refetchQueries: ["refreshCurrentUser", "refeshUserData"],
    });

  const [editAddress] = useMutation(EDIT_ADDRESS, {
    onCompleted: () => {
      toast.success("Your registration was completed successfully");
    },
    onError: () => {
      toast.error("Please complete the form");
    },
    refetchQueries: ["getAddress", "getMainAddress", "getSecontaryAddress"],
  });

  if (loading) return <Loading />;
  if (error) return ``;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValidate(messageError, registerValues)) {
      handleEditUser();
      window.scrollTo(0, 0);
    } else {
      toast.error("Please complete the form");
    }
  };

  const {
    companyName,
    email,
    emailuser,
    firstName,
    lastName,

    addresses,
  } = data.usersPermissionsUser.data.attributes;
  const dataAddress = addresses.data.filter((x) => x.attributes.main === true);

  const handleEditUser = async () => {
    await editUser({
      variables: {
        id: authUser.user.id,
        data: {
          username: registerValues.email,
          lastName: registerValues.lastName,
          email: registerValues.email,
          firstName: registerValues.firstname,
          // password: registerValues.password,          
          companyName: registerValues.companyName,
        },
      },
    });

    await editAddress({
      variables: {
        id: dataAddress[0].id,
        data: {
          address: registerValues.address,
          addressOptional: registerValues.addressOptional,
          lastname: registerValues.lastName,
          firstname: registerValues.firstname,
          cityName: registerValues.city,
          zipcode: registerValues.zipcode,
          aptNumber: registerValues.aptNumber,
          state: registerValues.state,
          phone: registerValues.phone,
          main: true,
          companyName: registerValues.companyName,
          street: registerValues.street,
          country: registerValues.country,
          
        },
      },
    });
  };

  return (
    <div className="container">
      <Container onSubmit={handleSubmit} noValidate>
        <h2>Edit user info</h2>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="name">Name</Label>
          </div>
          <div className="col-lg-4">
            <InputField className="u-margin-rp-2">
              <input
                type="text"
                id="name"
                name="firstname"
                placeholder="First Name (required)"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={firstName && firstName}
                className={messageError.firstname ? "border-error" : ""}
              />
              {messageError.firstname && (
                <small className="field-error">{messageError.firstname}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4">
            <InputField className="u-margin-rp-2">
              <input
                type="text"
                placeholder="Last Name (required)"
                name="lastName"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={lastName && lastName}
                className={messageError.lastName ? "border-error" : ""}
              />
              {messageError.lastName && (
                <small className="field-error">{messageError.lastName}</small>
              )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="company">Company</Label>
          </div>
          <div className="col-lg-8">
            <InputField>
              <input
                type="text"
                id="company"
                name="companyName"
                placeholder="Company name (optional)"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={companyName && companyName}
                className={messageError.companyName ? "border-error" : ""}
              />
              {messageError.companyName && (
                <small className="field-error">
                  {messageError.companyName}
                </small>
              )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="email">Email</Label>
          </div>
          <div className="col-lg-4">
            <InputField className="u-margin-rp-2">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="E-Mail Address (required)"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={email && email}
                className={messageError.email ? "border-error" : ""}
              />
              {messageError.email && (
                <small className="field-error">{messageError.email}</small>
              )}
            </InputField>
          </div>
          {/* <div className="col-lg-4">
            <InputField className="u-margin-rp-2">
              <input
                type="email"
                name="repeatEmail"
                placeholder="Confirm E-mail Address"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={emailuser && emailuser}
                className={messageError.repeatEmail ? "border-error" : ""}
              />
              {messageError.repeatEmail && (
                <small className="field-error">
                  {messageError.repeatEmail}
                </small>
              )}
            </InputField>
          </div> */}
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="address">Address</Label>
          </div>
          <div className="col-lg-8 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Address 1 (required)"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={
                  dataAddress[0]?.attributes?.address &&
                  dataAddress[0]?.attributes?.address
                }
                className={messageError.address ? "border-error" : ""}
              />
              {messageError.address && (
                <small className="field-error">{messageError.address}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-8 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                name="addressOptional"
                placeholder="Address 2 (optional)"
                onChange={handleChange}
                defaultValue={
                  dataAddress[0]?.attributes?.addressOptional &&
                  dataAddress[0]?.attributes?.addressOptional
                }
              />
            </InputField>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-4 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="City (required)"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={
                  dataAddress[0]?.attributes?.cityName &&
                  dataAddress[0]?.attributes?.cityName
                }
                className={messageError.city ? "border-error" : ""}
              />
              {messageError.city && (
                <small className="field-error">{messageError.city}</small>
              )}
            </InputField>
          </div>

          <div className="col-lg-4 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                placeholder="State (required)"
                name="state"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={
                  dataAddress[0]?.attributes?.state &&
                  dataAddress[0]?.attributes?.state
                }
                className={messageError.state ? "border-error" : ""}
              />
              {messageError.state && (
                <small className="field-error">{messageError.state}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-4 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                placeholder="Country (required)"
                name="country"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={
                  dataAddress[0]?.attributes?.country &&
                  dataAddress[0]?.attributes?.country
                }
                className={messageError.country ? "border-error" : ""}
              />
              {messageError.country && (
                <small className="field-error">{messageError.country}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4 u-margin-bottom-2">
            <InputField>
              <input
                type="text"
                placeholder="Zip Code (required)"
                name="zipcode"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={
                  dataAddress[0]?.attributes?.zipcode &&
                  dataAddress[0]?.attributes?.zipcode
                }
                className={messageError.zipcode ? "border-error" : ""}
              />
              {messageError.zipcode && (
                <small className="field-error">{messageError.zipcode}</small>
              )}
            </InputField>
          </div>
        </Row>
        <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="phone">Phone Number</Label>
          </div>
          <div className="col-lg-4">
            <InputField className="u-margin-rp-2">
              <input
                type="phone"
                id="phone"
                placeholder="Phone Number (required)"
                name="phone"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={
                  dataAddress[0]?.attributes?.phone &&
                  dataAddress[0]?.attributes?.phone
                }
                className={messageError.phone ? "border-error" : ""}
              />
              {messageError.phone && (
                <small className="field-error">{messageError.phone}</small>
              )}
            </InputField>
          </div>
        </Row>
        {/* <Row className="row">
          <div className="col-lg-4">
            <Label htmlFor="password">Password</Label>
          </div>
          <div className="col-lg-4">
            <InputField className="u-margin-rp-2">
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password (required)"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={""}
                className={messageError.password ? "border-error" : ""}
              />
              {messageError.password && (
                <small className="field-error">{messageError.password}</small>
              )}
            </InputField>
          </div>
          <div className="col-lg-4">
            <InputField className="u-margin-rp-2">
              <input
                type="password"
                name="repeatPassword"
                placeholder="Repeat password"
                onChange={handleChange}
                onBlur={handleChange}
                defaultValue={""}
                className={messageError.repeatPassword ? "border-error" : ""}
              />
              {messageError.repeatPassword && (
                <small className="field-error">
                  {messageError.repeatPassword}
                </small>
              )}
            </InputField>
          </div>
        </Row> */}
        <Buttons className="row u-text-center u-margin-tb-2">
          <button
            type="submit"
            className="btn btn-danger btn-width-200 u-margin-bottom-1"
          >
            {mutationLoading ? (
              <div className="loading"></div>
            ) : (
              "Save my Profile"
            )}
          </button>
        </Buttons>
      </Container>
    </div>
  );
};

export default EditMemberInfo;
