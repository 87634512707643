import styled from "@emotion/styled"

export const ToggleButton = styled.div`
  input { display: none }
  label {
    width: 100%;
    display: flex;    
    font-size: 15px;
    align-items: center;    
    position: relative;
    cursor: pointer;
    font-weight: 600;
    span{
      width: 100%;
      @media screen and (min-width:767px) and (max-width: 1120px){
        width: 70%;
      }
    }
    &:after{
      right: 0;
      content: '';
      width: 30px;
      height: 15px;
      position: absolute;
      border-radius: 30px;
      transition: .2s ease all;
      background: var(--color-dark-gray-b6b6);
      box-shadow: 2px 3px 3px 0px rgb(149 149 149 / 29%);
    }
    &:before{
      z-index: 1;
      right: 17px;
      content: '';
      width: 11px;
      height: 11px;
      position: absolute;
      border-radius: 30px;
      transition: .2s ease all;
      background: var(--color-contrast);
    }
  }
  input:checked + label:before {
    right: 2px;    
  }
  input:checked + label:after {    
    background: var(--color-primary);
  }
`

export const Select = styled.select`
  color: var(--color-primary);
  padding-left: 10px;
  padding-right: 30px;
  /* needed */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1rem;
  /* SVG background image */
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%2205515'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 10px;
  background-position: calc(100% - 10px) 60%;
  background-repeat: no-repeat;
  background-color: var(--color-contrast-light);
  height: 40px;
  border-radius: 3px;
  font-weight: 300;
  border: 1px solid var(--color-dark-shade);
`
export const Price = styled.p`
  padding: 0.5em 0;
  margin-top: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--color-dark-shade);
  display: flex;
  justify-content: space-between;
  span {
    font-size: 1.2rem;
    color: var(--color-dark);
    font-weight: 900;
  }
`
export const List = styled.ul`
  li {
    margin: 0.5em 0;
    padding: 0.5em 0;
    font-size: 13px;
    color: var(--color-primary-light);
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(211, 211, 211, 0.3);
  }
`
export const Quantity = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btns-quantity {
    display: flex;
    margin-left: 2em;
    button {
      cursor: pointer;
      background: none;
      font-size: 1.5rem;
      padding: 0em 1.2em;
      color: var(--color-dark);
      border: 1px solid var(--color-dark-shade);
    }
  }
  .quanty-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
export const ListDetail = styled.ul`
  margin-top: 1em;
  @media screen and (max-width: 610px) {
    padding: 0 1em;
  }
  li {
    display: flex;
    font-size: 1rem;
    margin: 0.5em 0;
    padding: 0.5em 0em;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-primary-light);
    .description-font {
      font-size: 14px;
      line-height: 27px;
    }

    div.tags-keywords {
      display: flex;
      flex-wrap: wrap;
      a {
        cursor: pointer;
        border: none;
        margin: 3px;
        padding: 2px 5px;
        font-weight: bold;
        font-size: 0.8rem;
        border-radius: 3px;
        font-weight: 300;
        color: var(--color-contrast);
        background-color: var(--color-gray-tags);
      }
    }
    span.title-product-detail {
      /* padding: 0.3em 0;
      font-weight: bold; */
      margin-bottom: 0.2em;
      font-weight: 900;
    }
  }
`
export const TotalPrice = styled.li`
  font-weight: bold;
  font-weight: 900;
  .label-price {
    font-size: 13px;
    font-weight: 300;
  }
  .price-cost {
    font-size: 14px;
  }
`

export const InputQuantity = styled.input`
  width: 55px;
  height: 30px;
  padding: 0 5px;
  font-size: 0.9rem;
  /* margin-left: 1em; */
  text-align: right;
  border-radius: 3px;
  border: 1px solid var(--color-dark-shade);
`

export const CardPay = styled.div`
  padding: 2em 2em 1em 2em;
  border-radius: 5px;
  margin: 2em 0;
  box-shadow: 0px 5px 14px -8px rgba(70, 70, 70, 0.6);
`
