import React from "react"
import { Link } from "gatsby"
import { useEffect } from "react"
import { useState } from "react"

const OrderProgressBar = ({ page }) => {
  const [numberone,setNumberOne] = useState();
  const [numbertwo,setNumberTwo] = useState();
  const [numberthree,setNumberThree] = useState();
  useEffect(() => {
    if (page >= 1) {
      setNumberOne(true)
    }
    if (page >= 2) {
      setNumberTwo(true)
    }
    if (page === 3) {
      setNumberThree(true)
    }
  })
  return (
    <div className="progress_bar">
        <div className="progress_bar_head">
          <p className={numberone? "progress_bar_number active_bar": "progress_bar_number notactive_bar"}><span>1</span></p>
          <p className="progress_bar_subtitle">Order Validation</p>
          <p className="progress_bar_text">Field validation</p>
        </div>
        <div className={numbertwo? "progress_bar_line active_bar": "progress_bar_line notactive_bar"}></div>
        <div className="progress_bar_head">
          <p className={numbertwo? "progress_bar_number active_bar": "progress_bar_number notactive_bar"}>2</p>
          <p className="progress_bar_subtitle">Order information</p>
          <p className="progress_bar_text">
            Fill in the fields weight, size <br />
            (fields required for fedex)
          </p>
        </div>
        <div className={numberthree? "progress_bar_line active_bar": "progress_bar_line notactive_bar"}></div>
        <div className="progress_bar_head">
          <p className={numberthree? "progress_bar_number active_bar": "progress_bar_number notactive_bar"}>3</p>
          <p className="progress_bar_subtitle">Send to Fedex</p>
          <p className="progress_bar_text">Confirm and view order to ship  </p>
        </div>
      </div>
  )
}
export default OrderProgressBar
