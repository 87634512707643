import React, { useState } from "react"
import { IoIosArrowDown } from "react-icons/io"

import { FilterStyle, TitleStyle } from "./styled-widget"
import Collapsible from 'react-collapsible';

const FunctionWidget = ({ functionItem, handleFilter, selected }) => {
  const [collapse, setCollapse] = useState<boolean>(false)

  return (
    <FilterStyle>
      <Collapsible trigger={<TitleStyle onClick={() => setCollapse(!collapse)}>
        Function
        <IoIosArrowDown
          style={{
            transform: `${collapse ? "rotate(180deg)" : "rotate(0deg)"} `,
          }}
        />
      </TitleStyle>} transitionTime={200}>

        <ul >
          {functionItem
            .slice(0, collapse ? functionItem.length : 0)
            .map((func: any) => {
              const isSelected = selected.includes(func)
              return (
                <li onClick={() => handleFilter(func)} style={{
                  fontWeight: `${isSelected ? "bold" : ""}`,
                }}>
                  {func}
                </li>
              )
            }

            )}
        </ul>
      </Collapsible>



    </FilterStyle>
  )
}

export default FunctionWidget
