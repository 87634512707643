import styled from "@emotion/styled";

export const DropDown = styled.div`
  right: 0;
  margin-top: 5px;
  width: 280px;
  padding: 0.5em 1em;
  border: 0;
  z-index: 9;
  position: absolute;
  border-radius: 10px;
  background: var(--color-contrast);
  box-shadow: 0px 6px 15px -5px rgba(0, 0, 0, 0.4);
  transition: 0.2s ease all;
`;
