import React, { useState, useEffect } from "react"
import { RESET_PASSWORD } from "../mutations"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import { navigate } from "gatsby-link"

const RecoverPass = props => {  


  useEffect(() => {
    if(!props?.search?.code){
      navigate("/")
    }
  }, [props.search.code])

  const [resetPassword, setResetPassword] = useState({
    password: "",
    repeatPassword: "",
    code: props.search.code,
    messageError: {},
  })
  const { messageError } = resetPassword
  const [resetPass] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {      
      navigate("/login")
      toast.success("Your password was successfully recovered!")
    },
    onError: () => {
      toast.error("Recovery code has expired")
    },
  })
  const handleChange = e => {
    const { name, value } = e.target
    let errros = resetPassword.messageError
    switch (name) {
      case "password":
        errros.password = value === "" ? "This field is required" : ""
        break
      case "repeatPassword":
        errros.repeatPassword =
          value !== resetPassword.password
            ? "Passwords do not match"
            : "" || value === ""
            ? "This field is required"
            : ""
        break
      default:
        break
    }
    setResetPassword(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }

  const handleResetPassword = () => {
    resetPass({
      variables: {
        password: resetPassword.password,
        code: resetPassword.code,
      },
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (
      resetPassword.password === "" ||
      resetPassword.resetPassword === "" ||
      resetPassword.code === ""
    ) {
      toast.error("Please enter the new password")
    } else {
      if (formValidate(resetPassword.messageError, resetPassword)) {
        handleResetPassword()
      } else {
        toast.error("The fields are required")
      }
    }
  }

  return (
    <>
      <section className="container container-card-login">
        <h2>Password Reset</h2>
        <div className="container-card-login__content">
          <form className="row" noValidate onSubmit={handleSubmit}>
            <p className="u-margin-t-none">Change your password</p>
            <div className="col-lg-12 ">
              <div className="container-card-login__input">
                <input
                  type="password"
                  name="password"
                  id="changeType"
                  onChange={handleChange}
                  placeholder="New Password"
                  className={messageError.password ? "border-error" : ""}
                  style={{ paddingRight: "40px" }}
                />
                {messageError.password && (
                  <small className="field-error field-error-home">
                    {messageError.password}
                  </small>
                )}
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="container-card-login__input">
                <input
                  type="password"
                  name="repeatPassword"
                  id="changeType"
                  onChange={handleChange}
                  placeholder="Repeat new password"
                  className={messageError.password ? "border-error" : ""}
                  style={{ paddingRight: "40px" }}
                />
                {messageError.repeatPassword && (
                  <small className="field-error field-error-home">
                    {messageError.repeatPassword}
                  </small>
                )}
              </div>
            </div>
            <div className="col-lg-12 ">
              <button type="submit" className="btn btn-primary">
                Change
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default RecoverPass
