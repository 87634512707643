import React from "react";
import { Link, navigate } from "gatsby";
const ThanksForPurchase = () => {
  return (
    <div className="container thanks-for-purchase">
      <div className="main-container">
        <div className="check-container">
          <div className="check-background">
            <svg
              viewBox="0 0 65 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 25L27.3077 44L58.5 7"
                stroke="white"
                strokeWidth="13"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="check-shadow"></div>
        </div>
      </div>
      <h3>Thank you for your purchase!</h3>
      <p>
        Your order was successful. You will receive an email confirmation
        shortly. If you have any questions about your purchase, don’t hesitate{" "}
        <Link to="/contact-us" target="_blank">
          contact us.
        </Link>
      </p>
      <div className="u-display-flex">
        <button
          onClick={() => navigate("/")}
          type="submit"
          className="u-margin-left-05 u-margin-right-05 btn btn-primary u-margin-bottom-1"
        >
          Back to Library
        </button>
        <button
          onClick={() => navigate("/order-history")}
          type="submit"
          className="u-margin-left-05 u-margin-right-05 btn btn-outline-primary u-margin-bottom-1"
        >
          View your Orders
        </button>
      </div>
    </div>
  );
};

export default ThanksForPurchase;
