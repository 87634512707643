export interface FilterPriceRange {
  min_price: number | string;
  max_price: number | string;
}

export interface ColorFilter {
  name: string;
  code: string;
}

export interface MaterialFilter {
  name: string;
}

export interface FinishFilter {
  name: string;
}

export interface FunctionFilter {
  name: string;
}

const price_range: FilterPriceRange[] = [
  { min_price: 0, max_price: 5 },
  { min_price: 5, max_price: 10 },
  { min_price: 10, max_price: 15 },
  { min_price: 15, max_price: 20 },
  { min_price: "Over", max_price: 20 },
];

export const getPriceRangeFilter = (): FilterPriceRange[] => {
  return price_range;
};

const color_filter: ColorFilter[] = [
  { name: "white", code: "#fff" },
  { name: "red", code: "#E94848" },
  { name: "yellow", code: "#FFF50A" },
  { name: "green", code: "#3D824C" },
  { name: "blue", code: "#257AFB" },
  { name: "brown", code: "#805944" },
  { name: "black", code: "#060606" },
  { name: "natural undyed", code: "#000000" },
  { name: "pfd/pfp", code: "#550000" },
  { name: "blue", code: "#227Abb" },
  { name: "white", code: "#447Abb" },
  { name: "orange", code: "#258Abb" },
  { name: "pink", code: "#257A11" },
  { name: "violet", code: "#257A00" },
  { name: "black red", code: "#2588Ab" },
];

export const getColorFilter = (): ColorFilter[] => {
  return color_filter;
};

const function_filter: FunctionFilter[] = [
  { name: "Strech" },
  { name: "Water Repellent" },
  { name: "Water Proof" },
  { name: "Down Proof" },
  { name: "Quick Dry" },
  { name: "Easy Care" },
  { name: "Anti Bacteria" },
  { name: "UV Cut" },
];

export const getFunctionFilter = (): FunctionFilter[] => {
  return function_filter;
};

const finish_filter: FinishFilter[] = [
  { name: "Washed" },
  { name: "Tumbled" },
  { name: "Coated" },
  { name: "Cire / Chintz" },
  { name: "Creased" },
];

export const getFinishFilter = (): FinishFilter[] => {
  return finish_filter;
};

export const matchFilter = (parent, sub) => {
  if (!parent || parent.length == 0) {
    return false;
  }
  return parent.length === 1
    ? sub.some((e) => parent.includes(e))
    : sub.every((e) => parent.includes(e));
};

export const cleanUrl = (url: string) => {
  return url?.replace(/[^a-zA-Z0-9-_]/g, "");
};
