import React, { useContext, useEffect } from "react";
import { USER_DATA } from "../querys";
import { useQuery } from "@apollo/client";

import { AuthUserContext } from "../context/AuthUser";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  if (
    !authUser.logged &&
    location.pathname !== `/login` &&
    !localStorage.getItem("token")
  ) {
    window.location.href = "/login";
    return null;
  }
  return <Component {...rest} current_user={authUser?.user} />;
};

export default PrivateRoute;
